export const FETCH_PACKED_ORDERS = 'FETCH_PACKED_ORDERS';
export const FETCH_PACKED_ORDERS_RESULT = 'FETCH_PACKED_ORDERS_RESULT';
export const FETCH_PACKED_ORDERS_ERROR = 'FETCH_PACKED_ORDERS_ERROR';
export const FETCH_PACKED_ORDERS_RETRY = 'FETCH_PACKED_ORDERS_RETRY';


export const CREATE_ORDER_INVOICE = 'CREATE_ORDER_INVOICE';
export const CREATE_ORDER_INVOICE_RESULT = 'CREATE_ORDER_INVOICE_RESULT';
export const CREATE_ORDER_INVOICE_ERROR = 'CREATE_ORDER_INVOICE_ERROR';
export const CREATE_ORDER_INVOICE_RETRY = 'CREATE_ORDER_INVOICE_RETRY';


export const fetchPackedOrders = (data) => ({
    type: FETCH_PACKED_ORDERS,
    data,
});


export const createOrderInvoice = (data) => ({
    type: CREATE_ORDER_INVOICE,
    data,
});
