import {call, put} from 'redux-saga/effects';
import {
    UPDATE_USER_PROFILE_RESULT, UPDATE_USER_PROFILE_ERROR, UPDATE_USER_PROFILE_RETRY,
    FETCH_USER_PROFILE_RESULT, FETCH_USER_PROFILE_ERROR, FETCH_USER_PROFILE_RETRY,
} from '../../actions/accounts/profile';
import api from "../api";


export function* updateUserProfile(action) {
    try {
        const {data} = action;
        const response = yield call(api.accounts.patchUser, data);
        const result = yield response.json();
        console.warn('SAGA:updateUserProfile', action, result);

        if (response.status === 200) {
            yield put({type: UPDATE_USER_PROFILE_RESULT, data: result});
        }
        else {
            yield put({type: UPDATE_USER_PROFILE_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: UPDATE_USER_PROFILE_RETRY, retry: true, message: e.message});
    }
}


export function* fetchUserProfile(action) {
    try {
        const response = yield call(api.accounts.getUser, action.data);
        const result = yield response.json();
        console.warn('SAGA:fetchUserProfile', action, result);

        if (response.status === 200) {
            yield localStorage.setItem('user', JSON.stringify(result));
            yield put({type: FETCH_USER_PROFILE_RESULT, data: result});
        }
        else {
            yield put({type: FETCH_USER_PROFILE_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_USER_PROFILE_RETRY, retry: true, message: e.message});
    }
}

