//External Imports
import {combineReducers} from 'redux';
//My Imports
import login from './login';
import profile from './profile';


export default combineReducers({
    login,
    profile,
})
