import {call, put} from 'redux-saga/effects';
import {
    CREATE_CONSULTATION_RESULT,
    CREATE_CONSULTATION_ERROR,
    CREATE_CONSULTATION_RETRY,
    FETCH_CONSULTATIONS_RESULT,
    FETCH_CONSULTATIONS_ERROR,
    FETCH_CONSULTATIONS_RETRY,
    FETCH_CONSULTATIONS_MASTER_RESULT,
    FETCH_CONSULTATIONS_MASTER_ERROR, FETCH_CONSULTATIONS_MASTER_RETRY
} from '../../actions/consultations/consultations';
import api from "../api";


export function* createConsultation(action) {
    try {
        const response = yield call(api.consultations.consultations.create, action.data);
        const result = yield response.json();
        console.warn('SAGA:createConsultation', action, result);

        if (response.status === 201) {
            yield put({type: CREATE_CONSULTATION_RESULT, data: result});
        }
        else {
            yield put({type: CREATE_CONSULTATION_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: CREATE_CONSULTATION_RETRY, retry: true, message: e.message});
    }
}


export function* fetchConsultations(action) {
    try {
        const response = yield call(api.consultations.consultations.list, action.data);
        const result = yield response.json();
        console.warn('SAGA:fetchConsultations', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_CONSULTATIONS_RESULT, data: result});
        }
        else {
            yield put({type: FETCH_CONSULTATIONS_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_CONSULTATIONS_RETRY, retry: true, message: e.message});
    }
}


export function* fetchConsultationsMaster(action) {
    try {
        const response = yield call(api.consultations.consultations.templates);
        const result = yield response.json();
        console.warn('SAGA:fetchConsultationsMaster', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_CONSULTATIONS_MASTER_RESULT, data: result});
        }
        else {
            yield put({type: FETCH_CONSULTATIONS_MASTER_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_CONSULTATIONS_MASTER_RETRY, retry: true, message: e.message});
    }
}
