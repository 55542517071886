import {call, put} from 'redux-saga/effects';
import {
    FETCH_INVOICE_ITEMS,
    FETCH_INVOICE_ITEMS_RESULT,
    FETCH_INVOICE_ITEMS_ERROR,
    FETCH_INVOICE_ITEMS_RETRY,

    CREATE_INVOICE_ITEMS,
    CREATE_INVOICE_ITEMS_RESULT,
    CREATE_INVOICE_ITEMS_ERROR,
    CREATE_INVOICE_ITEMS_RETRY,

    UPDATE_INVOICE_ITEMS,
    UPDATE_INVOICE_ITEMS_RESULT,
    UPDATE_INVOICE_ITEMS_ERROR,
    UPDATE_INVOICE_ITEMS_RETRY,

    DELETE_INVOICE_ITEMS,
    DELETE_INVOICE_ITEMS_RESULT,
    DELETE_INVOICE_ITEMS_ERROR,
    DELETE_INVOICE_ITEMS_RETRY,

    FETCH_INVOICE_ITEMS_BATCH,
    FETCH_INVOICE_ITEMS_BATCH_RESULT,
    FETCH_INVOICE_ITEMS_BATCH_ERROR,
    FETCH_INVOICE_ITEMS_BATCH_RETRY,
} from '../../actions/pharmacy/invoiceitems';
import api from "../api";


export function* fetchInvoiceItems(action) {
    try {
        const response = yield call(api.pharmacy.invoiceitems.list, action.data);
        const result = yield response.json();
        console.warn('SAGA:fetchInvoiceItems', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_INVOICE_ITEMS_RESULT, data: result});
        } else {
            yield put({type: FETCH_INVOICE_ITEMS_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_INVOICE_ITEMS_RETRY, retry: true, message: e.message});
    }
}

export function* createInvoiceItems(action) {
    try {
        const response = yield call(api.pharmacy.invoiceitems.create, action.data);
        const result = yield response.json();
        console.warn('SAGA:createInvoiceItems', action, result);

        if (response.status === 201) {
            yield put({type: CREATE_INVOICE_ITEMS_RESULT, data: result});
        } else {
            yield put({type: CREATE_INVOICE_ITEMS_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: CREATE_INVOICE_ITEMS_RETRY, retry: true, message: e.message});
    }
}

export function* updateInvoiceItems(action) {
    try {
        const response = yield call(api.pharmacy.invoiceitems.update, action.data);
        const result = yield response.json();
        console.warn('SAGA:updateInvoiceItems', action, result);

        if (response.status === 200) {
            yield put({type: UPDATE_INVOICE_ITEMS_RESULT, data: result});
        } else {
            yield put({type: UPDATE_INVOICE_ITEMS_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: UPDATE_INVOICE_ITEMS_RETRY, retry: true, message: e.message});
    }
}

export function* deleteInvoiceItems(action) {
    try {
        const response = yield call(api.pharmacy.invoiceitems.remove, action.data);
        console.warn('SAGA:deleteInvoiceItems', action);

        if (response.status === 204) {
            yield put({type: DELETE_INVOICE_ITEMS_RESULT});
        } else {
            yield put({type: DELETE_INVOICE_ITEMS_ERROR, error: true});
        }
    } catch (e) {
        yield put({type: DELETE_INVOICE_ITEMS_RETRY, retry: true, message: e.message});
    }
}

export function* fetchInvoiceItemsBatch(action) {
    try {
        const response = yield call(api.pharmacy.invoiceitems.batch, action.data);
        const result = yield response.json();
        console.warn('SAGA:fetchInvoiceItemsBatch', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_INVOICE_ITEMS_BATCH_RESULT, data: result});
        } else {
            yield put({type: FETCH_INVOICE_ITEMS_BATCH_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_INVOICE_ITEMS_BATCH_RETRY, retry: true, message: e.message});
    }
}