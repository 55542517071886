import {call, put} from 'redux-saga/effects';
import {
    FETCH_CREDIT_NOTES,
    FETCH_CREDIT_NOTES_RESULT,
    FETCH_CREDIT_NOTES_ERROR,
    FETCH_CREDIT_NOTES_RETRY,

    CREATE_CREDIT_NOTE,
    CREATE_CREDIT_NOTE_RESULT,
    CREATE_CREDIT_NOTE_ERROR,
    CREATE_CREDIT_NOTE_RETRY,

    UPDATE_CREDIT_NOTE,
    UPDATE_CREDIT_NOTE_RESULT,
    UPDATE_CREDIT_NOTE_ERROR,
    UPDATE_CREDIT_NOTE_RETRY,
} from '../../actions/pharmacy/creditnotes';
import api from "../api";


export function* fetchCreditNotes(action) {
    try {
        const response = yield call(api.pharmacy.creditnotes.list, action.data);
        const result = yield response.json();
        console.warn('SAGA:fetchCreditNotes', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_CREDIT_NOTES_RESULT, data: result});
        }
        else {
            yield put({type: FETCH_CREDIT_NOTES_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_CREDIT_NOTES_RETRY, retry: true, message: e.message});
    }
}

/*

export function* createCreditNote(action) {
    try {
        const response = yield call(api.pharmacy.batches.create, action.data);
        const result = yield response.json();
        console.warn('SAGA:createCreditNote', action, result);

        if (response.status === 201) {
            yield put({type: CREATE_CREDIT_NOTE_RESULT, data: result});
        }
        else {
            yield put({type: CREATE_CREDIT_NOTE_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: CREATE_CREDIT_NOTE_RETRY, retry: true, message: e.message});
    }
}

export function* updateCreditNote(action) {
    try {
        const response = yield call(api.pharmacy.batches.update, action.data);
        const result = yield response.json();
        console.warn('SAGA:updateCreditNote', action, result);

        if (response.status === 200) {
            yield put({type: UPDATE_CREDIT_NOTE_RESULT, data: result});
        }
        else {
            yield put({type: UPDATE_CREDIT_NOTE_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: UPDATE_CREDIT_NOTE_RETRY, retry: true, message: e.message});
    }
}*/
