export const FETCH_REPORT = 'FETCH_REPORT';
export const FETCH_REPORT_RESULT = 'FETCH_REPORT_RESULT';
export const FETCH_REPORT_ERROR = 'FETCH_REPORT_ERROR';
export const FETCH_REPORT_RETRY = 'FETCH_REPORT_RETRY';


export const fetchReport = (data) => ({
    type: FETCH_REPORT,
    data
});
