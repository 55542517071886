export const FETCH_RETURNED_ORDERS = 'FETCH_RETURNED_ORDERS';
export const FETCH_RETURNED_ORDERS_RESULT = 'FETCH_RETURNED_ORDERS_RESULT';
export const FETCH_RETURNED_ORDERS_ERROR = 'FETCH_RETURNED_ORDERS_ERROR';
export const FETCH_RETURNED_ORDERS_RETRY = 'FETCH_RETURNED_ORDERS_RETRY';

export const FETCH_RETURNED_ORDER = 'FETCH_RETURNED_ORDER';
export const FETCH_RETURNED_ORDER_RESULT = 'FETCH_RETURNED_ORDER_RESULT';
export const FETCH_RETURNED_ORDER_ERROR = 'FETCH_RETURNED_ORDER_ERROR';
export const FETCH_RETURNED_ORDER_RETRY = 'FETCH_RETURNED_ORDER_RETRY';

export const CREATE_RETURNED_ORDER = 'CREATE_RETURNED_ORDER';
export const CREATE_RETURNED_ORDER_RESULT = 'CREATE_RETURNED_ORDER_RESULT';
export const CREATE_RETURNED_ORDER_ERROR = 'CREATE_RETURNED_ORDER_ERROR';
export const CREATE_RETURNED_ORDER_RETRY = 'CREATE_RETURNED_ORDER_RETRY';

export const UPDATE_RETURNED_ORDER = 'UPDATE_RETURNED_ORDER';
export const UPDATE_RETURNED_ORDER_RESULT = 'UPDATE_RETURNED_ORDER_RESULT';
export const UPDATE_RETURNED_ORDER_ERROR = 'UPDATE_RETURNED_ORDER_ERROR';
export const UPDATE_RETURNED_ORDER_RETRY = 'UPDATE_RETURNED_ORDER_RETRY';


export const fetchReturnedOrders = (data) => ({
    type: FETCH_RETURNED_ORDERS,
    data,
});

export const fetchReturnedOrder = (data) => ({
    type: FETCH_RETURNED_ORDER,
    data,
});

export const createReturnedOrder = (data) => ({
    type: CREATE_RETURNED_ORDER,
    data,
});

export const updateReturnedOrder = (data) => ({
    type: UPDATE_RETURNED_ORDER,
    data,
});