import React from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import * as PropTypes from "prop-types";
import ConfirmButton from "../../buttons/ConfirmButton";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {createOrderInvoice} from "../../../actions/ecommerce/packed";
import {getMessages, getPhoneNumberWithoutCountryCode} from "../../../constants";
import {toast} from "react-toastify";
import moment from "moment";
import CopyComponent from "../../others/CopyComponent";


class OrderDetail extends React.Component {

    static propTypes = {
        order: PropTypes.object,
        show: PropTypes.bool,
        handleClose: PropTypes.func,
    };

    static defaultProps = {
        order: {},
    };

    state = {
        order: this.props.order,
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {detail, isCreatingInvoice, message, error, retry} = this.props.packed;

        if ((!this.props.show && prevProps.show) || (this.props.show && !prevProps.show)) {
            this.setState({
                order: this.props.order,
            });
        }

        if (!isCreatingInvoice && prevProps.packed.isCreatingInvoice && !error && !retry) {
            this.setState({
                order: detail,
            });
        }
        if (!isCreatingInvoice && prevProps.packed.isCreatingInvoice && (error || retry)) {
            let messages = getMessages(message);
            if (messages.length) toast(messages[0],);
        }
    }

    handleOrderLabelPrint = (order) => {
        let w = window.open(order.label_url);
        w.print();
    };

    handleOrderInvoicePrint = (order) => {
        let w = window.open(order.invoice_url);
        w.print();
    };

    handleOrderInvoiceCreate = (order) => {
        this.props.createOrderInvoice(order);
    };

    render() {
        let {show, handleClose, packed: {isCreatingInvoice}} = this.props;
        let {order} = this.state;

        return (
            <Modal size="lg" show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Order Detail: #{order.cid}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            {!!order.cid && <CopyComponent value={order.cid}/>}

                            {!!order.shipping_address && <CopyComponent value={order.shipping_address.name}/>}

                            {!!order.shipping_address && <CopyComponent
                                value={`${order.shipping_address.street}, ${order.shipping_address.city}, ${order.shipping_address.state}, ${order.shipping_address.zipcode}`}/>}

                            {!!order.shipping_address && <CopyComponent
                                value={getPhoneNumberWithoutCountryCode(order.shipping_address.phone)}/>}
                        </div>
                        <div className="col-12 col-md-6">
                            {!!order.created_at && <p className="mb-1"><label className="text-muted mb-0">Created
                                At</label>: {moment(order.created_at).format("ddd, MMMM Do YYYY, h:mm:ss a")}
                            </p>}

                            {!!order.status_created_at &&
                            <p className="mb-1"><label className="text-muted mb-0">Status Updated At</label>:
                                {moment(order.status_created_at).format("ddd, MMMM Do YYYY, h:mm:ss a")}
                            </p>}

                            {!!order.status &&
                            <p className="mb-1"><label
                                className="text-muted mb-0">Status</label>: {order.status.replace(/_/g, ' ')}</p>}

                            {!!order.is_cod &&
                            <p className="mb-1"><label className="text-muted mb-0">Payment
                                Type</label>: {order.is_cod ? 'COD' : 'Prepaid'}
                            </p>}

                            {!!order.is_payment_captured &&
                            <p className="mb-1"><label className="text-muted mb-0">Payment
                                Received</label>: {order.is_payment_captured ? 'Yes' : 'No'}</p>}

                            {!!order.total &&
                            <p className="mb-1"><label className="text-muted mb-0">Total</label>: ₹{order.total}</p>}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button size={'sm'} variant="outline-danger" onClick={handleClose}>Close</Button>
                    {
                        !!order.label_url &&
                        <Button size={'sm'} onClick={() => this.handleOrderLabelPrint(order)}>
                            Print label
                        </Button>
                    }

                    {
                        !!order.invoice_url ?
                            <Button size={'sm'}
                                    variant={'info'}
                                    onClick={() => this.handleOrderInvoicePrint(order)}>
                                Print Invoice
                            </Button> :
                            <ConfirmButton size={'sm'}
                                           variant={'info'}
                                           confirmVariant={'warning'}
                                           confirmText={'Confirm'}
                                           text={isCreatingInvoice ? 'Creating...' : 'Create Invoice'}
                                           onClick={() => this.handleOrderInvoiceCreate(order)}/>
                    }
                </Modal.Footer>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => ({
    packed: state.ecommerce.packed,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    createOrderInvoice,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(OrderDetail);