import {call, put} from 'redux-saga/effects';
import {
    FETCH_PACKED_ORDERS,
    FETCH_PACKED_ORDERS_RESULT,
    FETCH_PACKED_ORDERS_ERROR,
    FETCH_PACKED_ORDERS_RETRY,
    CREATE_ORDER_INVOICE,
    CREATE_ORDER_INVOICE_RESULT,
    CREATE_ORDER_INVOICE_ERROR,
    CREATE_ORDER_INVOICE_RETRY,
} from '../../actions/ecommerce/packed';
import api from "../api";


export function* fetchPackedOrders(action) {
    try {
        const response = yield call(api.ecommerce.orders.packed, action.data);
        const result = yield response.json();
        console.warn('SAGA:fetchPackedOrders', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_PACKED_ORDERS_RESULT, data: result});
        } else {
            yield put({type: FETCH_PACKED_ORDERS_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_PACKED_ORDERS_RETRY, retry: true, message: e.message});
    }
}


export function* createOrderInvoice(action) {
    try {
        const response = yield call(api.ecommerce.orders.invoice, action.data);
        const result = yield response.json();
        console.warn('SAGA:createOrderInvoice', action, result);

        if (response.status === 201) {
            yield put({type: CREATE_ORDER_INVOICE_RESULT, data: result});
        } else {
            yield put({type: CREATE_ORDER_INVOICE_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: CREATE_ORDER_INVOICE_RETRY, retry: true, message: e.message});
    }
}

