import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as PropTypes from 'prop-types';
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import moment from "moment";

import {SidebarContainer} from "../../components/sidebars/MainSidebar";
import MainNavbar from "../../components/navbars/MainNavbar";
import {fetchShippedOrders} from "../../actions/ecommerce/shipped";
import {updateOrderStatus} from "../../actions/ecommerce/orders";
import {
    getPhoneNumberWithoutCountryCode, getPrice,
    getSearchParams,
    scrollToTop
} from "../../constants";
import './styles.scss';
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Spinner from "react-bootstrap/Spinner";
import PaginationComponent from "../../components/others/PaginationComponent";
import CopyComponent from "../../components/others/CopyComponent";


class ShippedOrders extends React.Component {

    static propTypes = {
        shipped: PropTypes.object,
        fetchShippedOrders: PropTypes.func,
        updateOrderStatus: PropTypes.func,
    };

    constructor(props) {
        super(props);
        let {search} = props.history.location;
        let {page = 1, q = ''} = getSearchParams(search);

        this.state = {
            order: {},
            search: q,
            searchQuery: q,
            page,
        };
    }

    componentDidMount() {
        let {search, page} = this.state;
        this.props.fetchShippedOrders({search, page});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {isFetching, message, error, retry} = this.props.shipped;
        let {orders} = this.props;
        let {search, page} = this.state;
        let prevQueryParams = getSearchParams(prevProps.location.search);
        let queryParams = getSearchParams(this.props.location.search);

        if (!isFetching && prevProps.shipped.isFetching && !error && !retry) {
            scrollToTop();
        }

        if (page !== prevState.page || search !== prevState.search) {
            this.props.fetchShippedOrders({search, page});
        }
        if (!orders.isUpdatingStatus && prevProps.orders.isUpdatingStatus && !orders.error && !orders.retry) {
            this.props.fetchShippedOrders({search, page});
        }

        if (prevQueryParams.q !== queryParams.q || prevQueryParams.page !== queryParams.page) {
            this.setState({
                search: queryParams.q || '',
                searchQuery: queryParams.q || '',
                page: parseInt(queryParams.page || "1"),
            });
        }

    }

    handleSearchClick = (e) => {
        e && e.preventDefault();
        let {searchQuery} = this.state;
        this.setState({page: 1, search: searchQuery});
        this.props.history.push(`/shipped/?q=${searchQuery}&page=${1}`);
    };

    handlePageClick = (page) => {
        let {search} = this.state;
        this.setState({page});
        this.props.history.push(`/shipped/?q=${search}&page=${page}`);
    };

    handleOrderLabelPrint = (order) => {
        let w = window.open(order.label_url);
        w.print();
    };

    handleOrderInvoicePrint = (order) => {
        let w = window.open(order.invoice_url);
        w.print();
    };

    handleUpdateOrderStatus = ({id, status}) => {
        this.props.updateOrderStatus({id: id, status});
    }

    render() {
        let {shipped: {list: {results = [], count}, isFetching}} = this.props;
        let {searchQuery, page} = this.state;

        return (
            <SidebarContainer>
                <MainNavbar pageName={'Shipped Orders'}/>

                <div className="container-fluid">
                    <div className="row pt-5">
                        <div className="col-12 col-md-8">
                            <Form onSubmit={this.handleSearchClick}>
                                <InputGroup>
                                    <Form.Control type="text"
                                                  value={searchQuery}
                                                  placeholder="Search Order..."
                                                  onChange={(e) => this.setState({searchQuery: e.target.value})}/>
                                    <InputGroup.Append>
                                        <Button type="submit" variant="success" size="sm">
                                            {isFetching ?
                                                <Spinner size="sm" animation="border"/> :
                                                "Search"
                                            }
                                        </Button>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Form>
                        </div>
                        <div className="col-12 pt-2">
                            <Table striped bordered hover cellPadding={3}>
                                <thead>
                                <tr>
                                    <th>Order #ID</th>
                                    <th>Invoice No.</th>
                                    <th>Products</th>
                                    <th>Created At</th>
                                    <th>Status Updated At</th>
                                    <th>Status</th>
                                    <th>Ship To</th>
                                    <th>Total</th>
                                    <th>Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    results.map(
                                        (order) => (
                                            <tr key={order.cid}>
                                                <td className='order-cid'>#{order.cid}</td>
                                                <td className='order-cid'>#{order.invoice_number}</td>
                                                <td className='confirm-order-products'>
                                                    <ul>
                                                        {
                                                            order.order_items.map(
                                                                (item, index) => (
                                                                    <li key={index}>
                                                                        <a href={`https://misters.in/therapy/${item.product_id}/`}
                                                                           target="_blank" title={item.title}>
                                                                            #{item.product_id} &nbsp;
                                                                            <span>₹​{getPrice(item.total_price)} &nbsp;
                                                                                Qty:{item.quantity}</span>
                                                                        </a>
                                                                    </li>
                                                                )
                                                            )
                                                        }
                                                    </ul>
                                                </td>
                                                <td className='order-date'>
                                                    {moment(order.created_at).format("ddd, MMMM Do YYYY, h:mm:ss a")}
                                                </td>
                                                <td className='order-date'>
                                                    {moment(order.status_created_at).format("ddd, MMMM Do YYYY, h:mm:ss a")}
                                                </td>
                                                <td className='order-status'>
                                                    {order.status.replace(/_/g, ' ')}
                                                </td>
                                                <td className='order-address'>
                                                    <CopyComponent value={order.cid}/>
                                                    <CopyComponent value={order.shipping_address.name}/>
                                                    <CopyComponent
                                                        value={`${order.shipping_address.street}, ${order.shipping_address.city}, ${order.shipping_address.state}, ${order.shipping_address.zipcode}`}/>
                                                    <CopyComponent
                                                        value={getPhoneNumberWithoutCountryCode(order.shipping_address.phone)}/>
                                                </td>
                                                <td className='order-total'>
                                                    ₹{order.total}
                                                </td>
                                                <td>
                                                    <ButtonGroup vertical className="action-group">
                                                        {
                                                            order.prescription &&
                                                            <Button variant='info' size={'sm'}
                                                                    onClick={() => {
                                                                        window.open(order.prescription, "_blank")
                                                                    }}>
                                                                View Prescription
                                                            </Button>
                                                        }

                                                        {
                                                            !!order.label_url &&
                                                            <Button size={'sm'}
                                                                    onClick={() => this.handleOrderLabelPrint(order)}>
                                                                Print label
                                                            </Button>
                                                        }
                                                        {
                                                            !!order.invoice_url &&
                                                                <Button size={'sm'}
                                                                        variant={'info'}
                                                                        onClick={() => this.handleOrderInvoicePrint(order)}>
                                                                    Print Invoice
                                                                </Button>
                                                        }
                                                        {/*<Button variant={'danger'} size={'sm'}*/}
                                                        {/*        onClick={() => this.handleUpdateOrderStatus({id: order.id, status: "delivered"})}>*/}
                                                        {/*    Mark delivered*/}
                                                        {/*</Button>*/}
                                                    </ButtonGroup>
                                                </td>
                                            </tr>
                                        )
                                    )
                                }
                                </tbody>
                            </Table>
                        </div>
                        <div className="col-12 py-3">
                            {!!count &&
                            <PaginationComponent page={parseInt(page)} count={parseInt(count)}
                                                 onClick={this.handlePageClick}/>}
                        </div>
                    </div>
                </div>

            </SidebarContainer>
        )
    }
}

const mapStateToProps = (state) => ({
    shipped: state.ecommerce.shipped,
    orders: state.ecommerce.orders,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    fetchShippedOrders,
    updateOrderStatus,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(ShippedOrders);
