import React from 'react';
import {connect} from "react-redux";
import * as PropTypes from "prop-types";
import {bindActionCreators} from "redux";

import MainNavbar from "../../components/navbars/MainNavbar";
import {SidebarContainer} from "../../components/sidebars/MainSidebar";
import './styles.scss';
import {fetchPharmacyProfile} from "../../actions/pharmacy/pharmacy";
import {Col, Form} from "react-bootstrap";


class Account extends React.Component {
    static propTypes = {
        pharmacy: PropTypes.object,
        fetchPharmacyProfile: PropTypes.func,
    };

    static defaultProps = {};

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        this.props.fetchPharmacyProfile();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {pharmacy: {isFetching, error, retry}} = this.props;

        if (!isFetching && prevProps.pharmacy.isFetching && !error && !retry) {

        }
    }

    render() {
        let {pharmacy: {detail}} = this.props;
        return (
            <SidebarContainer>
                <MainNavbar pageName={'Account'}/>
                <div className="container-fluid mt-3">
                    <div className="row">
                        <div className="col-12 col-md-6 offset-md-3">
                            <Form>
                                <Form.Row>
                                    <Form.Group as={Col} controlId="name">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control type="text" placeholder="Name" value={detail.name || ''}
                                                      disabled/>
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="code">
                                        <Form.Label>Code</Form.Label>
                                        <Form.Control type="text" placeholder="Code" value={detail.code || ''}
                                                      disabled/>
                                    </Form.Group>
                                </Form.Row>

                                <Form.Group controlId="email">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="text" placeholder="Email" value={detail.email || ''}
                                                  disabled/>
                                </Form.Group>

                                <Form.Group controlId="shiprocket_email">
                                    <Form.Label>Shiprocket Email</Form.Label>
                                    <Form.Control type="text" placeholder="Shiprocket Email"
                                                  value={detail.shiprocket_email || ''} disabled/>
                                </Form.Group>

                                <Form.Group controlId="street">
                                    <Form.Label>Street</Form.Label>
                                    <Form.Control type="text" placeholder="Street" value={detail.street || ''}
                                                  disabled/>
                                </Form.Group>

                                <Form.Row>
                                    <Form.Group as={Col} controlId="city">
                                        <Form.Label>City</Form.Label>
                                        <Form.Control type="text" placeholder="City" value={detail.city || ''}
                                                      disabled/>
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="state">
                                        <Form.Label>State</Form.Label>
                                        <Form.Control type="text" placeholder="State" value={detail.state || ''}
                                                      disabled/>
                                    </Form.Group>
                                </Form.Row>

                                <Form.Row>
                                    <Form.Group as={Col} controlId="phone">
                                        <Form.Label>Phone</Form.Label>
                                        <Form.Control type="text" placeholder="Phone" value={detail.phone || ''}
                                                      disabled/>
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="zipcode">
                                        <Form.Label>Zip Code</Form.Label>
                                        <Form.Control type="text" placeholder="Zip Code" value={detail.zipcode || ''}
                                                      disabled/>
                                    </Form.Group>
                                </Form.Row>
                            </Form>
                        </div>
                    </div>
                </div>
            </SidebarContainer>
        )
    }
}

const mapStateToProps = (state) => ({
    pharmacy: state.pharmacy.pharmacy,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    fetchPharmacyProfile,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(Account);