import {
    REQUEST_OTP,
    REQUEST_OTP_RESULT,
    REQUEST_OTP_ERROR,
    REQUEST_OTP_RETRY,
    USER_LOGIN,
    USER_LOGIN_RESULT,
    USER_LOGIN_ERROR,
    USER_LOGIN_RETRY,
    TOKEN_VERIFY,
    TOKEN_VERIFY_RESULT,
    TOKEN_VERIFY_ERROR,
    TOKEN_VERIFY_RETRY,
} from '../../actions/accounts/login';


const initialState = {
    detail: {},
    isFetching: false,
    isFetchingLogin: false,
    error: false,
    message: null,
    retry: false,
    loggedIn: false,
    otpSent: false,
    tokenVerified: false,
};


const reducer = (state=initialState, action) => {
    // console.log(action, state);

    switch (action.type) {
        case REQUEST_OTP:
            return {...state, isFetching: true, error: false, message: null, retry: false, otpSent: false};
        case REQUEST_OTP_RESULT:
            return {...state, isFetching: false, otpSent: true};
        case REQUEST_OTP_ERROR:
            return {...state, isFetching: false, error: true, message: action.message};
        case REQUEST_OTP_RETRY:
            return {...state, isFetching: false, retry: true, message: action.message};

        case USER_LOGIN:
            return {...state, isFetchingLogin: true, error: false, message: null, otpSent: false, retry: false,};
        case USER_LOGIN_RESULT:
            return {...state, isFetchingLogin: false, detail: action.data, otpSent: action.otpSent};
        case USER_LOGIN_ERROR:
            return {...state, isFetchingLogin: false, error: true, message: action.message};
        case USER_LOGIN_RETRY:
            return {...state, isFetchingLogin: false, retry: true, message: action.message};

        case TOKEN_VERIFY:
            return {...state, isFetching: true, tokenVerified: false};
        case TOKEN_VERIFY_RESULT:
            return {...state, isFetching: false, tokenVerified: true};
        case TOKEN_VERIFY_ERROR:
            return {...state, isFetching: false, tokenVerified: false};
        case TOKEN_VERIFY_RETRY:
            return {...state, isFetching: false, tokenVerified: false};
        default:
            return state;
    }

};

export default reducer;
