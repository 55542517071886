export const REQUEST_OTP = 'REQUEST_OTP';
export const REQUEST_OTP_RESULT = 'REQUEST_OTP_RESULT';
export const REQUEST_OTP_ERROR = 'REQUEST_OTP_ERROR';
export const REQUEST_OTP_RETRY = 'REQUEST_OTP_RETRY';

export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGIN_RESULT = 'USER_LOGIN_RESULT';
export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR';
export const USER_LOGIN_RETRY = 'USER_LOGIN_RETRY';

export const TOKEN_VERIFY = 'TOKEN_VERIFY';
export const TOKEN_VERIFY_RESULT = 'TOKEN_VERIFY_RESULT';
export const TOKEN_VERIFY_ERROR = 'TOKEN_VERIFY_ERROR';
export const TOKEN_VERIFY_RETRY = 'TOKEN_VERIFY_RETRY';

export const requestOtp = (data) => ({
    type: REQUEST_OTP,
    data,
});

export const userLogin = (data) => ({
    type: USER_LOGIN,
    data,
});

export const tokenVerify = () => ({
    type: TOKEN_VERIFY,
});
