import React from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import * as PropTypes from "prop-types";
import Select from "react-select";


export default class OrderRevert extends React.Component {

    static propTypes = {
        order: PropTypes.object,
        show: PropTypes.bool,
        handleClose: PropTypes.func,
        handleRevert: PropTypes.func,
    };

    static defaultProps = {
        order: {},
    };

    state = {
        orderId: '',
        revert_reason: '',
        revert_reason_select: '',
        showText: false,
    };

    revertReason = [
        {value: 'address_too_short', label: 'ADDRESS TO SHORT'},
        {value: 'wrong_address', label: 'WRONG ADDRESS'},
        {value: 'customer_not_interested', label: 'CUSTOMER NOT INTERESTED'},
        {value: 'rejected_by_delivery_partner', label: 'REJECTED BY DELIVERY PARTNER'},
        {value: 'address_not_in_english', label: 'ADDRESS NOT IN ENGLISH'},
        {value: 'no_prescription', label: 'NO PRESCRIPTION'},
        {value: 'advised_by_call_center', label: 'ADVISED BY CALL CENTER'},
        {value: 'other', label: 'OTHER'},
    ];

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.props.show && prevProps.show) {
            this.setState({
                orderId: '',
                revert_reason: '',
                revert_reason_select: '',
                showText: false,
            });
        }
    }

    handleRevertReasonChange = (reason) => {
        if (reason === 'other') {
            this.setState({showText: true, revert_reason: '', revert_reason_select: reason});
        } else {
            this.setState({revert_reason_select: reason, showText: false, revert_reason: ''});
        }
    };

    handleRevertClick = () => {
        let {order, handleRevert} = this.props;
        let {revert_reason, revert_reason_select, showText} = this.state;

        if (showText) {
            handleRevert({id: order.id, message: revert_reason})
        } else {
            handleRevert({id: order.id, message: revert_reason_select})
        }
    };

    render() {
        let {order, show, handleClose} = this.props;
        let {revert_reason, revert_reason_select, showText} = this.state;

        return (
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Are You Sure You want to revert this Order?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>If yes then type the order ID - {order.cid} below and press Revert Order</p>
                    <Form.Control size="md" type="text" placeholder="Order ID"
                                  value={this.state.orderId}
                                  onChange={(e) => this.setState({orderId: e.target.value})}/>
                    <Form.Group controlId="revert_reason_select">
                        <Form.Label>Select Revert reason</Form.Label>
                        <Select options={this.revertReason.map(reason => ({label: reason.label, value: reason.value}))}
                                value={this.revertReason.filter(reason => reason.value === revert_reason_select).map(reason => ({
                                    label: reason.label,
                                    value: reason.value
                                }))}
                                onChange={(reason) => this.handleRevertReasonChange(reason.value)}/>
                    </Form.Group>
                    {showText && <Form.Group controlId="revert_reason">
                        <Form.Label>Other Revert reason</Form.Label>
                        <Form.Control as="textarea" rows="3" value={revert_reason}
                                      onChange={(e) => this.setState({revert_reason: e.target.value})}/>
                    </Form.Group>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-danger" onClick={handleClose}>Close</Button>
                    <Button variant="danger" onClick={() => this.handleRevertClick()}
                            disabled={!(order.cid === this.state.orderId)}>
                        Revert This Order
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}