export const FETCH_INVOICE_ITEMS = 'FETCH_INVOICE_ITEMS';
export const FETCH_INVOICE_ITEMS_RESULT = 'FETCH_INVOICE_ITEMS_RESULT';
export const FETCH_INVOICE_ITEMS_ERROR = 'FETCH_INVOICE_ITEMS_ERROR';
export const FETCH_INVOICE_ITEMS_RETRY = 'FETCH_INVOICE_ITEMS_RETRY';

export const CREATE_INVOICE_ITEMS = 'CREATE_INVOICE_ITEMS';
export const CREATE_INVOICE_ITEMS_RESULT = 'CREATE_INVOICE_ITEMS_RESULT';
export const CREATE_INVOICE_ITEMS_ERROR = 'CREATE_INVOICE_ITEMS_ERROR';
export const CREATE_INVOICE_ITEMS_RETRY = 'CREATE_INVOICE_ITEMS_RETRY';

export const UPDATE_INVOICE_ITEMS = 'UPDATE_INVOICE_ITEMS';
export const UPDATE_INVOICE_ITEMS_RESULT = 'UPDATE_INVOICE_ITEMS_RESULT';
export const UPDATE_INVOICE_ITEMS_ERROR = 'UPDATE_INVOICE_ITEMS_ERROR';
export const UPDATE_INVOICE_ITEMS_RETRY = 'UPDATE_INVOICE_ITEMS_RETRY';

export const DELETE_INVOICE_ITEMS = 'DELETE_INVOICE_ITEMS';
export const DELETE_INVOICE_ITEMS_RESULT = 'DELETE_INVOICE_ITEMS_RESULT';
export const DELETE_INVOICE_ITEMS_ERROR = 'DELETE_INVOICE_ITEMS_ERROR';
export const DELETE_INVOICE_ITEMS_RETRY = 'DELETE_INVOICE_ITEMS_RETRY';

export const FETCH_INVOICE_ITEMS_BATCH = 'FETCH_INVOICE_ITEMS_BATCH';
export const FETCH_INVOICE_ITEMS_BATCH_RESULT = 'FETCH_INVOICE_ITEMS_BATCH_RESULT';
export const FETCH_INVOICE_ITEMS_BATCH_ERROR = 'FETCH_INVOICE_ITEMS_BATCH_ERROR';
export const FETCH_INVOICE_ITEMS_BATCH_RETRY = 'FETCH_INVOICE_ITEMS_BATCH_RETRY';


export const fetchInvoiceItems = (data) => ({
    type: FETCH_INVOICE_ITEMS,
    data,
});

export const createInvoiceItems = (data) => ({
    type: CREATE_INVOICE_ITEMS,
    data,
});

export const updateInvoiceItems = (data) => ({
    type: UPDATE_INVOICE_ITEMS,
    data,
});

export const deleteInvoiceItems = (data) => ({
    type: DELETE_INVOICE_ITEMS,
    data,
});

export const fetchInvoiceItemsBatch = (data) => ({
    type: FETCH_INVOICE_ITEMS_BATCH,
    data,
});