import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Button from "react-bootstrap/Button";
import {copyText} from "../../../constants";


export default class CopyComponent extends PureComponent {

    static propTypes = {
        value: PropTypes.any,
    };

    static defaultProps = {
        value: '',
    };

    render() {
        let {value} = this.props;

        return (
            <div className="d-flex justify-content-between align-items-center pb-1">
                <span>{value}</span>
                <Button variant="outline-success" className="far fa-copy p-1"
                        size="sm" onClick={() => copyText(value)}/>
            </div>
        )
    }
}
