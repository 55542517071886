import {takeEvery} from 'redux-saga/effects';

import {createConsultation, fetchConsultations, fetchConsultationsMaster} from './consultations';
import {
    CREATE_CONSULTATION,
    FETCH_CONSULTATIONS,
    FETCH_CONSULTATIONS_MASTER
} from "../../actions/consultations/consultations";


export default [
    takeEvery(CREATE_CONSULTATION, createConsultation),
    takeEvery(FETCH_CONSULTATIONS, fetchConsultations),
    takeEvery(FETCH_CONSULTATIONS_MASTER, fetchConsultationsMaster),
];
