// External Imports
import {combineReducers} from 'redux';
import {connectRouter, routerMiddleware, } from 'connected-react-router';
// My Imports
import accounts from './accounts';
import ecommerce from './ecommerce';
import consultations from './consultations';
import pharmacy from './pharmacy';

const reducers = (history) => combineReducers({
    router: connectRouter(history),
    accounts,
    ecommerce,
    consultations,
    pharmacy,
});


export default reducers;
