export const FETCH_PHARMACY_ITEMS = 'FETCH_PHARMACY_ITEMS';
export const FETCH_PHARMACY_ITEMS_RESULT = 'FETCH_PHARMACY_ITEMS_RESULT';
export const FETCH_PHARMACY_ITEMS_ERROR = 'FETCH_PHARMACY_ITEMS_ERROR';
export const FETCH_PHARMACY_ITEMS_RETRY = 'FETCH_PHARMACY_ITEMS_RETRY';


export const fetchItems = (data) => ({
    type: FETCH_PHARMACY_ITEMS,
    data,
});
