import React from 'react';
import {connect} from "react-redux";
import * as PropTypes from "prop-types";
import {bindActionCreators} from "redux";

import MainNavbar from "../../components/navbars/MainNavbar";
import {SidebarContainer} from "../../components/sidebars/MainSidebar";
import './styles.scss';
import {fetchReport} from "../../actions/pharmacy/report";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";


class Report extends React.Component {
    static propTypes = {
        report: PropTypes.object,
        fetchReport: PropTypes.func,
    };

    static defaultProps = {};

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        this.props.fetchReport();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    render() {
        let {report: {detail}} = this.props;
        let reportList = Object.values(detail);
        return (
            <SidebarContainer>
                <MainNavbar pageName={'Report'}/>
                <div className="container-fluid mt-5">
                    <div className="row">
                        <div className="col-12">
                            <Table striped bordered responsive>
                                <tbody>
                                {reportList.map(dailyReport => (
                                    <tr key={dailyReport.label}>
                                        <th>{dailyReport.label}</th>
                                        <td>{dailyReport.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </SidebarContainer>
        )
    }
}

const mapStateToProps = (state) => ({
    report: state.pharmacy.report,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    fetchReport,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(Report);