/* global require */

import * as queryString from "query-string";
import {toast} from "react-toastify";

export const DEBUG = process.env.REACT_APP_DEBUG == "true";
export const HOST = process.env.REACT_APP_HOST;
export const NODE_SERVER_HOST = process.env.REACT_APP_NODE_SERVER_HOST;


export const getPhoneNumberWithoutCountryCode = (number) => {
    if (!number) number = '';
    if (number.startsWith('+91')) {
        return number.slice(3, number.length);
    }
    return number;
};

export const getPhoneNumber = (phone = '') => {
    if (!phone.startsWith('+91')) {
        return `+91${phone}`;
    }
    return phone;
};

export const getMessages = (message) => {
    let messages = [];
    if (typeof message === 'string' && message.length > 0) {
        messages.push(message);
    }
    if (typeof message === 'object' && message) {
        let objectMessages = Object.values(message);
        objectMessages.map(
          (msg, index) => {
                const keyName = Object.keys(message)[index];
                if (typeof msg === "string") {
                    messages = [...messages, `${keyName}: ${msg}`];
                }
                else if (Array.isArray(msg)) {
                    messages = [...messages, ...msg.map(val => `${keyName}: ${val}`)];
                }
                else if (typeof msg === 'object'){
                    messages = [...messages, ...getMessages(msg).map(item => `${keyName} -> ${item}`)];
                }
            }
        );
    }
    return messages;
};

export const getRupeeFromPaisa = (paisa) => parseFloat(paisa) / 100;

export const fieldTypes = {
    TEXT: 'text',
    INTEGER: 'integer',
    FLOAT: 'float',
    BOOL: 'bool',
    FILE: 'file',
    CHOICE: 'choice',
    MULTIPLE_CHOICE: 'multiple_choice',
    READ_ONLY_TEXT: 'read_only_text',
    DATE: 'date',
};

export const documentTypes = {
    REPORT: 'report',
    PRESCRIPTION: 'prescription',
    VITAL: 'vital',
    NOTE: 'note',
};

export const Images = {
    error404: '/assets/images/error404.png',
};

export const scrollToTop = (scrollDuration) => {
    const scrollHeight = window.scrollY;
    const scrollStep = Math.PI / (scrollDuration / 15);
    const cosParameter = scrollHeight / 2;
    let scrollCount = 0;
    let scrollMargin;
    let scrollInterval = setInterval(function () {
        if (window.scrollY != 0) {
            scrollCount = scrollCount + 1;
            scrollMargin = cosParameter - cosParameter * Math.cos(scrollCount * scrollStep);
            window.scrollTo(0, (scrollHeight - scrollMargin));
        } else clearInterval(scrollInterval);
    }, 15);
};

export const getSearchParams = (path) => {
    let values = path.split('?');
    return queryString.parse(`?${values[values.length - 1]}`);
};

export const copyText = (text) => {
    toast.dismiss();
    let copyText = text;
    let element = document.createElement("input");
    element.type = 'text';
    element.value = copyText;
    element.style.position = "fixed"; // Prevent MS edge scrolling.
    document.body.append(element);
    element.select();
    document.execCommand("copy");
    document.body.removeChild(element);
    toast(`Copied: ${text}`,);
};

export const zTableX = [-3.4, -3.3, -3.2, -3.1, -3, -2.9, -2.8, -2.7, -2.6, -2.5, -2.4, -2.3, -2.2, -2.1, -2, -1.9, -1.8, -1.7, -1.6, -1.5, -1.4, -1.3, -1.2, -1.1, -1, -0.9, -0.8, -0.7, -0.6, -0.5, -0.4, -0.3, -0.2, -0.1, 0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.1, 1.2, 1.3, 1.4, 1.5, 1.6, 1.7, 1.8, 1.9, 2, 2.1, 2.2, 2.3, 2.4, 2.5, 2.6, 2.7, 2.8, 2.9, 3, 3.1, 3.2, 3.3, 3.4];


export const getStorageItem = (key, defaultValue = null) => {
    try {
        return localStorage.getItem(key) || defaultValue
    } catch (e) {
        return defaultValue;
    }
};


export const setStorageItem = (key, value) => {
    try {
        return localStorage.setItem(key, value);
    } catch (e) {
        return null;
    }
};


export const removeStorageItem = (key) => {
    try {
        return localStorage.removeItem(key);
    } catch (e) {
        return null;
    }
};

export const getPrice = (price) => (parseFloat(price) / 100).toFixed(2);
export const getPriceInPaisa = (price) => parseFloat(price) * 100;

export const getQueryString = (params) => {
    return Object.keys(params).map(key => key + '=' + params[key]).join('&');
};

export const isObjectsEqual = (object, prevObject) => {
    let status = true;
    if (!Object.keys(object).length && !!Object.keys(prevObject).length)
        return false;

    Object.keys(object).forEach(key => {
        if (object[key] !== prevObject[key])
            status = false;
    });

    return status;
};
