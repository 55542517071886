import React, {PureComponent} from 'react';

import './styles.scss';
import MainSidebar from "./MainSidebar";


class SidebarContainer extends PureComponent {

    breakpoint = 991;

    state = {
        width: window.innerWidth,
        height: window.innerHeight,
        active: window.innerWidth > this.breakpoint,
    };

    componentDidMount() {
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        // console.log(this.state.width);
        let active = false;
        if (this.state.width > this.breakpoint) {
            active = true;
        }
        this.setState({width: window.innerWidth, height: window.innerHeight, active});
    };
    toggleSidebar = () => {
        this.setState(prevState => ({active: !prevState.active}));
    };

    render() {

        return (
            <div className={'sidebar-container ' + (this.state.active ? 'active' : '')}>
                <MainSidebar active={this.state.active}
                             toggleSidebar={this.toggleSidebar}/>
                {this.props.children}
            </div>
        )
    }
}


export default SidebarContainer;
