import {takeEvery} from 'redux-saga/effects';

import {FETCH_PHARMACY_PROFILE} from '../../actions/pharmacy/pharmacy';
import {fetchPharmacyProfile} from './pharmacy';


import {FETCH_BATCHES, UPDATE_BATCH, CREATE_BATCH} from '../../actions/pharmacy/batches';
import {fetchBatches, updateBatch, createBatch} from './batches';

import {FETCH_PHARMACY_ITEMS} from '../../actions/pharmacy/items';
import {fetchItems} from './items';

import {FETCH_REPORT} from '../../actions/pharmacy/report';
import {fetchReport} from './report';

import {FETCH_CREDIT_NOTES} from '../../actions/pharmacy/creditnotes';
import {fetchCreditNotes} from './creditnotes';

import {
    FETCH_RETURNED_ORDERS,
    FETCH_RETURNED_ORDER,
    CREATE_RETURNED_ORDER,
    UPDATE_RETURNED_ORDER
} from '../../actions/pharmacy/returned';
import {fetchReturnedOrders, fetchReturnedOrder, createReturnedOrder, updateReturnedOrder} from './returned';

import {
    FETCH_INVOICE_ITEMS,
    UPDATE_INVOICE_ITEMS,
    FETCH_INVOICE_ITEMS_BATCH,
    DELETE_INVOICE_ITEMS,
    CREATE_INVOICE_ITEMS
} from '../../actions/pharmacy/invoiceitems';
import {
    fetchInvoiceItems,
    fetchInvoiceItemsBatch,
    updateInvoiceItems,
    deleteInvoiceItems,
    createInvoiceItems
} from './invoiceitems';

import {FETCH_INVOICES, CREATE_INVOICE, UPDATE_INVOICE} from '../../actions/pharmacy/invoices';
import {fetchInvoices, createInvoice, updateInvoice} from './invoices';

import {
    FETCH_DELIVERY_CHALLANS,
    CREATE_DELIVERY_CHALLAN,
    UPDATE_DELIVERY_CHALLAN,
    DELETE_DELIVERY_CHALLAN
} from '../../actions/pharmacy/deliverychallans';
import {fetchDeliveryChallans, createDeliveryChallan, updateDeliveryChallan, deleteDeliveryChallan} from './deliverychallans';

import {
    FETCH_DELIVERY_CHALLAN_ITEMS,
    UPDATE_DELIVERY_CHALLAN_ITEM,
    DELETE_DELIVERY_CHALLAN_ITEM,
    CREATE_DELIVERY_CHALLAN_ITEM
} from '../../actions/pharmacy/deliverychallanitems';
import {
    fetchDeliveryChallanItems,
    updateDeliveryChallanItem,
    deleteDeliveryChallanItem,
    createDeliveryChallanItem
} from './deliverychallanitems';

export default [
    takeEvery(FETCH_PHARMACY_PROFILE, fetchPharmacyProfile),

    takeEvery(FETCH_BATCHES, fetchBatches),
    takeEvery(UPDATE_BATCH, updateBatch),
    takeEvery(CREATE_BATCH, createBatch),

    takeEvery(FETCH_PHARMACY_ITEMS, fetchItems),

    takeEvery(FETCH_REPORT, fetchReport),

    takeEvery(FETCH_CREDIT_NOTES, fetchCreditNotes),

    takeEvery(FETCH_RETURNED_ORDERS, fetchReturnedOrders),
    takeEvery(FETCH_RETURNED_ORDER, fetchReturnedOrder),
    takeEvery(CREATE_RETURNED_ORDER, createReturnedOrder),
    takeEvery(UPDATE_RETURNED_ORDER, updateReturnedOrder),

    takeEvery(FETCH_INVOICE_ITEMS, fetchInvoiceItems),
    takeEvery(CREATE_INVOICE_ITEMS, createInvoiceItems),
    takeEvery(UPDATE_INVOICE_ITEMS, updateInvoiceItems),
    takeEvery(DELETE_INVOICE_ITEMS, deleteInvoiceItems),
    takeEvery(FETCH_INVOICE_ITEMS_BATCH, fetchInvoiceItemsBatch),

    takeEvery(FETCH_INVOICES, fetchInvoices),
    takeEvery(CREATE_INVOICE, createInvoice),
    takeEvery(UPDATE_INVOICE, updateInvoice),

    takeEvery(FETCH_DELIVERY_CHALLANS, fetchDeliveryChallans),
    takeEvery(CREATE_DELIVERY_CHALLAN, createDeliveryChallan),
    takeEvery(UPDATE_DELIVERY_CHALLAN, updateDeliveryChallan),
    takeEvery(DELETE_DELIVERY_CHALLAN, deleteDeliveryChallan),

    takeEvery(FETCH_DELIVERY_CHALLAN_ITEMS, fetchDeliveryChallanItems),
    takeEvery(UPDATE_DELIVERY_CHALLAN_ITEM, updateDeliveryChallanItem),
    takeEvery(DELETE_DELIVERY_CHALLAN_ITEM, deleteDeliveryChallanItem),
    takeEvery(CREATE_DELIVERY_CHALLAN_ITEM, createDeliveryChallanItem),
];
