import store from "./store";

export const CHEMIST = 'chemist';

export const checkRole = (role, user = store.getState().accounts.login.detail) => {
    return (user.permissions.roles || []).includes(role);
};

export const isUserChemist = (user = store.getState().accounts.login.detail) => {
    return checkRole(CHEMIST, user);
};