import {
    FETCH_DELIVERY_CHALLAN_ITEMS,
    FETCH_DELIVERY_CHALLAN_ITEMS_RESULT,
    FETCH_DELIVERY_CHALLAN_ITEMS_ERROR,
    FETCH_DELIVERY_CHALLAN_ITEMS_RETRY,

    CREATE_DELIVERY_CHALLAN_ITEM,
    CREATE_DELIVERY_CHALLAN_ITEM_RESULT,
    CREATE_DELIVERY_CHALLAN_ITEM_ERROR,
    CREATE_DELIVERY_CHALLAN_ITEM_RETRY,

    UPDATE_DELIVERY_CHALLAN_ITEM,
    UPDATE_DELIVERY_CHALLAN_ITEM_RESULT,
    UPDATE_DELIVERY_CHALLAN_ITEM_ERROR,
    UPDATE_DELIVERY_CHALLAN_ITEM_RETRY,

    DELETE_DELIVERY_CHALLAN_ITEM,
    DELETE_DELIVERY_CHALLAN_ITEM_RESULT,
    DELETE_DELIVERY_CHALLAN_ITEM_ERROR,
    DELETE_DELIVERY_CHALLAN_ITEM_RETRY,
} from '../../actions/pharmacy/deliverychallanitems';


const initialState = {
    list: [],
    batchList: [],
    detail: {},
    isFetching: false,
    isCreating: false,
    isUpdating: false,
    isDeleting: false,
    error: false,
    message: null,
    retry: false,
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DELIVERY_CHALLAN_ITEMS:
            return {...state, isFetching: true, error: false, message: null, retry: false};
        case FETCH_DELIVERY_CHALLAN_ITEMS_RESULT:
            return {...state, isFetching: false, list: action.data};
        case FETCH_DELIVERY_CHALLAN_ITEMS_ERROR:
            return {...state, isFetching: false, error: true, message: action.message};
        case FETCH_DELIVERY_CHALLAN_ITEMS_RETRY:
            return {...state, isFetching: false, retry: true, message: action.message};

        case CREATE_DELIVERY_CHALLAN_ITEM:
            return {...state, isCreating: true, error: false, message: null, retry: false};
        case CREATE_DELIVERY_CHALLAN_ITEM_RESULT:
            return {...state, isCreating: false, detail: action.data};
        case CREATE_DELIVERY_CHALLAN_ITEM_ERROR:
            return {...state, isCreating: false, error: true, message: action.message};
        case CREATE_DELIVERY_CHALLAN_ITEM_RETRY:
            return {...state, isCreating: false, retry: true, message: action.message};

        case UPDATE_DELIVERY_CHALLAN_ITEM:
            return {...state, isUpdating: true, error: false, message: null, retry: false};
        case UPDATE_DELIVERY_CHALLAN_ITEM_RESULT:
            return {...state, isUpdating: false, detail: action.data};
        case UPDATE_DELIVERY_CHALLAN_ITEM_ERROR:
            return {...state, isUpdating: false, error: true, message: action.message};
        case UPDATE_DELIVERY_CHALLAN_ITEM_RETRY:
            return {...state, isUpdating: false, retry: true, message: action.message};

        case DELETE_DELIVERY_CHALLAN_ITEM:
            return {...state, isDeleting: true, error: false, message: null, retry: false};
        case DELETE_DELIVERY_CHALLAN_ITEM_RESULT:
            return {...state, isDeleting: false, detail: action.data};
        case DELETE_DELIVERY_CHALLAN_ITEM_ERROR:
            return {...state, isDeleting: false, error: true, message: action.message};
        case DELETE_DELIVERY_CHALLAN_ITEM_RETRY:
            return {...state, isDeleting: false, retry: true, message: action.message};

        default:
            return state;
    }
};

export default reducer;
