// External Imports
import React from "react";
// My Imports
import {
    ConfirmedOrders,
    Login,
    Logout,
    Orders,
    Account,
    Batches,
    ShippedOrders,
    Report,
    CreditNotes,
    Invoices,
    DeliveryChallan,
} from './screens';


export default [
    // {
    //     exact: true,
    //     path: '/',
    //     component: Home,
    // },
    {
        exact: true,
        path: '/',
        component: Orders,
    },
    {
        exact: true,
        path: '/packed/',
        component: ConfirmedOrders,
    },
    {
        exact: true,
        path: '/batches/',
        component: Batches,
    },
    {
        exact: true,
        path: '/shipped/',
        component: ShippedOrders,
    },
    {
        exact: true,
        path: '/report/',
        component: Report,
    },
    {
        exact: true,
        path: '/credit-notes/',
        component: CreditNotes,
    },
    {
        exact: true,
        path: '/invoices/',
        component: Invoices,
    },
    {
        exact: true,
        path: '/delivery-challan/',
        component: DeliveryChallan,
    },
    {
        exact: true,
        path: '/account',
        component: Account,
    },
    {
        exact: true,
        path: '/login',
        component: Login,
    },
    {
        exact: true,
        path: '/logout',
        component: Logout,
    },

];

