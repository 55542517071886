import React from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import * as PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import Select from "react-select";
import {Col} from "react-bootstrap";
import {getPhoneNumber, getPhoneNumberWithoutCountryCode} from "../../../constants";


class InvoiceItemsDetailModal extends React.Component {

    static propTypes = {
        invoice: PropTypes.object,
        show: PropTypes.bool,
        handleClose: PropTypes.func,
        handleSubmit: PropTypes.func,
    };

    state = {
        is_cod: false,
        order_cid: '',
        channel: '',
        address: {
            name: '',
            street: '',
            street2: '',
            landmark: '',
            city: '',
            state: '',
            zipcode: '',
            phone: '',
        },
        message: undefined,
    };

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {invoices: {isCreating, error, message, retry}} = this.props;
        if (!!this.props.show && !prevProps.show) {
            // let {shipping_address: {name = '', street = '', street2 = '', landmark = '', city = '', state = '', zipcode = '', phone: ''}} = this.props.order;
            this.setState({
                is_cod: false,
                order_cid: '',
                channel: '',
                address: {
                    name: '',
                    street: '',
                    street2: '',
                    landmark: '',
                    city: '',
                    state: '',
                    zipcode: '',
                    phone: '',
                },
                message: undefined,
            });
        }

        if (!isCreating && prevProps.invoices.isCreating && (error || retry)) {
            this.setState({message});
        }
    }

    channelList = [
        {label: '1 MG', value: '1mg'},
        {label: 'AMAZON', value: 'amazon'},
        {label: 'lybrate', value: 'lybrate'},
        {label: 'clinic', value: 'clinic'},
    ]

    render() {
        let {invoice, show, handleSubmit, handleClose} = this.props;
        let {address: {name, street, city, state, zipcode, street2, landmark, phone}, address, is_cod, order_cid, channel, message} = this.state;
        let enableCreation = name.length && street.length && city.length && state.length && zipcode.length && channel;

        return (
            <Modal size="lg" show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Invoice Detail</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Row>
                        <Form.Group as={Col} controlId="channel">
                            <Form.Label>Select Channel</Form.Label>
                            <Select placeholder="Select Channel"
                                    options={this.channelList}
                                    value={this.channelList.filter(c => c.value === channel)}
                                    onChange={(value) => {
                                        this.setState({
                                            channel: value.value
                                        })
                                    }}/>
                            <Form.Control type="hidden" isInvalid={!!message && !!message.channel}/>
                            <Form.Control.Feedback type="invalid">
                                {!!message && message.channel}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} controlId="order_cid">
                            <Form.Label>Order CID</Form.Label>
                            <Form.Control type="text" placeholder="Order CID"
                                          onChange={(e) => this.setState({
                                              order_cid: e.target.value
                                          })}
                                          isInvalid={!!message && !!message.order_cid}
                                          value={order_cid}/>
                            <Form.Control.Feedback type="invalid">
                                {!!message && message.order_cid}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="is_cod">
                            <Form.Check type="checkbox" label="Is COD"
                                        checked={is_cod}
                                        isInvalid={!!message && !!message.is_cod}
                                        onChange={(e) => this.setState({
                                            is_cod: !is_cod
                                        })}/>
                            <Form.Control.Feedback type="invalid">
                                {!!message && message.is_cod}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="formName">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter Name"
                                          onChange={(e) => this.setState({
                                              address: {
                                                  ...address,
                                                  name: e.target.value
                                              }
                                          })}
                                          isInvalid={!!message && !!message.address && !!message.address.name}
                                          value={name}/>
                            <Form.Control.Feedback type="invalid">
                                {!!message && !!message.address && message.address.name}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} controlId="formStreet">
                            <Form.Label>Street</Form.Label>
                            <Form.Control type="text" placeholder="Flat, House no., Building, Company, Apartment"
                                          onChange={(e) => this.setState({
                                              address: {
                                                  ...address,
                                                  street: e.target.value
                                              }
                                          })}
                                          isInvalid={!!message && !!message.address && !!message.address.street}
                                          value={street}/>
                            <Form.Control.Feedback type="invalid">
                                {!!message && !!message.address && message.address.street}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="formStreet2">
                            <Form.Label>Street2</Form.Label>
                            <Form.Control type="text" placeholder="Area, Colony, Street, Sector, Village"
                                          onChange={(e) => this.setState({
                                              address: {
                                                  ...address,
                                                  street2: e.target.value
                                              }
                                          })}
                                          isInvalid={!!message && !!message.address && !!message.address.street2}
                                          value={street2}/>
                            <Form.Control.Feedback type="invalid">
                                {!!message && !!message.address && message.address.street2}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} controlId="formLandmark">
                            <Form.Label>Landmark</Form.Label>
                            <Form.Control type="text" placeholder="Landmark e.g. Near AIIMS Fliover, etc."
                                          onChange={(e) => this.setState({
                                              address: {
                                                  ...address,
                                                  landmark: e.target.value
                                              }
                                          })}
                                          isInvalid={!!message && !!message.address && !!message.address.landmark}
                                          value={landmark}/>
                            <Form.Control.Feedback type="invalid">
                                {!!message && !!message.address && message.address.landmark}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="formCity">
                            <Form.Label>City</Form.Label>
                            <Form.Control type="text" placeholder="Enter City"
                                          onChange={(e) => this.setState({
                                              address: {
                                                  ...address,
                                                  city: e.target.value
                                              }
                                          })}
                                          isInvalid={!!message && !!message.address && !!message.address.city}
                                          value={city}/>
                            <Form.Control.Feedback type="invalid">
                                {!!message && !!message.address && message.address.city}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} controlId="formState">
                            <Form.Label>State</Form.Label>
                            <Form.Control type="text" placeholder="Enter State"
                                          onChange={(e) => this.setState({
                                              address: {
                                                  ...address,
                                                  state: e.target.value
                                              }
                                          })}
                                          isInvalid={!!message && !!message.address && !!message.address.state}
                                          value={state}/>
                            <Form.Control.Feedback type="invalid">
                                {!!message && !!message.address && message.address.state}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="formZipcode">
                            <Form.Label>Zipcode</Form.Label>
                            <Form.Control type="number" placeholder="Enter Zipcode"
                                          onChange={(e) => this.setState({
                                              address: {
                                                  ...address,
                                                  zipcode: e.target.value
                                              }
                                          })}
                                          isInvalid={!!message && !!message.address && !!message.address.zipcode}
                                          value={zipcode}/>
                            <Form.Control.Feedback type="invalid">
                                {!!message && !!message.address && message.address.zipcode}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} controlId="formZipcode">
                            <Form.Label>Phone</Form.Label>
                            <Form.Control type="number" placeholder="Enter Phone"
                                          onChange={(e) => this.setState({
                                              address: {
                                                  ...address,
                                                  phone: getPhoneNumber(e.target.value)
                                              }
                                          })}
                                          isInvalid={!!message && !!message.address && !!message.address.phone}
                                          value={getPhoneNumberWithoutCountryCode(phone)}/>
                            <Form.Control.Feedback type="invalid">
                                {!!message && !!message.address && message.address.phone}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success"
                            onClick={() => handleSubmit({address, is_cod, order_cid, channel})}
                            disabled={!enableCreation}>
                        Create Invoice
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => ({
    invoices: state.pharmacy.invoices,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(InvoiceItemsDetailModal);
