import {call, put} from 'redux-saga/effects';
import {
    FETCH_PHARMACY_ITEMS,
    FETCH_PHARMACY_ITEMS_RESULT,
    FETCH_PHARMACY_ITEMS_ERROR,
    FETCH_PHARMACY_ITEMS_RETRY,
} from '../../actions/pharmacy/items';
import api from "../api";
import {toast} from "react-toastify";


export function* fetchItems(action) {
    try {
        const response = yield call(api.pharmacy.items.list, action.data);
        const result = yield response.json();
        console.warn('SAGA:fetchItems', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_PHARMACY_ITEMS_RESULT, data: result});
        }
        else {
            yield put({type: FETCH_PHARMACY_ITEMS_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_PHARMACY_ITEMS_RETRY, retry: true, message: e.message});
    }
}