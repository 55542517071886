import {
    FETCH_PHARMACY_PROFILE,
    FETCH_PHARMACY_PROFILE_RESULT,
    FETCH_PHARMACY_PROFILE_ERROR,
    FETCH_PHARMACY_PROFILE_RETRY,
} from '../../actions/pharmacy/pharmacy';


const initialState = {
    detail: {},
    isFetching: false,
    isUpdating: false,
    error: false,
    message: null,
    retry: false,
};


const reducer = (state=initialState, action) => {
    switch (action.type) {

        case FETCH_PHARMACY_PROFILE:
            return {...state, isFetching: true, error: false, message: null, retry: false};
        case FETCH_PHARMACY_PROFILE_RESULT:
            return {...state, isFetching: false, detail: {...action.data}};
        case FETCH_PHARMACY_PROFILE_ERROR:
            return {...state, isFetching: false, error: true, message: action.message};
        case FETCH_PHARMACY_PROFILE_RETRY:
            return {...state, isFetching: false, retry: true, message: action.message};
        default:
            return {...state};
    }

};

export default reducer;
