export const FETCH_CREDIT_NOTES = 'FETCH_CREDIT_NOTES';
export const FETCH_CREDIT_NOTES_RESULT = 'FETCH_CREDIT_NOTES_RESULT';
export const FETCH_CREDIT_NOTES_ERROR = 'FETCH_CREDIT_NOTES_ERROR';
export const FETCH_CREDIT_NOTES_RETRY = 'FETCH_CREDIT_NOTES_RETRY';

export const CREATE_CREDIT_NOTE = 'CREATE_CREDIT_NOTE';
export const CREATE_CREDIT_NOTE_RESULT = 'CREATE_CREDIT_NOTE_RESULT';
export const CREATE_CREDIT_NOTE_ERROR = 'CREATE_CREDIT_NOTE_ERROR';
export const CREATE_CREDIT_NOTE_RETRY = 'CREATE_CREDIT_NOTE_RETRY';

export const UPDATE_CREDIT_NOTE = 'UPDATE_CREDIT_NOTE';
export const UPDATE_CREDIT_NOTE_RESULT = 'UPDATE_CREDIT_NOTE_RESULT';
export const UPDATE_CREDIT_NOTE_ERROR = 'UPDATE_CREDIT_NOTE_ERROR';
export const UPDATE_CREDIT_NOTE_RETRY = 'UPDATE_CREDIT_NOTE_RETRY';


export const fetchCreditNotes = (data) => ({
    type: FETCH_CREDIT_NOTES,
    data,
});

export const createCreditNote = (data) => ({
    type: CREATE_CREDIT_NOTE,
    data,
});

export const updateCreditNote = (data) => ({
    type: UPDATE_CREDIT_NOTE,
    data,
});