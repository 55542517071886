import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import SidebarItem from './SidebarItem';
import SidebarHeader from './SidebarHeader';
import './styles.scss';
import {withRouter} from "react-router";


class MainSidebar extends PureComponent {

    static propTypes = {
        history: PropTypes.object,
    };

    static defaultProps = {
        active: false,
    };

    handleLogoutClick = () => {
        this.props.history.push('/logout');
    };

    render() {
        const path = this.props.history.location.pathname;
        // let user = localStorage.getItem('user');
        // try {user = JSON.parse(user);}
        // catch (e) {user = {};}
        // console.warn(user);

        return (
            <div className={'sidebar ' + (this.props.active ? 'active' : '')}>
                <i onClick={this.props.toggleSidebar}
                   className={'material-icons sidebar-toggle ' + (this.props.active ? 'active' : '')}>keyboard_arrow_right</i>

                <ul className='sidebar-items'>
                    <SidebarHeader type={'Misters'}/>
                    {/*<SidebarItem icon='home'*/}
                    {/*             type='Home'*/}
                    {/*             active={path === '/'}*/}
                    {/*             onClick={() => this.props.history.push('/')} />*/}
                    <SidebarItem icon='list_alt'
                                 type='Orders'
                                 active={path === '/'}
                                 onClick={() => this.props.history.push('/')}/>
                    <SidebarItem icon='list_alt'
                                 type='Packed orders'
                                 active={path === '/packed'}
                                 onClick={() => this.props.history.push('/packed')}/>
                    <SidebarItem icon='list_alt'
                                 type='Shipped orders'
                                 active={path === '/shipped'}
                                 onClick={() => this.props.history.push('/shipped')}/>
                    <SidebarItem icon='list_alt'
                                 type='Batches'
                                 active={path === '/batches'}
                                 onClick={() => this.props.history.push('/batches')}/>
                    <SidebarItem icon='list_alt'
                                 type='Report'
                                 active={path === '/report'}
                                 onClick={() => this.props.history.push('/report')}/>
                    <SidebarItem icon='list_alt'
                                 type='Credit Notes'
                                 active={path === '/credit-notes'}
                                 onClick={() => this.props.history.push('/credit-notes')}/>
                    <SidebarItem icon='list_alt'
                                 type='Invoices'
                                 active={path === '/invoices'}
                                 onClick={() => this.props.history.push('/invoices')}/>
                    <SidebarItem icon='list_alt'
                                 type='Delivery Challan'
                                 active={path === '/delivery-challan'}
                                 onClick={() => this.props.history.push('/delivery-challan')}/>
                    <SidebarItem icon='account_balance_wallet'
                                 type='Account'
                                 active={path === '/account'}
                                 onClick={() => this.props.history.push('/account')}/>
                    <SidebarItem icon='lock'
                                 type='Logout'
                                 active={path === '/logout'}
                                 onClick={this.handleLogoutClick}/>
                </ul>
            </div>
        )
    }
}

export default withRouter(MainSidebar);
