import {
    FETCH_PHARMACY_ITEMS,
    FETCH_PHARMACY_ITEMS_RESULT,
    FETCH_PHARMACY_ITEMS_ERROR,
    FETCH_PHARMACY_ITEMS_RETRY,
} from '../../actions/pharmacy/items';


const initialState = {
    list: [],
    detail: {},
    isFetching: false,
    error: false,
    message: null,
    retry: false,
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PHARMACY_ITEMS:
            return {...state, isFetching: true, error: false, message: null, retry: false};
        case FETCH_PHARMACY_ITEMS_RESULT:
            return {...state, isFetching: false, list: action.data};
        case FETCH_PHARMACY_ITEMS_ERROR:
            return {...state, isFetching: false, error: true, message: action.message};
        case FETCH_PHARMACY_ITEMS_RETRY:
            return {...state, isFetching: false, retry: true, message: action.message};

        default:
            return state;
    }
};

export default reducer;
