export const FETCH_INVOICES = 'FETCH_INVOICES';
export const FETCH_INVOICES_RESULT = 'FETCH_INVOICES_RESULT';
export const FETCH_INVOICES_ERROR = 'FETCH_INVOICES_ERROR';
export const FETCH_INVOICES_RETRY = 'FETCH_INVOICES_RETRY';

export const CREATE_INVOICE = 'CREATE_INVOICE';
export const CREATE_INVOICE_RESULT = 'CREATE_INVOICE_RESULT';
export const CREATE_INVOICE_ERROR = 'CREATE_INVOICE_ERROR';
export const CREATE_INVOICE_RETRY = 'CREATE_INVOICE_RETRY';

export const UPDATE_INVOICE = 'UPDATE_INVOICE';
export const UPDATE_INVOICE_RESULT = 'UPDATE_INVOICE_RESULT';
export const UPDATE_INVOICE_ERROR = 'UPDATE_INVOICE_ERROR';
export const UPDATE_INVOICE_RETRY = 'UPDATE_INVOICE_RETRY';


export const fetchInvoices = (data) => ({
    type: FETCH_INVOICES,
    data,
});

export const createInvoice = (data) => ({
    type: CREATE_INVOICE,
    data,
});

export const updateInvoice = (data) => ({
    type: UPDATE_INVOICE,
    data,
});