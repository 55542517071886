/* global process */
// External Imports
import {createStore, applyMiddleware} from 'redux';
import createSagaMiddleware from 'redux-saga';
import {routerMiddleware, } from 'connected-react-router';
import thunk from 'redux-thunk';    // allows us to dispatch asynchronous actions.
// import promise from 'redux-promise-middleware';
import {createBrowserHistory} from 'history';
import logger from 'redux-logger';
// My Imports
import allReducers from './reducers';
import rootSaga from './sagas';
import {DEBUG} from './constants'

export const history = createBrowserHistory();


const middleware_list = [];

if (DEBUG) {
    middleware_list.push(logger);
}

const sagaMiddleware = createSagaMiddleware();
const middleware = applyMiddleware(...middleware_list, thunk, sagaMiddleware, routerMiddleware(history));

// logger
const store = createStore(
    allReducers(history),
    middleware
);
sagaMiddleware.run(rootSaga);

export default store;
