import {call, put} from 'redux-saga/effects';
import api from "../api";
import {
    FETCH_PHARMACY_PROFILE_RESULT,
    FETCH_PHARMACY_PROFILE_ERROR,
    FETCH_PHARMACY_PROFILE_RETRY
} from "../../actions/pharmacy/pharmacy";

export function* fetchPharmacyProfile(action) {
    try {
        const response = yield call(api.pharmacy.get, action.data);
        const result = yield response.json();
        console.warn('SAGA:fetchPharmacyProfile', action, result);

        if (response.status === 200) {
            yield localStorage.setItem('user', JSON.stringify(result));
            yield put({type: FETCH_PHARMACY_PROFILE_RESULT, data: result});
        } else {
            yield put({type: FETCH_PHARMACY_PROFILE_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_PHARMACY_PROFILE_RETRY, retry: true, message: e.message});
    }
}