export const FETCH_PHARMACY_PROFILE = 'FETCH_PHARMACY_PROFILE';
export const FETCH_PHARMACY_PROFILE_RESULT = 'FETCH_PHARMACY_PROFILE_RESULT';
export const FETCH_PHARMACY_PROFILE_ERROR = 'FETCH_PHARMACY_PROFILE_ERROR';
export const FETCH_PHARMACY_PROFILE_RETRY = 'FETCH_PHARMACY_PROFILE_RETRY';


export const fetchPharmacyProfile = (data) => ({
    type: FETCH_PHARMACY_PROFILE,
    data
});
