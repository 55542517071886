import {takeEvery} from 'redux-saga/effects';

import {
    fetchOrders,
    confirmOrder,
    cancelOrder,
    createOrderImage,
    fetchOrderImage,
    revertOrder,
    updateOrderCid,
    updateOrderStatus
} from './orders';
import {
    FETCH_ORDERS,
    CONFIRM_ORDER,
    CANCEL_ORDER,
    REVERT_ORDER,
    UPDATE_ORDER_CID,
    CREATE_ORDER_IMAGE,
    FETCH_ORDER_IMAGE,
    UPDATE_ORDER_STATUS
} from "../../actions/ecommerce/orders";
import {createOrderInvoice, fetchPackedOrders} from "./packed";
import {CREATE_ORDER_INVOICE, FETCH_PACKED_ORDERS} from "../../actions/ecommerce/packed";

import {fetchShippedOrders} from "./shipped";
import {FETCH_SHIPPED_ORDERS} from "../../actions/ecommerce/shipped";


export default [
    takeEvery(FETCH_PACKED_ORDERS, fetchPackedOrders),
    takeEvery(FETCH_ORDERS, fetchOrders),
    takeEvery(CONFIRM_ORDER, confirmOrder),
    takeEvery(CANCEL_ORDER, cancelOrder),
    takeEvery(REVERT_ORDER, revertOrder),
    takeEvery(UPDATE_ORDER_CID, updateOrderCid),
    takeEvery(CREATE_ORDER_IMAGE, createOrderImage),
    takeEvery(FETCH_ORDER_IMAGE, fetchOrderImage),
    takeEvery(UPDATE_ORDER_STATUS, updateOrderStatus),
    takeEvery(CREATE_ORDER_INVOICE, createOrderInvoice),

    takeEvery(FETCH_SHIPPED_ORDERS, fetchShippedOrders),
];
