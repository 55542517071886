import {getQueryString, HOST} from '../constants'
import makeRequest from "./makeRequest";

export {HOST};


export const jsonToForm = (data) => {
    const form = new FormData();
    const keys = Object.keys(data);
    const values = Object.values(data);

    keys.map((key, i) => {
        form.append(key, values[i])
    });
    return form;
};


export const addFileToForm = ({form, key, file}) => {
    // form.append(key, {
    //     uri: file.uri,
    //     name: file.name,
    //     type: file.type ? file.type: 'image/jpg',
    // });
    form.append(key, file, file.name);
    return form;
};


export default {
    accounts: {
        postUserLogin: data => (makeRequest(
            `${HOST}/api/v1/accounts/login/`,
            {method: 'post', body: jsonToForm(data)}
        )),
        getUser: data => (makeRequest(
            `${HOST}/api/v1/accounts/profile/`,
            {method: 'get'}
        )),
        patchUser: data => (makeRequest(
            `${HOST}/api/v1/accounts/profile/`,
            {method: 'PATCH', body: jsonToForm(data)}
        )),
        requestOtp: data => (makeRequest(
            `${HOST}/api/v1/accounts/otp/`,
            {method: 'post', body: jsonToForm(data)}
        )),
        tokenVerify: data => (makeRequest(
            `${HOST}/api/v1/accounts/token/verify/`,
            {method: 'post', body: jsonToForm(data)}
        )),
        tokenRefresh: data => (fetch(
            `${HOST}/api/v1/accounts/token/refresh/`,
            {method: 'post', body: jsonToForm(data)}
        )),
    },
    pharmacy: {
        get: () => makeRequest(`${HOST}/api/v1/pharmacy/`),

        batches: {
            list: ({page = 1, search}) => makeRequest(`${HOST}/api/v1/pharmacy/batches/?page=${page}&q=${search}`,),
            create: (data) => makeRequest(
                `${HOST}/api/v1/pharmacy/batches/`,
                {method: 'POST', body: JSON.stringify(data), bodyType: 'json',},
            ),
            update: (data) => makeRequest(
                `${HOST}/api/v1/pharmacy/batches/${data.id}/`,
                {method: 'PATCH', body: JSON.stringify(data), bodyType: 'json',},
            ),
        },
        items: {
            list: () => makeRequest(`${HOST}/api/v1/pharmacy/items/`,),
        },
        report: {
            get: () => makeRequest(`${HOST}/api/v1/pharmacy/report/`),
        },
        creditnotes: {
            list: ({page = 1, search}) => makeRequest(`${HOST}/api/v1/pharmacy/creditnotes/?page=${page}&q=${search}`,),
        },

        returned: {
            list: (data) => makeRequest(`${HOST}/api/v1/pharmacy/orders/returned/?${getQueryString(data)}`,),
            get: ({id}) => makeRequest(`${HOST}/api/v1/pharmacy/invoices/${id}/returnacknowledgement/`),
            create: (data) => makeRequest(
                `${HOST}/api/v1/pharmacy/invoices/${data.id}/returnacknowledgement/`,
                {method: 'POST', body: JSON.stringify(data), bodyType: 'json',},
            ),
            update: (data) => makeRequest(
                `${HOST}/api/v1/pharmacy/invoices/${data.id}/returnacknowledgement/`,
                {method: 'POST', body: JSON.stringify(data), bodyType: 'json',},
            ),
        },
        invoiceitems: {
            list: () => makeRequest(`${HOST}/api/v1/pharmacy/invoiceitems/`,),
            batch: () => makeRequest(`${HOST}/api/v1/pharmacy/invoiceitems/batch/`,),
            create: (data) => makeRequest(
                `${HOST}/api/v1/pharmacy/invoiceitems/`,
                {method: 'POST', body: JSON.stringify(data), bodyType: 'json',},
            ),
            update: (data) => makeRequest(
                `${HOST}/api/v1/pharmacy/invoiceitems/${data.id}/`,
                {method: 'PATCH', body: JSON.stringify(data), bodyType: 'json',},
            ),
            remove: (data) => makeRequest(
                `${HOST}/api/v1/pharmacy/invoiceitems/${data.id}/`, {method: 'DELETE'},
            ),
        },
        invoices: {
            list: ({page = 1, search}) => makeRequest(`${HOST}/api/v1/pharmacy/invoices/?page=${page}&q=${search}`,),
            create: (data) => makeRequest(
                `${HOST}/api/v1/pharmacy/invoices/`,
                {method: 'POST', body: JSON.stringify(data), bodyType: 'json',},
            ),
            update: (data) => makeRequest(
                `${HOST}/api/v1/pharmacy/invoices/${data.id}/`,
                {method: 'PATCH', body: JSON.stringify(data), bodyType: 'json'}
            ),
        },
        deliverychallans: {
            list: ({page = 1, search}) => makeRequest(`${HOST}/api/v1/pharmacy/deliverychallans/?page=${page}&q=${search}`,),
            create: (data) => makeRequest(
                `${HOST}/api/v1/pharmacy/deliverychallans/`,
                {method: 'POST', body: JSON.stringify(data), bodyType: 'json',},
            ),
            update: (data) => makeRequest(
                `${HOST}/api/v1/pharmacy/deliverychallans/${data.id}/`,
                {method: 'PATCH', body: JSON.stringify(data), bodyType: 'json'}
            ),
            remove: (data) => makeRequest(
                `${HOST}/api/v1/pharmacy/deliverychallans/${data.id}/`, {method: 'DELETE'},
            ),
        },
        deliverychallanitems: {
            list: () => makeRequest(`${HOST}/api/v1/pharmacy/deliverychallanitems/`,),
            create: (data) => makeRequest(
                `${HOST}/api/v1/pharmacy/deliverychallanitems/`,
                {method: 'POST', body: JSON.stringify(data), bodyType: 'json',},
            ),
            update: (data) => makeRequest(
                `${HOST}/api/v1/pharmacy/deliverychallanitems/${data.id}/`,
                {method: 'PATCH', body: JSON.stringify(data), bodyType: 'json',},
            ),
            remove: (data) => makeRequest(
                `${HOST}/api/v1/pharmacy/deliverychallanitems/${data.id}/`, {method: 'DELETE'},
            ),
        },
    },
    ecommerce: {
        address: {
            pincode: (data) => makeRequest(
                `${HOST}/api/v1/ecommerce/address/pincode/`,
                {method: 'POST', bodyType: 'json', body: JSON.stringify(data)}
            ),
        },
        orders: {
            list: ({page = 1, search}) => makeRequest(`${HOST}/api/v1/pharmacy/orders/?page=${page}&q=${search}`,),
            packed: ({page = 1, search}) => makeRequest(`${HOST}/api/v1/pharmacy/orders/packed/?page=${page}&q=${search}`,),
            shipped: ({page = 1, search}) => makeRequest(`${HOST}/api/v1/pharmacy/orders/shipped/?page=${page}&q=${search}`,),
            invoice: ({id}) => makeRequest(`${HOST}/api/v1/pharmacy/orders/${id}/invoice/`, {method: 'POST'}),
            confirm: ({id, ...data}) => makeRequest(
                `${HOST}/api/v1/pharmacy/orders/${id}/confirm/`,
                {method: 'POST', body: JSON.stringify(data), bodyType: 'json'}
            ),
            cancel: ({id, ...data}) => makeRequest(
                `${HOST}/api/v1/pharmacy/orders/${id}/cancel/`,
                {method: 'POST', body: JSON.stringify(data), bodyType: 'json'}
            ),
            revert: ({id, ...data}) => makeRequest(
                `${HOST}/api/v1/pharmacy/orders/${id}/revert/`,
                {method: 'POST', body: JSON.stringify(data), bodyType: 'json'}
            ),
            cid: ({id, ...data}) => makeRequest(`${HOST}/api/v1/pharmacy/orders/${id}/cid/`,
                {method: 'POST', body: JSON.stringify(data), bodyType: 'json'}),
            updateStatus: ({id, ...data}) => makeRequest(`${HOST}/api/v1/pharmacy/orders/${id}/status-update/`,
                {method: 'POST', body: JSON.stringify(data), bodyType: 'json'}),
            images: {
                create: (data) => makeRequest(
                    `${HOST}/api/v1/pharmacy/orders/images/`,
                    {
                        method: 'POST',
                        body: addFileToForm({form: new FormData(), key: 'image', file: data.image}),
                        // bodyType: 'file'
                    }
                ),
                get: ({id}) => makeRequest(`${HOST}/api/v1/pharmacy/orders/images/${id}/`),
            },
            slip: ({id}) => makeRequest(`${HOST}/api/v1/pharmacy/orders/${id}/slip/`),
        }
    },
    consultations: {
        consultations: {
            create: data => makeRequest(
                `${HOST}/api/v1/consultation/create/`,
                {method: 'POST', body: jsonToForm(data)}
            ),
            list: data => makeRequest(
                `${HOST}/api/v1/consultation/${data.patient_id}/`,
            ),
            templates: () => makeRequest(
                `${HOST}/api/v1/consultation/templates/`,
            ),
        },
    },
}
