import React from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import * as PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Select from "react-select";
import {confirmOrder} from "../../../actions/ecommerce/orders";


class OrderConfirm extends React.Component {

    static propTypes = {
        order: PropTypes.object,
        show: PropTypes.bool,
        handleClose: PropTypes.func,
        handleConfirm: PropTypes.func,
        confirmOrder: PropTypes.func,
    };

    state = {
        message: undefined,
        shipping_aggregator: 'manual_shipment',
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {detail, message, isConfirming, error, retry} = this.props.orders;
        if (!this.props.show && prevProps.show) {
            this.setState({
                message: undefined,
                shipping_aggregator: 'manual_shipment',
            });
        }

        if (!isConfirming && prevProps.orders.isConfirming && (error || retry)) {
            this.setState({message});
        }
    }

    handleConfirmOrder = () => {
        let {order: {id}} = this.props;
        let {shipping_aggregator} = this.state;
        this.props.confirmOrder({id, shipping_aggregator});
    };

    render() {
        let {order, show, handleClose, orders: {isConfirming}} = this.props;
        let {message, shipping_aggregator} = this.state;

        let confirmList = [
            {label: 'Manual Shipment', value: 'manual_shipment'},
            // {label: 'Pickrr', value: 'pickrr'},
            {label: 'Shiprocket', value: 'shiprocket'},
        ];

        return (
            <Modal show={show} onHide={handleClose} size='lg' centered>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm This order</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-sm-8 offset-sm-2">
                            <Form>
                                <Form.Group controlId="shipping_aggregator">
                                    <Form.Label>Shipping Aggregator</Form.Label>
                                    <Select options={confirmList.map(i => ({label: i.label, value: i.value}))}
                                            value={confirmList.filter(i => i.value === shipping_aggregator).map(i => ({
                                                label: i.label,
                                                value: i.value
                                            }))}
                                            onChange={(i) => this.setState({
                                                shipping_aggregator: i.value,
                                            })}/>
                                    <Form.Control.Feedback type="invalid">
                                        {!!message && message.shipping_aggregator}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Form>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={this.handleConfirmOrder}
                            disabled={!this.state.shipping_aggregator}>
                        {isConfirming ? 'Confirming...' : 'Confirm This Order'}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => ({
    orders: state.ecommerce.orders,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    confirmOrder,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(OrderConfirm);
