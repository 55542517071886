//External Imports
import {combineReducers} from 'redux';
//My Imports
import pharmacy from './pharmacy';
import batches from './batches';
import items from './items';
import report from './report';
import creditnotes from './creditnotes';
import returned from './returned';
import invoiceitems from './invoiceitems';
import invoices from './invoices';
import deliverychallans from './deliverychallans';
import deliverychallanitems from './deliverychallanitems';


export default combineReducers({
    pharmacy,
    batches,
    items,
    report,
    creditnotes,
    returned,
    invoiceitems,
    invoices,
    deliverychallans,
    deliverychallanitems,
})
