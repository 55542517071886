import {
    FETCH_CREDIT_NOTES,
    FETCH_CREDIT_NOTES_RESULT,
    FETCH_CREDIT_NOTES_ERROR,
    FETCH_CREDIT_NOTES_RETRY,

    CREATE_CREDIT_NOTE,
    CREATE_CREDIT_NOTE_RESULT,
    CREATE_CREDIT_NOTE_ERROR,
    CREATE_CREDIT_NOTE_RETRY,

    UPDATE_CREDIT_NOTE,
    UPDATE_CREDIT_NOTE_RESULT,
    UPDATE_CREDIT_NOTE_ERROR,
    UPDATE_CREDIT_NOTE_RETRY,
} from '../../actions/pharmacy/creditnotes';


const initialState = {
    list: {},
    detail: {},
    isFetching: false,
    isUpdating: false,
    isCreating: false,
    error: false,
    message: null,
    retry: false,
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CREDIT_NOTES:
            return {...state, isFetching: true, error: false, message: null, retry: false};
        case FETCH_CREDIT_NOTES_RESULT:
            return {...state, isFetching: false, list: action.data};
        case FETCH_CREDIT_NOTES_ERROR:
            return {...state, isFetching: false, error: true, message: action.message};
        case FETCH_CREDIT_NOTES_RETRY:
            return {...state, isFetching: false, retry: true, message: action.message};

        case CREATE_CREDIT_NOTE:
            return {...state, isCreating: true, error: false, message: null, retry: false};
        case CREATE_CREDIT_NOTE_RESULT:
            return {...state, isCreating: false, detail: action.data};
        case CREATE_CREDIT_NOTE_ERROR:
            return {...state, isCreating: false, error: true, message: action.message};
        case CREATE_CREDIT_NOTE_RETRY:
            return {...state, isCreating: false, retry: true, message: action.message};

        case UPDATE_CREDIT_NOTE:
            return {...state, isUpdating: true, error: false, message: null, retry: false};
        case UPDATE_CREDIT_NOTE_RESULT:
            return {...state, isUpdating: false, detail: action.data};
        case UPDATE_CREDIT_NOTE_ERROR:
            return {...state, isUpdating: false, error: true, message: action.message};
        case UPDATE_CREDIT_NOTE_RETRY:
            return {...state, isUpdating: false, retry: true, message: action.message};

        default:
            return state;
    }
};

export default reducer;
