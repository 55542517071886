import React from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import * as PropTypes from "prop-types";
import Select from "react-select";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Table from "react-bootstrap/Table";
import ButtonGroup from "react-bootstrap/ButtonGroup";

import {getPrice, getPriceInPaisa} from "../../../constants";
import {fetchItems} from "../../../actions/pharmacy/items";
import {createDeliveryChallan} from "../../../actions/pharmacy/deliverychallans";
import {
    createDeliveryChallanItem,
    deleteDeliveryChallanItem,
    fetchDeliveryChallanItems,
    updateDeliveryChallanItem
} from "../../../actions/pharmacy/deliverychallanitems";

import {fetchInvoiceItemsBatch} from "../../../actions/pharmacy/invoiceitems";

import "./styles.scss";
import {Col} from "react-bootstrap";


class DeliveryChallanCreateModal extends React.Component {

    static propTypes = {
        show: PropTypes.bool,
        fetchItems: PropTypes.func,
        fetchDeliveryChallanItems: PropTypes.func,
        fetchInvoiceItemsBatch: PropTypes.func,
        createDeliveryChallanItem: PropTypes.func,
        deleteDeliveryChallanItem: PropTypes.func,
        updateDeliveryChallanItem: PropTypes.func,
        createDeliveryChallan: PropTypes.func,
        handleClose: PropTypes.func,
    };

    static defaultProps = {};

    constructor(props) {
        super(props);

        this.state = {
            delivery_challan_number: '',
            status: '',
            deliveryChallanItems: [{}],
            message: undefined,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {isFetching, isCreating, isUpdating, isDeleting, detail, list, message, error, retry} = this.props.deliverychallanitems;
        let {deliverychallans} = this.props;
        let {deliveryChallanItems, itemIndex} = this.state;

        if (this.props.show && !prevProps.show) {
            this.props.fetchItems();
            this.props.fetchDeliveryChallanItems();
            this.props.fetchInvoiceItemsBatch();

            this.setState({
                delivery_challan_number: '',
                status: '',
                is_reverse: false,
                deliveryChallanItems: [{}],
                message: undefined,
            });
        }

        if (!isFetching && prevProps.deliverychallanitems.isFetching && !error && !retry) {
            if (!!list.length) {
                this.setState({
                    deliveryChallanItems: list,
                });
            }
        }

        if (!deliverychallans.isCreating && prevProps.deliverychallans.isCreating && (deliverychallans.error || deliverychallans.retry)) {
            this.setState({message: deliverychallans.message});
        }

        if (!isCreating && prevProps.deliverychallanitems.isCreating && !error && !retry) {
            deliveryChallanItems[deliveryChallanItems.length - 1] = detail
            this.setState({deliveryChallanItems})
        }

        if (!isUpdating && prevProps.deliverychallanitems.isUpdating && !error && !retry) {
            deliveryChallanItems[itemIndex] = detail;
            this.setState({deliveryChallanItems})
        }

        if (!isDeleting && prevProps.deliverychallanitems.isDeleting && !error && !retry) {
            deliveryChallanItems.splice(itemIndex, 1);
            this.setState({deliveryChallanItems})
        }
    }

    handleChange = (item, index) => {
        let {deliveryChallanItems} = this.state;
        deliveryChallanItems[index] = item;

        this.setState({deliveryChallanItems})
    }

    handleAddItem = () => {
        let {deliveryChallanItems} = this.state;

        this.setState({
            deliveryChallanItems: [
                ...deliveryChallanItems,
                {}
            ]
        })
    }

    handleCreateItem = (item) => {
        this.props.createDeliveryChallanItem({
            ...item,
            price: item.price,
        });
    }

    handleUpdateItem = (item, index) => {
        this.setState({itemIndex: index});

        this.props.updateDeliveryChallanItem({
            id: item.id,
            item: item.item,
            quantity: item.quantity,
            price: item.price,
            batch: item.batch,
        });
    }

    handleDeleteItem = (item, index) => {
        this.setState({itemIndex: index});
        this.props.deleteDeliveryChallanItem({id: item.id});
    }

    handleSubmit = () => {
        let {deliveryChallanItems, status, is_reverse, delivery_challan_number} = this.state;

        let delivery_challan_items = [];
        deliveryChallanItems.forEach((item) => {
            if (!!item.id)
                delivery_challan_items.push(item.id);
        });
        let data = {status, delivery_challan_number, is_reverse, delivery_challan_items};

        this.props.createDeliveryChallan(data);
    }

    statusList = [
        {label: 'OPEN', value: 'open'},
        {label: 'DRAFT', value: 'draft'},
        {label: 'DELIVERED', value: 'delivered'},
    ]

    render() {
        let {show, handleClose, items, deliverychallanitems, invoiceitems} = this.props;
        let {deliveryChallanItems, status, delivery_challan_number, is_reverse, message} = this.state;

        return (
            <Modal size="xl" show={show} onHide={handleClose} centered>

                <Modal.Header closeButton>
                    <Modal.Title>Delivery Challan</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="row mb-2">
                        <div className="col-12">
                            <Form.Row>
                                <Form.Group as={Col} controlId="delivery_challan_number">
                                    <Form.Control type="text" placeholder="Delivery Challan Number"
                                                  onChange={(e) => this.setState({
                                                      delivery_challan_number: e.target.value
                                                  })}
                                                  isInvalid={!!message && !!message.delivery_challan_number}
                                                  value={delivery_challan_number}/>
                                    <Form.Control.Feedback type="invalid">
                                        {!!message && message.delivery_challan_number}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} controlId="status">
                                    <Select placeholder="Select Status"
                                            options={this.statusList}
                                            value={this.statusList.filter(c => c.value === status)}
                                            onChange={(value) => {
                                                this.setState({
                                                    status: value.value
                                                })
                                            }}/>
                                    <Form.Control type="hidden" isInvalid={!!message && !!message.status}/>
                                    <Form.Control.Feedback type="invalid">
                                        {!!message && message.status}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} controlId="is_reverse">
                                    <Form.Check type="checkbox" label="Is Reverse Challan"
                                                checked={is_reverse}
                                                isInvalid={!!message && !!message.is_cod}
                                                onChange={(e) => this.setState({
                                                    is_reverse: !is_reverse
                                                })}/>
                                    <Form.Control.Feedback type="invalid">
                                        {!!message && message.is_reverse}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                        </div>
                    </div>
                    <Table className="delivery-challans-modal-table" striped bordered responsive>
                        <thead>
                        <tr>
                            <th>Id</th>
                            <th>Item</th>
                            <th>Qty</th>
                            <th>Price <small>(in Paisa)</small></th>
                            <th>Batch</th>
                            <th>Discount</th>
                            <th>Tax</th>
                            <th>Rate</th>
                            <th>Updated at</th>
                            <th>Created at</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {deliveryChallanItems.map((item, index) => (
                            <tr key={index + 1}>
                                <th>#{item.id}</th>
                                <td style={{minWidth: '180px'}}>
                                    <Form.Group className="mb-0" controlId="items_select">
                                        <Select options={items.list.map(i => ({
                                            label: `${i.product_name}-${i.product_id}`,
                                            value: i.id
                                        }))}
                                                value={items.list.filter(i => i.id === item.item).map(i => ({
                                                    label: `${i.product_name}-${i.product_id}`,
                                                    value: i.value
                                                }))}
                                                onChange={(i) => this.handleChange({
                                                    ...item,
                                                    item: i.value,
                                                }, index)}/>
                                    </Form.Group>
                                </td>
                                <td style={{minWidth: '100px'}}>
                                    <Form.Group className="mb-0" controlId="qty">
                                        <Form.Control type="number" placeholder="Quantity"
                                                      value={item.quantity || ''}
                                                      onChange={(e) => this.handleChange({
                                                          ...item,
                                                          quantity: parseInt(e.target.value),
                                                      }, index)}/>
                                    </Form.Group>
                                </td>
                                <td style={{minWidth: '100px'}}>
                                    <Form.Group className="mb-0" controlId="price">
                                        <Form.Control type="number" placeholder="Price"
                                                      value={isNaN(item.price) ? '': item.price}
                                                      onChange={(e) => this.handleChange({
                                                          ...item,
                                                          price: parseInt(e.target.value),
                                                      }, index)}/>
                                        {!!item.price &&
                                        <b className="d-block text-center mt-2">{`₹${getPrice(item.price)}`}</b>}
                                    </Form.Group>
                                </td>
                                <td style={{minWidth: '150px'}}>
                                    <Form.Group className="mb-0" controlId="batch_select">
                                        <Select options={invoiceitems.batchList.map(i => ({
                                            label: `${i.name}-${i.batch_number}`,
                                            value: i.id
                                        }))}
                                                value={invoiceitems.batchList.filter(i => i.id === item.batch).map(i => ({
                                                    label: `${i.name}-${i.batch_number}`,
                                                    value: i.value
                                                }))}
                                                onChange={(i) => this.handleChange({
                                                    ...item,
                                                    batch: i.value,
                                                }, index)}/>
                                    </Form.Group>
                                </td>
                                <th>{!!item.discount_percentage && `${item.discount_percentage}%`}</th>
                                <th>{!!item.tax_percentage && `${item.tax_percentage}%`}</th>
                                <th>​{!!item.rate && `₹${getPrice(item.rate)}`}</th>
                                <td>{!!item.created_at && moment(item.updated_at).format('DD MMMM YYYY')}</td>
                                <td>{!!item.created_at && moment(item.created_at).format('DD MMMM YYYY')}</td>
                                <td>
                                    <ButtonGroup vertical>
                                        {!item.id &&
                                        <Button variant="success" size="sm"
                                                onClick={() => this.handleCreateItem(item)}>
                                            Create
                                        </Button>}
                                        {item.id &&
                                        <Button variant="primary" size="sm"
                                                onClick={() => this.handleUpdateItem(item, index)}>
                                            Update
                                        </Button>}
                                        {item.id &&
                                        <Button variant="danger" size="sm"
                                                onClick={() => this.handleDeleteItem(item, index)}>
                                            Delete
                                        </Button>}
                                    </ButtonGroup>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <div className="flex-fill">
                        {(!deliveryChallanItems.length || !!deliveryChallanItems[deliveryChallanItems.length - 1].id) &&
                        <Button variant="primary" size="sm" onClick={this.handleAddItem}>Add More Item</Button>}
                    </div>

                    <Button variant="outline-danger" onClick={handleClose}>Close</Button>
                    <Button variant="success" onClick={this.handleSubmit}
                            disabled={!!deliveryChallanItems.length && !deliveryChallanItems[0].id}>
                        Create
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}


const mapStateToProps = (state) => ({
    items: state.pharmacy.items,
    deliverychallanitems: state.pharmacy.deliverychallanitems,
    deliverychallans: state.pharmacy.deliverychallans,
    invoiceitems: state.pharmacy.invoiceitems,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    fetchItems,
    createDeliveryChallanItem,
    updateDeliveryChallanItem,
    deleteDeliveryChallanItem,
    fetchInvoiceItemsBatch,
    fetchDeliveryChallanItems,
    createDeliveryChallan,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(DeliveryChallanCreateModal);
