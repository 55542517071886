import {
    FETCH_SHIPPED_ORDERS,
    FETCH_SHIPPED_ORDERS_RESULT,
    FETCH_SHIPPED_ORDERS_ERROR,
    FETCH_SHIPPED_ORDERS_RETRY,
} from '../../actions/ecommerce/shipped';


const initialState = {
    list: [],
    detail: {},
    isFetching: false,
    isUploading: false,
    isConfirming: false,
    isCanceling: false,
    error: false,
    message: null,
    retry: false,
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SHIPPED_ORDERS:
            return {...state, isFetching: true, error: false, message: null, retry: false};
        case FETCH_SHIPPED_ORDERS_RESULT:
            return {...state, isFetching: false, list: action.data};
        case FETCH_SHIPPED_ORDERS_ERROR:
            return {...state, isFetching: false, error: true, message: action.message};
        case FETCH_SHIPPED_ORDERS_RETRY:
            return {...state, isFetching: false, retry: true, message: action.message};

        default:
            return state;
    }
};

export default reducer;
