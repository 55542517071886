import {call, put} from 'redux-saga/effects';
import api from "../api";
import {
    FETCH_REPORT_RESULT,
    FETCH_REPORT_ERROR,
    FETCH_REPORT_RETRY
} from "../../actions/pharmacy/report";

export function* fetchReport(action) {
    try {
        const response = yield call(api.pharmacy.report.get, action.data);
        const result = yield response.json();
        console.warn('SAGA:fetchReport', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_REPORT_RESULT, data: result});
        } else {
            yield put({type: FETCH_REPORT_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_REPORT_RETRY, retry: true, message: e.message});
    }
}