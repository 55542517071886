import {call, put} from 'redux-saga/effects';
import {
    FETCH_SHIPPED_ORDERS,
    FETCH_SHIPPED_ORDERS_RESULT,
    FETCH_SHIPPED_ORDERS_ERROR,
    FETCH_SHIPPED_ORDERS_RETRY,
} from '../../actions/ecommerce/shipped';
import api from "../api";


export function* fetchShippedOrders(action) {
    try {
        const response = yield call(api.ecommerce.orders.shipped, action.data);
        const result = yield response.json();
        console.warn('SAGA:fetchShippedOrders', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_SHIPPED_ORDERS_RESULT, data: result});
        } else {
            yield put({type: FETCH_SHIPPED_ORDERS_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_SHIPPED_ORDERS_RETRY, retry: true, message: e.message});
    }
}


