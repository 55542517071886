//External Imports
import {combineReducers} from 'redux';
//My Imports
import orders from './orders';
import packed from './packed';
import shipped from './shipped';


export default combineReducers({
    orders,
    packed,
    shipped,
})
