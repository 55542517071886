import {
    FETCH_INVOICES,
    FETCH_INVOICES_RESULT,
    FETCH_INVOICES_ERROR,
    FETCH_INVOICES_RETRY,

    CREATE_INVOICE,
    CREATE_INVOICE_RESULT,
    CREATE_INVOICE_ERROR,
    CREATE_INVOICE_RETRY,

    UPDATE_INVOICE,
    UPDATE_INVOICE_RESULT,
    UPDATE_INVOICE_ERROR,
    UPDATE_INVOICE_RETRY,
} from '../../actions/pharmacy/invoices';


const initialState = {
    list: {},
    detail: {},
    isFetching: false,
    isUpdating: false,
    isCreating: false,
    error: false,
    message: null,
    retry: false,
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_INVOICES:
            return {...state, isFetching: true, error: false, message: null, retry: false};
        case FETCH_INVOICES_RESULT:
            return {...state, isFetching: false, list: action.data};
        case FETCH_INVOICES_ERROR:
            return {...state, isFetching: false, error: true, message: action.message};
        case FETCH_INVOICES_RETRY:
            return {...state, isFetching: false, retry: true, message: action.message};

        case CREATE_INVOICE:
            return {...state, isCreating: true, error: false, message: null, retry: false};
        case CREATE_INVOICE_RESULT:
            return {...state, isCreating: false, detail: action.data};
        case CREATE_INVOICE_ERROR:
            return {...state, isCreating: false, error: true, message: action.message};
        case CREATE_INVOICE_RETRY:
            return {...state, isCreating: false, retry: true, message: action.message};

        case UPDATE_INVOICE:
            return {...state, isUpdating: true, error: false, message: null, retry: false};
        case UPDATE_INVOICE_RESULT:
            return {...state, isUpdating: false, detail: action.data};
        case UPDATE_INVOICE_ERROR:
            return {...state, isUpdating: false, error: true, message: action.message};
        case UPDATE_INVOICE_RETRY:
            return {...state, isUpdating: false, retry: true, message: action.message};

        default:
            return state;
    }
};

export default reducer;
