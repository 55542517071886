import {
    FETCH_ORDERS,
    FETCH_ORDERS_RESULT,
    FETCH_ORDERS_ERROR,
    FETCH_ORDERS_RETRY,

    CONFIRM_ORDER,
    CONFIRM_ORDER_RESULT,
    CONFIRM_ORDER_ERROR,
    CONFIRM_ORDER_RETRY,

    CANCEL_ORDER,
    CANCEL_ORDER_RESULT,
    CANCEL_ORDER_ERROR,
    CANCEL_ORDER_RETRY,

    REVERT_ORDER,
    REVERT_ORDER_RESULT,
    REVERT_ORDER_ERROR,
    REVERT_ORDER_RETRY,

    UPDATE_ORDER_CID,
    UPDATE_ORDER_CID_RESULT,
    UPDATE_ORDER_CID_ERROR,
    UPDATE_ORDER_CID_RETRY,

    CREATE_ORDER_IMAGE,
    CREATE_ORDER_IMAGE_RESULT,
    CREATE_ORDER_IMAGE_ERROR,
    CREATE_ORDER_IMAGE_RETRY,

    UPDATE_ORDER_STATUS,
    UPDATE_ORDER_STATUS_RESULT,
    UPDATE_ORDER_STATUS_ERROR,
    UPDATE_ORDER_STATUS_RETRY,
} from '../../actions/ecommerce/orders';


const initialState = {
    list: [],
    detail: {},
    orderImage: {},
    isFetching: false,
    isUploading: false,
    isConfirming: false,
    isReverting: false,
    isUpdating: false,
    isUpdatingStatus: false,
    isCanceling: false,
    error: false,
    message: null,
    retry: false,
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ORDERS:
            return {...state, isFetching: true, error: false, message: null, retry: false};
        case FETCH_ORDERS_RESULT:
            return {...state, isFetching: false, list: action.data};
        case FETCH_ORDERS_ERROR:
            return {...state, isFetching: false, error: true, message: action.message};
        case FETCH_ORDERS_RETRY:
            return {...state, isFetching: false, retry: true, message: action.message};

        case CONFIRM_ORDER:
            return {...state, isConfirming: true, error: false, message: null, retry: false};
        case CONFIRM_ORDER_RESULT:
            return {...state, isConfirming: false, detail: action.data};
        case CONFIRM_ORDER_ERROR:
            return {...state, isConfirming: false, error: true, message: action.message};
        case CONFIRM_ORDER_RETRY:
            return {...state, isConfirming: false, retry: true, message: action.message};

        case CANCEL_ORDER:
            return {...state, isCanceling: true, error: false, message: null, retry: false};
        case CANCEL_ORDER_RESULT:
            return {...state, isCanceling: false};
        case CANCEL_ORDER_ERROR:
            return {...state, isCanceling: false, error: true, message: action.message};
        case CANCEL_ORDER_RETRY:
            return {...state, isCanceling: false, retry: true, message: action.message};

        case REVERT_ORDER:
            return {...state, isReverting: true, error: false, message: null, retry: false};
        case REVERT_ORDER_RESULT:
            return {...state, isReverting: false};
        case REVERT_ORDER_ERROR:
            return {...state, isReverting: false, error: true, message: action.message};
        case REVERT_ORDER_RETRY:
            return {...state, isReverting: false, retry: true, message: action.message};

        case UPDATE_ORDER_CID:
            return {...state, isUpdating: true, error: false, message: null, retry: false};
        case UPDATE_ORDER_CID_RESULT:
            return {...state, isUpdating: false};
        case UPDATE_ORDER_CID_ERROR:
            return {...state, isUpdating: false, error: true, message: action.message};
        case UPDATE_ORDER_CID_RETRY:
            return {...state, isUpdating: false, retry: true, message: action.message};

        case CREATE_ORDER_IMAGE:
            return {...state, isUploading: true, error: false, message: null, retry: false};
        case CREATE_ORDER_IMAGE_RESULT:
            return {...state, isUploading: false, orderImage: action.data};
        case CREATE_ORDER_IMAGE_ERROR:
            return {...state, isUploading: false, error: true, message: action.message};
        case CREATE_ORDER_IMAGE_RETRY:
            return {...state, isUploading: false, retry: true, message: action.message};


        case UPDATE_ORDER_STATUS:
            return {...state, isUpdatingStatus: true, error: false, message: null, retry: false};
        case UPDATE_ORDER_STATUS_RESULT:
            return {...state, isUpdatingStatus: false};
        case UPDATE_ORDER_STATUS_ERROR:
            return {...state, isUpdatingStatus: false, error: true, message: action.message};
        case UPDATE_ORDER_STATUS_RETRY:
            return {...state, isUpdatingStatus: false, retry: true, message: action.message};
        default:
            return state;
    }
};

export default reducer;
