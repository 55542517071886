import {
    FETCH_INVOICE_ITEMS,
    FETCH_INVOICE_ITEMS_RESULT,
    FETCH_INVOICE_ITEMS_ERROR,
    FETCH_INVOICE_ITEMS_RETRY,

    CREATE_INVOICE_ITEMS,
    CREATE_INVOICE_ITEMS_RESULT,
    CREATE_INVOICE_ITEMS_ERROR,
    CREATE_INVOICE_ITEMS_RETRY,

    UPDATE_INVOICE_ITEMS,
    UPDATE_INVOICE_ITEMS_RESULT,
    UPDATE_INVOICE_ITEMS_ERROR,
    UPDATE_INVOICE_ITEMS_RETRY,

    DELETE_INVOICE_ITEMS,
    DELETE_INVOICE_ITEMS_RESULT,
    DELETE_INVOICE_ITEMS_ERROR,
    DELETE_INVOICE_ITEMS_RETRY,

    FETCH_INVOICE_ITEMS_BATCH,
    FETCH_INVOICE_ITEMS_BATCH_RESULT,
    FETCH_INVOICE_ITEMS_BATCH_ERROR,
    FETCH_INVOICE_ITEMS_BATCH_RETRY,
} from '../../actions/pharmacy/invoiceitems';


const initialState = {
    list: [],
    batchList: [],
    detail: {},
    isFetching: false,
    isCreating: false,
    isUpdating: false,
    isDeleting: false,
    error: false,
    message: null,
    retry: false,
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_INVOICE_ITEMS:
            return {...state, isFetching: true, error: false, message: null, retry: false};
        case FETCH_INVOICE_ITEMS_RESULT:
            return {...state, isFetching: false, list: action.data};
        case FETCH_INVOICE_ITEMS_ERROR:
            return {...state, isFetching: false, error: true, message: action.message};
        case FETCH_INVOICE_ITEMS_RETRY:
            return {...state, isFetching: false, retry: true, message: action.message};

        case CREATE_INVOICE_ITEMS:
            return {...state, isCreating: true, error: false, message: null, retry: false};
        case CREATE_INVOICE_ITEMS_RESULT:
            return {...state, isCreating: false, detail: action.data};
        case CREATE_INVOICE_ITEMS_ERROR:
            return {...state, isCreating: false, error: true, message: action.message};
        case CREATE_INVOICE_ITEMS_RETRY:
            return {...state, isCreating: false, retry: true, message: action.message};

        case UPDATE_INVOICE_ITEMS:
            return {...state, isUpdating: true, error: false, message: null, retry: false};
        case UPDATE_INVOICE_ITEMS_RESULT:
            return {...state, isUpdating: false, detail: action.data};
        case UPDATE_INVOICE_ITEMS_ERROR:
            return {...state, isUpdating: false, error: true, message: action.message};
        case UPDATE_INVOICE_ITEMS_RETRY:
            return {...state, isUpdating: false, retry: true, message: action.message};

        case DELETE_INVOICE_ITEMS:
            return {...state, isDeleting: true, error: false, message: null, retry: false};
        case DELETE_INVOICE_ITEMS_RESULT:
            return {...state, isDeleting: false, detail: action.data};
        case DELETE_INVOICE_ITEMS_ERROR:
            return {...state, isDeleting: false, error: true, message: action.message};
        case DELETE_INVOICE_ITEMS_RETRY:
            return {...state, isDeleting: false, retry: true, message: action.message};

        case FETCH_INVOICE_ITEMS_BATCH:
            return {...state, isFetching: true, error: false, message: null, retry: false};
        case FETCH_INVOICE_ITEMS_BATCH_RESULT:
            return {...state, isFetching: false, batchList: action.data};
        case FETCH_INVOICE_ITEMS_BATCH_ERROR:
            return {...state, isFetching: false, error: true, message: action.message};
        case FETCH_INVOICE_ITEMS_BATCH_RETRY:
            return {...state, isFetching: false, retry: true, message: action.message};

        default:
            return state;
    }
};

export default reducer;
