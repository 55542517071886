export const FETCH_SHIPPED_ORDERS = 'FETCH_SHIPPED_ORDERS';
export const FETCH_SHIPPED_ORDERS_RESULT = 'FETCH_SHIPPED_ORDERS_RESULT';
export const FETCH_SHIPPED_ORDERS_ERROR = 'FETCH_SHIPPED_ORDERS_ERROR';
export const FETCH_SHIPPED_ORDERS_RETRY = 'FETCH_SHIPPED_ORDERS_RETRY';


export const fetchShippedOrders = (data) => ({
    type: FETCH_SHIPPED_ORDERS,
    data,
});

