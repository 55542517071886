//External Imports
import React from 'react';
import {Route, Switch} from 'react-router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//My Imports
import routes from './routes';
import './App.scss';
import LoginRedirect from "./components/others/LoginRedirect";


class App extends React.Component {

    render() {
        return (
            <div>
                <LoginRedirect />
                <Switch>
                    {
                        routes.map(
                            (route, index) => <Route key={index} {...route} />
                        )
                    }
                </Switch>
                <ToastContainer enableMultiContainer
                                autoClose={3000}
                                className={'toast-container'}/>
            </div>
        );
    }
}

export default App;
