import {call, put} from 'redux-saga/effects';
import {
    FETCH_DELIVERY_CHALLAN_ITEMS,
    FETCH_DELIVERY_CHALLAN_ITEMS_RESULT,
    FETCH_DELIVERY_CHALLAN_ITEMS_ERROR,
    FETCH_DELIVERY_CHALLAN_ITEMS_RETRY,

    CREATE_DELIVERY_CHALLAN_ITEM,
    CREATE_DELIVERY_CHALLAN_ITEM_RESULT,
    CREATE_DELIVERY_CHALLAN_ITEM_ERROR,
    CREATE_DELIVERY_CHALLAN_ITEM_RETRY,

    UPDATE_DELIVERY_CHALLAN_ITEM,
    UPDATE_DELIVERY_CHALLAN_ITEM_RESULT,
    UPDATE_DELIVERY_CHALLAN_ITEM_ERROR,
    UPDATE_DELIVERY_CHALLAN_ITEM_RETRY,

    DELETE_DELIVERY_CHALLAN_ITEM,
    DELETE_DELIVERY_CHALLAN_ITEM_RESULT,
    DELETE_DELIVERY_CHALLAN_ITEM_ERROR,
    DELETE_DELIVERY_CHALLAN_ITEM_RETRY,
} from '../../actions/pharmacy/deliverychallanitems';
import api from "../api";


export function* fetchDeliveryChallanItems(action) {
    try {
        const response = yield call(api.pharmacy.deliverychallanitems.list, action.data);
        const result = yield response.json();
        console.warn('SAGA:fetchDeliveryChallanItems', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_DELIVERY_CHALLAN_ITEMS_RESULT, data: result});
        } else {
            yield put({type: FETCH_DELIVERY_CHALLAN_ITEMS_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_DELIVERY_CHALLAN_ITEMS_RETRY, retry: true, message: e.message});
    }
}

export function* createDeliveryChallanItem(action) {
    try {
        const response = yield call(api.pharmacy.deliverychallanitems.create, action.data);
        const result = yield response.json();
        console.warn('SAGA:createDeliveryChallanItem', action, result);

        if (response.status === 201) {
            yield put({type: CREATE_DELIVERY_CHALLAN_ITEM_RESULT, data: result});
        } else {
            yield put({type: CREATE_DELIVERY_CHALLAN_ITEM_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: CREATE_DELIVERY_CHALLAN_ITEM_RETRY, retry: true, message: e.message});
    }
}

export function* updateDeliveryChallanItem(action) {
    try {
        const response = yield call(api.pharmacy.deliverychallanitems.update, action.data);
        const result = yield response.json();
        console.warn('SAGA:updateDeliveryChallanItem', action, result);

        if (response.status === 200) {
            yield put({type: UPDATE_DELIVERY_CHALLAN_ITEM_RESULT, data: result});
        } else {
            yield put({type: UPDATE_DELIVERY_CHALLAN_ITEM_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: UPDATE_DELIVERY_CHALLAN_ITEM_RETRY, retry: true, message: e.message});
    }
}

export function* deleteDeliveryChallanItem(action) {
    try {
        const response = yield call(api.pharmacy.deliverychallanitems.remove, action.data);
        console.warn('SAGA:deleteDeliveryChallanItem', action);

        if (response.status === 204) {
            yield put({type: DELETE_DELIVERY_CHALLAN_ITEM_RESULT});
        } else {
            yield put({type: DELETE_DELIVERY_CHALLAN_ITEM_ERROR, error: true});
        }
    } catch (e) {
        yield put({type: DELETE_DELIVERY_CHALLAN_ITEM_RETRY, retry: true, message: e.message});
    }
}