import {call, put} from 'redux-saga/effects';
import {
    FETCH_BATCHES,
    FETCH_BATCHES_RESULT,
    FETCH_BATCHES_ERROR,
    FETCH_BATCHES_RETRY,

    CREATE_BATCH,
    CREATE_BATCH_RESULT,
    CREATE_BATCH_ERROR,
    CREATE_BATCH_RETRY,

    UPDATE_BATCH,
    UPDATE_BATCH_RESULT,
    UPDATE_BATCH_ERROR,
    UPDATE_BATCH_RETRY,
} from '../../actions/pharmacy/batches';
import api from "../api";


export function* fetchBatches(action) {
    try {
        const response = yield call(api.pharmacy.batches.list, action.data);
        const result = yield response.json();
        console.warn('SAGA:fetchBatches', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_BATCHES_RESULT, data: result});
        }
        else {
            yield put({type: FETCH_BATCHES_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_BATCHES_RETRY, retry: true, message: e.message});
    }
}


export function* updateBatch(action) {
    try {
        const response = yield call(api.pharmacy.batches.update, action.data);
        const result = yield response.json();
        console.warn('SAGA:updateBatch', action, result);

        if (response.status === 200) {
            yield put({type: UPDATE_BATCH_RESULT, data: result});
        }
        else {
            yield put({type: UPDATE_BATCH_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: UPDATE_BATCH_RETRY, retry: true, message: e.message});
    }
}

export function* createBatch(action) {
    try {
        const response = yield call(api.pharmacy.batches.create, action.data);
        const result = yield response.json();
        console.warn('SAGA:createBatch', action, result);

        if (response.status === 201) {
            yield put({type: CREATE_BATCH_RESULT, data: result});
        }
        else {
            yield put({type: CREATE_BATCH_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: CREATE_BATCH_RETRY, retry: true, message: e.message});
    }
}