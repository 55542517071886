import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as PropTypes from 'prop-types';
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import moment from "moment";

import {SidebarContainer} from "../../components/sidebars/MainSidebar";
import MainNavbar from "../../components/navbars/MainNavbar";
import {fetchBatches} from "../../actions/pharmacy/batches";
import {getPhoneNumberWithoutCountryCode, getSearchParams, scrollToTop} from "../../constants";
import './styles.scss';
import {toast} from "react-toastify";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Spinner from "react-bootstrap/Spinner";
import PaginationComponent from "../../components/others/PaginationComponent";
import BatchEditCreateModal from "../../components/modals/BatchEditCreateModal";


class Batches extends React.Component {

    static propTypes = {
        batches: PropTypes.object,
        fetchBatches: PropTypes.func,
    };

    constructor(props) {
        super(props);
        let {search} = props.history.location;
        let {page = 1, q = ''} = getSearchParams(search);

        this.state = {
            showBatchModal: false,
            batch: {},
            search: q,
            searchQuery: q,
            page,
        };
    }

    componentDidMount() {
        let {page, search} = this.state;
        this.props.fetchBatches({page, search});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {detail, isFetching, isUpdating, isCreating, error, retry} = this.props.batches;

        let {search, page} = this.state;
        let prevQueryParams = getSearchParams(prevProps.location.search);
        let queryParams = getSearchParams(this.props.location.search);

        if (!isFetching && prevProps.batches.isFetching && !error && !retry) {
            scrollToTop();
        }

        if ((!isUpdating && prevProps.batches.isUpdating && !error && !retry) ||
            (!isCreating && prevProps.batches.isCreating && !error && !retry)) {
            this.props.fetchBatches({search, page});
            this.setState({showBatchModal: false, batch: {}})
        }

        if (page !== prevState.page || search !== prevState.search) {
            this.props.fetchBatches({search, page});
        }

        if (prevQueryParams.q !== queryParams.q || prevQueryParams.page !== queryParams.page) {
            this.setState({
                search: queryParams.q || '',
                searchQuery: queryParams.q || '',
                page: parseInt(queryParams.page || "1"),
            });
        }
    }

    handleSearchClick = (e) => {
        e && e.preventDefault();
        let {searchQuery} = this.state;
        this.setState({page: 1, search: searchQuery});
        this.props.history.push(`/batches/?q=${searchQuery}&page=${1}`);
    };

    handlePageClick = (page) => {
        let {search} = this.state;
        this.setState({page});
        this.props.history.push(`/batches/?q=${search}&page=${page}`);
    };

    render() {
        let {batches: {list: {results = [], count}, isFetching}} = this.props;
        let {searchQuery, page, showBatchModal, batch} = this.state;

        return (
            <SidebarContainer>
                <MainNavbar pageName={'Batches'}/>

                <BatchEditCreateModal show={showBatchModal} batch={batch}
                                      handleClose={() => this.setState({showBatchModal: false, batch: {}})}/>

                <div className="container-fluid">
                    <div className="row pt-5">
                        <div className="col-8">
                            <Form onSubmit={this.handleSearchClick}>
                                <InputGroup>
                                    <Form.Control type="text"
                                                  value={searchQuery}
                                                  placeholder="Search Batch..."
                                                  onChange={(e) => this.setState({searchQuery: e.target.value})}/>
                                    <InputGroup.Append>
                                        <Button type="submit" variant="success" size="sm">
                                            {isFetching ?
                                                <Spinner size="sm" animation="border"/> :
                                                "Search"
                                            }
                                        </Button>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Form>
                        </div>
                        <div className="col-4 text-right">
                            <Button variant="success" size={'sm'}
                                    onClick={() => this.setState({showBatchModal: true})}>
                                Create
                            </Button>
                        </div>
                        <div className="col-12 pt-2">
                            <Table responsive striped bordered hover cellPadding={3}>
                                <thead>
                                <tr>
                                    <th>Batch No</th>
                                    <th>Name</th>
                                    <th>Product</th>
                                    <th>Mrp</th>
                                    <th>Qty</th>
                                    <th>Inventory</th>
                                    <th>Active</th>
                                    <th>Expiry Date</th>
                                    <th>Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    results.map(
                                        (batch) => (
                                            <tr key={batch.id}>
                                                <td>{batch.batch_number}</td>
                                                <td>{batch.name}</td>
                                                <td>{batch.product_name} ({batch.product_id})</td>
                                                <td>{batch.mrp}</td>
                                                <td>{batch.quantity}</td>
                                                <td>{batch.inventory}</td>
                                                <td className="text-center">
                                                    {
                                                        !!batch.active ?
                                                            <i className="fa fa-check" style={{color: 'green'}}/>
                                                            :
                                                            <i className="fa fa-times" style={{color: 'red'}}/>
                                                    }
                                                </td>
                                                <td>
                                                    {moment(batch.expiry_date).format("ddd, MMMM Do YYYY, h:mm:ss a")}
                                                </td>
                                                <td>
                                                    <ButtonGroup vertical className="action-group">
                                                        <Button variant="primary" size={'sm'}
                                                                onClick={() => this.setState({
                                                                    showBatchModal: true,
                                                                    batch: batch
                                                                })}>
                                                            Edit
                                                        </Button>
                                                    </ButtonGroup>
                                                </td>
                                            </tr>
                                        )
                                    )
                                }
                                </tbody>
                            </Table>
                        </div>
                        <div className="col-12 py-3">
                            {!!count &&
                            <PaginationComponent page={parseInt(page)} count={parseInt(count)}
                                                 onClick={this.handlePageClick}/>}
                        </div>
                    </div>
                </div>

            </SidebarContainer>
        )
    }
}

const mapStateToProps = (state) => ({
    batches: state.pharmacy.batches,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    fetchBatches,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(Batches);
