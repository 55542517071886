import {call, put} from 'redux-saga/effects';
import {
    FETCH_DELIVERY_CHALLANS,
    FETCH_DELIVERY_CHALLANS_RESULT,
    FETCH_DELIVERY_CHALLANS_ERROR,
    FETCH_DELIVERY_CHALLANS_RETRY,

    CREATE_DELIVERY_CHALLAN,
    CREATE_DELIVERY_CHALLAN_RESULT,
    CREATE_DELIVERY_CHALLAN_ERROR,
    CREATE_DELIVERY_CHALLAN_RETRY,

    UPDATE_DELIVERY_CHALLAN,
    UPDATE_DELIVERY_CHALLAN_RESULT,
    UPDATE_DELIVERY_CHALLAN_ERROR,
    UPDATE_DELIVERY_CHALLAN_RETRY,

    DELETE_DELIVERY_CHALLAN,
    DELETE_DELIVERY_CHALLAN_RESULT,
    DELETE_DELIVERY_CHALLAN_ERROR,
    DELETE_DELIVERY_CHALLAN_RETRY,
} from '../../actions/pharmacy/deliverychallans';
import api from "../api";


export function* fetchDeliveryChallans(action) {
    try {
        const response = yield call(api.pharmacy.deliverychallans.list, action.data);
        const result = yield response.json();
        console.warn('SAGA:fetchDeliveryChallans', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_DELIVERY_CHALLANS_RESULT, data: result});
        } else {
            yield put({type: FETCH_DELIVERY_CHALLANS_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_DELIVERY_CHALLANS_RETRY, retry: true, message: e.message});
    }
}

export function* createDeliveryChallan(action) {
    try {
        const response = yield call(api.pharmacy.deliverychallans.create, action.data);
        const result = yield response.json();
        console.warn('SAGA:createDeliveryChallan', action, result);

        if (response.status === 201) {
            yield put({type: CREATE_DELIVERY_CHALLAN_RESULT, data: result});
        } else {
            yield put({type: CREATE_DELIVERY_CHALLAN_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: CREATE_DELIVERY_CHALLAN_RETRY, retry: true, message: e.message});
    }
}

export function* updateDeliveryChallan(action) {
    try {
        const response = yield call(api.pharmacy.deliverychallans.update, action.data);
        const result = yield response.json();
        console.warn('SAGA:updateDeliveryChallan', action, result);

        if (response.status === 200) {
            yield put({type: UPDATE_DELIVERY_CHALLAN_RESULT, data: result});
        } else {
            yield put({type: UPDATE_DELIVERY_CHALLAN_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: UPDATE_DELIVERY_CHALLAN_RETRY, retry: true, message: e.message});
    }
}

export function* deleteDeliveryChallan(action) {
    try {
        const response = yield call(api.pharmacy.deliverychallans.remove, action.data);
        console.warn('SAGA:deleteDeliveryChallan', action);

        if (response.status === 204) {
            yield put({type: DELETE_DELIVERY_CHALLAN_RESULT});
        } else {
            yield put({type: DELETE_DELIVERY_CHALLAN_ERROR, error: true});
        }
    } catch (e) {
        yield put({type: DELETE_DELIVERY_CHALLAN_RETRY, retry: true, message: e.message});
    }
}
