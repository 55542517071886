import {
    CREATE_CONSULTATION,
    CREATE_CONSULTATION_RESULT,
    CREATE_CONSULTATION_ERROR,
    CREATE_CONSULTATION_RETRY,
    FETCH_CONSULTATIONS,
    FETCH_CONSULTATIONS_RESULT,
    FETCH_CONSULTATIONS_ERROR,
    FETCH_CONSULTATIONS_RETRY,
    FETCH_CONSULTATIONS_MASTER,
    FETCH_CONSULTATIONS_MASTER_RESULT,
    FETCH_CONSULTATIONS_MASTER_ERROR,
    FETCH_CONSULTATIONS_MASTER_RETRY,
} from '../../actions/consultations/consultations';


const initialState = {
    detail: {},
    list: [],
    templates: [],
    isCreating: false,
    isFetching: false,
    error: false,
    message: null,
    retry: false,
};


const reducer = (state=initialState, action) => {
    switch (action.type) {
        case CREATE_CONSULTATION:
            return {...state, isCreating: true, error: false, message: null, retry: false};
        case CREATE_CONSULTATION_RESULT:
            return {...state, isCreating: false, detail: action.data};
        case CREATE_CONSULTATION_ERROR:
            return {...state, isCreating: false, error: true, message: action.message};
        case CREATE_CONSULTATION_RETRY:
            return {...state, isCreating: false, retry: true, message: action.message};
        case FETCH_CONSULTATIONS:
            return {...state, isFetching: true, error: false, message: null, retry: false};
        case FETCH_CONSULTATIONS_RESULT:
            return {...state, isFetching: false, list: action.data};
        case FETCH_CONSULTATIONS_ERROR:
            return {...state, isFetching: false, error: true, message: action.message};
        case FETCH_CONSULTATIONS_RETRY:
            return {...state, isFetching: false, retry: true, message: action.message};
        case FETCH_CONSULTATIONS_MASTER:
            return {...state, isFetching: true, error: false, message: null, retry: false};
        case FETCH_CONSULTATIONS_MASTER_RESULT:
            return {...state, isFetching: false, templates: action.data};
        case FETCH_CONSULTATIONS_MASTER_ERROR:
            return {...state, isFetching: false, error: true, message: action.message};
        case FETCH_CONSULTATIONS_MASTER_RETRY:
            return {...state, isFetching: false, retry: true, message: action.message};
        default:
            return state;
    }
};

export default reducer;
