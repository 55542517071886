import React from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Col from "react-bootstrap/Col";
import Button from 'react-bootstrap/Button';
import * as PropTypes from "prop-types";
import Select from "react-select";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import {fetchReturnedOrder, createReturnedOrder, updateReturnedOrder} from "../../../actions/pharmacy/returned";
import Table from "react-bootstrap/Table";


class ReturnedOrderEditCreateModal extends React.Component {

    static propTypes = {
        returned: PropTypes.object,
        show: PropTypes.bool,
        createReturnedOrder: PropTypes.func,
        updateReturnedOrder: PropTypes.func,
        handleClose: PropTypes.func,
    };

    static defaultProps = {
        returned: {},
    };

    constructor(props) {
        super(props);

        this.state = {
            invoice: props.invoice,
            message: null,
            detail: {},
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {detail, isFetching, isCreating, isUpdating, message, error, retry} = this.props.returned
        let {invoice, handleClose} = this.props;

        if (this.props.show && !prevProps.show) {
            this.props.fetchReturnedOrder(invoice);
            this.setState({
                invoice: this.props.invoice,
                message: null,
                detail: {},
            });
        }

        if (!isFetching && prevProps.returned.isFetching && !error && !retry) {
            let received_at = !!detail.batches && !!detail.batches.length ? detail.batches[0].received_at : new Date();
            this.setState({detail: {received_at, ...detail}});
        }

        if ((!isCreating && prevProps.returned.isCreating && !error && !retry) ||
            (!isUpdating && prevProps.returned.isUpdating && !error && !retry)) {
            handleClose();
        }

        if ((!isCreating && prevProps.returned.isCreating && (error || retry)) ||
            (!isUpdating && prevProps.returned.isUpdating && (error || retry))) {
            this.setState({message});
        }
    }

    handleCreateClick = () => {
        let {invoice, detail} = this.state;
        if (invoice.acknowledgement) {
            this.props.updateReturnedOrder({id: invoice.id, ...detail});
        } else {
            this.props.createReturnedOrder({id: invoice.id, ...detail});
        }
    };

    handleChange = (batch, index) => {
        let {detail, detail: {batches}} = this.state;
        batches[index] = batch;

        this.setState({
            detail: {
                ...detail,
                batches: batches
            }
        })
    }

    renderDamageList = (batch) => {
        let i = 0, list = [];
        while (i <= batch.quantity) {
            list.push({label: i, value: i});
            i++;
        }
        return list;
    }

    render() {
        let {returned, show, handleClose} = this.props;
        let {invoice, message, detail} = this.state;

        return (
            <Modal size="lg" show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Return Acknowledgement</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Order CID: {!!invoice.order && invoice.order.cid}</p>
                    <p>Invoice Number: {invoice.invoice_number}</p>
                    {!!detail.invoice &&
                    <React.Fragment>
                        <p>Invoice No: {detail.invoice.invoice_number}</p>
                        <p>Invoice Status: {detail.invoice.status}</p>
                    </React.Fragment>}

                    <div className="row">
                        <div className="col-12 col-md-6">
                            <Form.Group controlId="received_at">
                                <Form.Label>Received at</Form.Label>
                                <DatePicker showYearDropdown showMonthDropdown
                                            className='form-control'
                                            placeholderText='Received at'
                                            dateFormat="dd MMM yyyy"
                                            selected={!!detail.received_at ? new Date(detail.received_at) : ''}
                                            onChange={(date) => this.setState({
                                                detail: {
                                                    ...detail,
                                                    received_at: moment(date).format(),
                                                }
                                            })}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {!!message && message.received_at}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </div>
                    </div>
                    <Table striped bordered hover cellPadding={3}>
                        <thead>
                        <tr>
                            <th>index</th>
                            <th>Batch</th>
                            <th>Qty</th>
                            <th>Damaged</th>
                        </tr>
                        </thead>
                        <tbody>
                        {!!detail.batches &&
                        detail.batches.map((batch, index) => {
                            let damageList = !!batch.quantity ? this.renderDamageList(batch) : [];
                            return (
                                <tr key={index}>
                                    <th>{index}</th>
                                    <td>{batch.batch_name}</td>
                                    <td>
                                        <Form.Group className="mb-0" controlId="quantity">
                                            <Form.Control type="number" placeholder="Quantity"
                                                          value={batch.quantity || 0}
                                                          onChange={(e) => this.handleChange({
                                                              ...batch,
                                                              quantity: parseInt(e.target.value),
                                                          }, index)}/>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group controlId="damaged">
                                            <Select options={damageList.map(item => item)}
                                                    value={damageList.filter(item => item.value === batch.damaged).map(item => item)}
                                                    onChange={(item) => this.handleChange({
                                                        ...batch,
                                                        damaged: item.value,
                                                    }, index)}/>
                                        </Form.Group>
                                    </td>
                                </tr>
                            )
                        })

                        }
                        </tbody>
                    </Table>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-danger" onClick={handleClose}>Close</Button>
                    <Button variant="success" onClick={this.handleCreateClick}
                            disabled={returned.isCreating || returned.isUpdating}>
                        {
                            (returned.isCreating || returned.isUpdating) ?
                                'Please wait ...':
                                `${invoice.acknowledgement ? 'Update' : 'Submit'}`
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}


const mapStateToProps = (state) => ({
    returned: state.pharmacy.returned,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    fetchReturnedOrder,
    createReturnedOrder,
    updateReturnedOrder,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(ReturnedOrderEditCreateModal);
