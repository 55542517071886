import React from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import * as PropTypes from "prop-types";
import Select from "react-select";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import {fetchItems} from "../../../actions/pharmacy/items";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import {createInvoice} from "../../../actions/pharmacy/invoices";
import Table from "react-bootstrap/Table";
import {getPhoneNumber, getPhoneNumberWithoutCountryCode, getPrice, getPriceInPaisa} from "../../../constants";
import {
    createInvoiceItems,
    deleteInvoiceItems,
    fetchInvoiceItems,
    fetchInvoiceItemsBatch,
    updateInvoiceItems
} from "../../../actions/pharmacy/invoiceitems";
import ButtonGroup from "react-bootstrap/ButtonGroup";

import "./styles.scss";
import InvoiceItemsDetailModal from "../InvoiceItemsDetailModal";
import {Col} from "react-bootstrap";
import api from "../../../sagas/api";


class InvoiceCreateModal extends React.Component {

    static propTypes = {
        show: PropTypes.bool,
        fetchItems: PropTypes.func,
        fetchInvoiceItemsBatch: PropTypes.func,
        createInvoiceItems: PropTypes.func,
        createInvoice: PropTypes.func,
        handleClose: PropTypes.func,
    };

    static defaultProps = {};

    state = {
        invoiceItems: [{}],

        is_cod: false,
        order_cid: '',
        gst_no: '',
        channel: '',
        address: {
            name: '',
            street: '',
            street2: '',
            landmark: '',
            city: '',
            state: '',
            zipcode: '',
            phone: '',
        },
        message: undefined,
    };

    channelList = [
        {label: '1 MG', value: '1mg'},
        {label: 'AMAZON', value: 'amazon'},
        {label: 'lybrate', value: 'lybrate'},
        {label: 'clinic', value: 'clinic'},
    ]

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {
            isFetching,
            isCreating,
            isUpdating,
            isDeleting,
            detail,
            list,
            error,
            retry
        } = this.props.invoiceitems;
        let {invoices} = this.props;
        let {invoiceItems, itemIndex} = this.state;

        if (this.props.show && !prevProps.show) {
            this.props.fetchItems();
            this.props.fetchInvoiceItems();
            this.props.fetchInvoiceItemsBatch();

            this.setState({
                invoiceItems: [{}],
                message: undefined,
            });
        }

        if (!invoices.isCreating && prevProps.invoices.isCreating && !invoices.error && !invoices.retry) {
            this.props.handleClose();
        }

        if (!invoices.isCreating && prevProps.invoices.isCreating && (invoices.error || invoices.retry)) {
            this.setState({message: invoices.message});
        }

        if (!isFetching && prevProps.invoiceitems.isFetching && !error && !retry) {
            if (!!list.length) {
                this.setState({
                    invoiceItems: list,
                });
            }
        }

        if (!isCreating && prevProps.invoiceitems.isCreating && !error && !retry) {
            invoiceItems[invoiceItems.length - 1] = detail
            this.setState({invoiceItems});
        }

        if (!isUpdating && prevProps.invoiceitems.isUpdating && !error && !retry) {
            invoiceItems[itemIndex] = detail;
            this.setState({invoiceItems});
        }

        if (!isDeleting && prevProps.invoiceitems.isDeleting && !error && !retry) {
            invoiceItems.splice(itemIndex, 1);
            this.setState({invoiceItems});
        }

    }

    handleChange = (item, index) => {
        let {invoiceItems} = this.state;
        invoiceItems[index] = item;

        this.setState({invoiceItems})
    }

    handleAddItem = () => {
        let {invoiceItems} = this.state;

        this.setState({
            invoiceItems: [
                ...invoiceItems,
                {}
            ]
        })
    }

    handleCreateItem = (item) => {
        this.props.createInvoiceItems({
            ...item,
            price: item.price,
        });
    }

    handleUpdateItem = (item, index) => {
        this.setState({itemIndex: index});

        this.props.updateInvoiceItems({
            id: item.id,
            item: item.item,
            quantity: item.quantity,
            price: item.price,
            batch: item.batch,
        });
    }

    handleDeleteItem = (item, index) => {
        this.setState({itemIndex: index});
        this.props.deleteInvoiceItems({id: item.id});
    }

    handleSubmit = () => {
        let {invoiceItems, address, is_cod, gst_no, order_cid, channel} = this.state;
        let invoice_items = [];
        this.setState({message: undefined});
        invoiceItems.forEach((item) => {
            if (!!item.id)
                invoice_items.push(item.id);
        })

        this.props.createInvoice({address, is_cod, gst_no, order_cid, channel, invoice_items});
    }

    handleChangePinCode = async (e) => {
        const pincode = e.target.value;

        this.setState({
            address: {
                ...this.state.address,
                zipcode: pincode
            }
        });

        if (pincode.length === 6) {
            try {
                let res = await api.ecommerce.address.pincode({pincode});
                let result = await res.json();
                if (res.status === 200) {
                    this.setState(prevState => ({
                        address: {...prevState.address, zipcode: result.pincode, city: result.district, state: result.state,},
                        message: undefined
                    }));
                } else {
                    this.setState(prevState => ({
                        address: {...prevState.address, zipcode: pincode, city: '', state: '',},
                        message: {zipcode: [result.detail],}
                    }));
                }
            } catch (e) {
                console.warn(e);
                this.setState(prevState => ({
                    address: {...prevState.address, zipcode: pincode, city: '', state: '',}
                }));
            }
        }

    };

    render() {
        let {show, handleClose, items, invoiceitems, invoices} = this.props;
        let {invoiceItems} = this.state;
        let {
            address: {name, street, city, state, zipcode, street2, landmark, phone},
            address, gst_no, is_cod, order_cid, channel, message
        } = this.state;
        let hasItems = !(!!invoiceItems.length && !invoiceItems[0].id);
        let enableCreation = name.length && street.length && city.length && state.length && zipcode.length && phone.length && channel && hasItems;

        let i = 0, quantityList = [];
        while (i <= 10) {
            quantityList.push({label: i, value: i});
            i++;
        }
        // console.warn('message', message);

        return (
            <Modal size="xl" show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Invoice</Modal.Title>
                </Modal.Header>

                <Modal.Body className="invoice-modal-body">
                    <Form.Row>

                        <Form.Group as={Col} controlId="formName">
                            <Form.Label>Name*</Form.Label>
                            <Form.Control type="text" placeholder="Enter Name"
                                          onChange={(e) => this.setState({
                                              address: {
                                                  ...address,
                                                  name: e.target.value
                                              }
                                          })}
                                          isInvalid={!!message && !!message.address && !!message.address.name}
                                          value={name}/>
                            <Form.Control.Feedback type="invalid">
                                {!!message && !!message.address && message.address.name}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} controlId="formZipcode">
                            <Form.Label>10-digit Mobile Number*</Form.Label>
                            <Form.Control type="number" placeholder="Enter Phone"
                                          onChange={(e) => this.setState({
                                              address: {
                                                  ...address,
                                                  phone: getPhoneNumber(e.target.value)
                                              }
                                          })}
                                          autoComplete="tel"
                                          isInvalid={!!message && !!message.address && !!message.address.phone}
                                          value={getPhoneNumberWithoutCountryCode(phone)}/>
                            <Form.Control.Feedback type="invalid">
                                {!!message && !!message.address && message.address.phone}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>

                        <Form.Group as={Col} controlId="channel">
                            <Form.Label>Select Channel*</Form.Label>
                            <Select placeholder="Select Channel"
                                    options={this.channelList}
                                    value={this.channelList.filter(c => c.value === channel)}
                                    onChange={(value) => {
                                        this.setState({
                                            channel: value.value
                                        })
                                    }}/>
                            <Form.Control type="hidden" isInvalid={!!message && !!message.channel}/>
                            <Form.Control.Feedback type="invalid">
                                {!!message && message.channel}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} controlId="order_cid">
                            <Form.Label>Order CID*</Form.Label>
                            <Form.Control type="text" placeholder="Order CID"
                                          onChange={(e) => this.setState({
                                              order_cid: e.target.value
                                          })}
                                          isInvalid={!!message && !!message.order_cid}
                                          value={order_cid}/>
                            <Form.Control.Feedback type="invalid">
                                {!!message && message.order_cid}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} controlId="is_cod">
                            <Form.Label>Payment Type</Form.Label>
                            <Form.Check type="checkbox" label="Is Cash on Delivery"
                                        checked={is_cod}
                                        isInvalid={!!message && !!message.is_cod}
                                        onChange={(e) => this.setState({
                                            is_cod: !is_cod
                                        })}/>
                            <Form.Control.Feedback type="invalid">
                                {!!message && message.is_cod}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="formStreet">
                            <Form.Label>Street*</Form.Label>
                            <Form.Control type="text" placeholder="Flat, House no., Building, Company, Apartment"
                                          onChange={(e) => this.setState({
                                              address: {
                                                  ...address,
                                                  street: e.target.value
                                              }
                                          })}
                                          autoComplete="shipping address-line1"
                                          isInvalid={!!message && !!message.address && !!message.address.street}
                                          value={street}/>
                            <Form.Control.Feedback type="invalid">
                                {!!message && !!message.address && message.address.street}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} controlId="formStreet2">
                            <Form.Label>Street2</Form.Label>
                            <Form.Control type="text" placeholder="Area, Colony, Street, Sector, Village"
                                          onChange={(e) => this.setState({
                                              address: {
                                                  ...address,
                                                  street2: e.target.value
                                              }
                                          })}
                                          autoComplete="shipping address-line2"
                                          isInvalid={!!message && !!message.address && !!message.address.street2}
                                          value={street2}/>
                            <Form.Control.Feedback type="invalid">
                                {!!message && !!message.address && message.address.street2}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formZipcode">
                            <Form.Label>Zipcode*</Form.Label>
                            <Form.Control type="number" placeholder="Enter Zipcode"
                                          onChange={this.handleChangePinCode}
                                          autoComplete="shipping postal-code"
                                          isInvalid={!!message && !!message.address && !!message.address.zipcode}
                                          value={zipcode}/>
                            <Form.Control.Feedback type="invalid">
                                {!!message && !!message.address && message.address.zipcode}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} controlId="formCity">
                            <Form.Label>City*</Form.Label>
                            <Form.Control type="text" placeholder="Enter City"
                                          onChange={(e) => this.setState({
                                              address: {
                                                  ...address,
                                                  city: e.target.value
                                              }
                                          })}
                                          autoComplete="shipping locality"
                                          isInvalid={!!message && !!message.address && !!message.address.city}
                                          value={city}/>
                            <Form.Control.Feedback type="invalid">
                                {!!message && !!message.address && message.address.city}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} controlId="formState">
                            <Form.Label>State*</Form.Label>
                            <Form.Control type="text" placeholder="Enter State"
                                          onChange={(e) => this.setState({
                                              address: {
                                                  ...address,
                                                  state: e.target.value
                                              }
                                          })}
                                          autoComplete="shipping region"
                                          isInvalid={!!message && !!message.address && !!message.address.state}
                                          value={state}/>
                            <Form.Control.Feedback type="invalid">
                                {!!message && !!message.address && message.address.state}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formLandmark">
                            <Form.Label>Landmark</Form.Label>
                            <Form.Control type="text" placeholder="Landmark e.g. Near AIIMS Fliover, etc."
                                          onChange={(e) => this.setState({
                                              address: {
                                                  ...address,
                                                  landmark: e.target.value
                                              }
                                          })}
                                          isInvalid={!!message && !!message.address && !!message.address.landmark}
                                          value={landmark}/>
                            <Form.Control.Feedback type="invalid">
                                {!!message && !!message.address && message.address.landmark}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} controlId="formLandmark">
                            <Form.Label>GST IN</Form.Label>
                            <Form.Control type="text" placeholder="GST IN number of the business (not required)"
                                          onChange={(e) => this.setState({
                                              gst_no: e.target.value
                                          })}
                                          isInvalid={!!message && !!message.gst_no }
                                          value={gst_no}/>
                            <Form.Control.Feedback type="invalid">
                                {!!message && !!message.gst_no && message.gst_no}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>

                    <div className="row mb-2">
                        <div className="col-12 text-right">
                            {(!invoiceItems.length || !!invoiceItems[invoiceItems.length - 1].id) &&
                            <Button variant="primary" size="sm" onClick={this.handleAddItem}>Add More Item</Button>}
                        </div>
                    </div>
                    <div className="invoice-modal-table-container">
                        <Table className="invoice-modal-table" striped bordered>
                            <thead>
                            <tr>
                                <th>Id</th>
                                <th>Item</th>
                                <th>Qty</th>
                                <th>Price <small>(in Paisa)</small></th>
                                <th>Batch</th>
                                <th>Discount</th>
                                <th>Tax</th>
                                <th>Rate</th>
                                <th>Updated at</th>
                                <th>Created at</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {invoiceItems.map((item, index) => (
                                <tr key={index + 1}>
                                    <th>#{item.id}</th>
                                    <td style={{minWidth: '180px'}}>
                                        <Form.Group className="mb-0" controlId="items_select">
                                            <Select options={items.list.map(i => ({
                                                label: `${i.product_name}-${i.product_id}`,
                                                value: i.id
                                            }))}
                                                    value={items.list.filter(i => i.id === item.item).map(i => ({
                                                        label: `${i.product_name}-${i.product_id}`,
                                                        value: i.value
                                                    }))}
                                                    onChange={(i) => this.handleChange({
                                                        ...item,
                                                        item: i.value,
                                                    }, index)}/>
                                        </Form.Group>
                                    </td>
                                    <td style={{minWidth: '100px'}}>
                                        <Form.Group className="mb-0" controlId="qty">
                                            <Form.Control type="number" placeholder="quantity"
                                                          value={item.quantity || ''}
                                                          onChange={(e) => this.handleChange({
                                                              ...item,
                                                              quantity: parseInt(e.target.value),
                                                          }, index)}/>
                                        </Form.Group>
                                    </td>
                                    <td style={{minWidth: '100px'}}>
                                        <Form.Group className="mb-0" controlId="price">
                                            <Form.Control type="number" placeholder="Price"
                                                          value={item.price || ''}
                                                          onChange={(e) => this.handleChange({
                                                              ...item,
                                                              price: parseInt(e.target.value),
                                                          }, index)}/>
                                            {!!item.price &&
                                            <b className="d-block text-center mt-2">{`₹${getPrice(item.price)}`}</b>}
                                        </Form.Group>
                                    </td>
                                    <td style={{minWidth: '150px'}}>
                                        <Form.Group className="mb-0" controlId="batch_select">
                                            <Select options={invoiceitems.batchList.map(i => ({
                                                label: `${i.name}-${i.batch_number}`,
                                                value: i.id
                                            }))}
                                                    value={invoiceitems.batchList.filter(i => i.id === item.batch).map(i => ({
                                                        label: `${i.name}-${i.batch_number}`,
                                                        value: i.value
                                                    }))}
                                                    onChange={(i) => this.handleChange({
                                                        ...item,
                                                        batch: i.value,
                                                    }, index)}/>
                                        </Form.Group>
                                    </td>
                                    <th>{!!item.discount_percentage && `${item.discount_percentage}%`}</th>
                                    <th>{!!item.tax_percentage && `${item.tax_percentage}%`}</th>
                                    <th>​{!!item.rate && `₹${getPrice(item.rate)}`}</th>
                                    <td>{!!item.created_at && moment(item.updated_at).format('DD MMMM YYYY')}</td>
                                    <td>{!!item.created_at && moment(item.created_at).format('DD MMMM YYYY')}</td>
                                    <td>
                                        <ButtonGroup vertical>
                                            {!item.id &&
                                            <Button variant="success" size="sm"
                                                    onClick={() => this.handleCreateItem(item)}>
                                                Create
                                            </Button>}
                                            {item.id &&
                                            <Button variant="primary" size="sm"
                                                    onClick={() => this.handleUpdateItem(item, index)}>
                                                Update
                                            </Button>}
                                            {item.id &&
                                            <Button variant="danger" size="sm"
                                                    onClick={() => this.handleDeleteItem(item, index)}>
                                                Delete
                                            </Button>}
                                        </ButtonGroup>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-danger" onClick={handleClose}>Close</Button>
                    <Button variant="success" onClick={this.handleSubmit}
                            disabled={!enableCreation || invoices.isCreating}>
                        {invoices.isCreating ? 'Please wait..': 'Create Invoice'}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}


const mapStateToProps = (state) => ({
    items: state.pharmacy.items,
    invoiceitems: state.pharmacy.invoiceitems,
    invoices: state.pharmacy.invoices,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    fetchItems,
    createInvoiceItems,
    updateInvoiceItems,
    deleteInvoiceItems,
    fetchInvoiceItemsBatch,
    fetchInvoiceItems,
    createInvoice,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(InvoiceCreateModal);
