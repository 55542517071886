import {
    FETCH_PACKED_ORDERS,
    FETCH_PACKED_ORDERS_RESULT,
    FETCH_PACKED_ORDERS_ERROR,
    FETCH_PACKED_ORDERS_RETRY,

    CREATE_ORDER_INVOICE,
    CREATE_ORDER_INVOICE_RESULT,
    CREATE_ORDER_INVOICE_ERROR,
    CREATE_ORDER_INVOICE_RETRY,
} from '../../actions/ecommerce/packed';


const initialState = {
    list: [],
    detail: {},
    isFetching: false,
    isCreatingInvoice: false,
    isUploading: false,
    isConfirming: false,
    isCanceling: false,
    error: false,
    message: null,
    retry: false,
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PACKED_ORDERS:
            return {...state, isFetching: true, error: false, message: null, retry: false};
        case FETCH_PACKED_ORDERS_RESULT:
            return {...state, isFetching: false, list: action.data};
        case FETCH_PACKED_ORDERS_ERROR:
            return {...state, isFetching: false, error: true, message: action.message};
        case FETCH_PACKED_ORDERS_RETRY:
            return {...state, isFetching: false, retry: true, message: action.message};

        case CREATE_ORDER_INVOICE:
            return {...state, isCreatingInvoice: true, error: false, message: null, retry: false};
        case CREATE_ORDER_INVOICE_RESULT:
            return {...state, isCreatingInvoice: false, detail: action.data};
        case CREATE_ORDER_INVOICE_ERROR:
            return {...state, isCreatingInvoice: false, error: true, message: action.message};
        case CREATE_ORDER_INVOICE_RETRY:
            return {...state, isCreatingInvoice: false, retry: true, message: action.message};
        default:
            return state;
    }
};

export default reducer;
