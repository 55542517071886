export const FETCH_ORDERS = 'FETCH_ORDERS';
export const FETCH_ORDERS_RESULT = 'FETCH_ORDERS_RESULT';
export const FETCH_ORDERS_ERROR = 'FETCH_ORDERS_ERROR';
export const FETCH_ORDERS_RETRY = 'FETCH_ORDERS_RETRY';


export const CONFIRM_ORDER = 'CONFIRM_ORDER';
export const CONFIRM_ORDER_RESULT = 'CONFIRM_ORDER_RESULT';
export const CONFIRM_ORDER_ERROR = 'CONFIRM_ORDER_ERROR';
export const CONFIRM_ORDER_RETRY = 'CONFIRM_ORDER_RETRY';

export const CANCEL_ORDER = 'CANCEL_ORDER';
export const CANCEL_ORDER_RESULT = 'CANCEL_ORDER_RESULT';
export const CANCEL_ORDER_ERROR = 'CANCEL_ORDER_ERROR';
export const CANCEL_ORDER_RETRY = 'CANCEL_ORDER_RETRY';

export const REVERT_ORDER = 'REVERT_ORDER';
export const REVERT_ORDER_RESULT = 'REVERT_ORDER_RESULT';
export const REVERT_ORDER_ERROR = 'REVERT_ORDER_ERROR';
export const REVERT_ORDER_RETRY = 'REVERT_ORDER_RETRY';

export const UPDATE_ORDER_CID = 'UPDATE_ORDER_CID';
export const UPDATE_ORDER_CID_RESULT = 'UPDATE_ORDER_CID_RESULT';
export const UPDATE_ORDER_CID_ERROR = 'UPDATE_ORDER_CID_ERROR';
export const UPDATE_ORDER_CID_RETRY = 'UPDATE_ORDER_CID_RETRY';

export const CREATE_ORDER_IMAGE = 'CREATE_ORDER_IMAGE';
export const CREATE_ORDER_IMAGE_RESULT = 'CREATE_ORDER_IMAGE_RESULT';
export const CREATE_ORDER_IMAGE_ERROR = 'CREATE_ORDER_IMAGE_ERROR';
export const CREATE_ORDER_IMAGE_RETRY = 'CREATE_ORDER_IMAGE_RETRY';

export const FETCH_ORDER_IMAGE = 'FETCH_ORDER_IMAGE';
export const FETCH_ORDER_IMAGE_RESULT = 'FETCH_ORDER_IMAGE_RESULT';
export const FETCH_ORDER_IMAGE_ERROR = 'FETCH_ORDER_IMAGE_ERROR';
export const FETCH_ORDER_IMAGE_RETRY = 'FETCH_ORDER_IMAGE_RETRY';

export const UPDATE_ORDER_STATUS = 'UPDATE_ORDER_STATUS';
export const UPDATE_ORDER_STATUS_RESULT = 'UPDATE_ORDER_STATUS_RESULT';
export const UPDATE_ORDER_STATUS_ERROR = 'UPDATE_ORDER_STATUS_ERROR';
export const UPDATE_ORDER_STATUS_RETRY = 'UPDATE_ORDER_STATUS_RETRY';


export const fetchOrders = (data) => ({
    type: FETCH_ORDERS,
    data,
});


export const confirmOrder = (data) => ({
    type: CONFIRM_ORDER,
    data,
});


export const cancelOrder = (data) => ({
    type: CANCEL_ORDER,
    data,
});

export const revertOrder = (data) => ({
    type: REVERT_ORDER,
    data,
});

export const updateOrderCid = (data) => ({
    type: UPDATE_ORDER_CID,
    data
});


export const createOrderImage = (data) => ({
    type: CREATE_ORDER_IMAGE,
    data,
});

export const fetchOrderImage = (data) => ({
    type: FETCH_ORDER_IMAGE,
    data
});

export const updateOrderStatus = (data) => ({
    type: UPDATE_ORDER_STATUS,
    data
})