import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as PropTypes from 'prop-types';
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import moment from "moment";

import {SidebarContainer} from "../../components/sidebars/MainSidebar";
import MainNavbar from "../../components/navbars/MainNavbar";
import {fetchInvoices, updateInvoice} from "../../actions/pharmacy/invoices";
import {getMessages, getPhoneNumberWithoutCountryCode, getPrice, getSearchParams, scrollToTop} from "../../constants";
import './styles.scss';
import {toast} from "react-toastify";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Spinner from "react-bootstrap/Spinner";
import PaginationComponent from "../../components/others/PaginationComponent";
import InvoiceCreateModal from "../../components/modals/InvoiceCreateModal";
import ReturnedOrderEditCreateModal from "../../components/modals/ReturnedOrderEditCreateModal";


class Invoices extends React.Component {

    static propTypes = {
        invoices: PropTypes.object,
        invoiceitems: PropTypes.object,
        fetchInvoices: PropTypes.func,
    };

    constructor(props) {
        super(props);
        let {search} = props.history.location;
        let {page = '1', q = ''} = getSearchParams(search);

        this.state = {
            showReturnedModal: false,
            showCreateInvoiceModal: false,
            invoice: {},
            search: q,
            searchQuery: q,
            page: parseInt(page),
        };
    }

    componentDidMount() {
        let {page, search} = this.state;
        this.props.fetchInvoices({page, search});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {detail, isFetching, isUpdating, isCreating, message, error, retry} = this.props.invoices;

        let {search, page} = this.state;
        let prevQueryParams = getSearchParams(prevProps.location.search);
        let queryParams = getSearchParams(this.props.location.search);

        if (!isFetching && prevProps.invoices.isFetching && !error && !retry) {
            scrollToTop();
        }

        if (!isCreating && prevProps.invoices.isCreating && !error && !retry) {
            this.props.fetchInvoices({search, page});
            this.setState({showCreateInvoiceModal: false, invoice: {}})
        }

        if (!isCreating && prevProps.invoices.isCreating && error && !retry) {
            toast(getMessages(message)[0]);
        }

        if (!this.props.invoiceitems.isCreating && prevProps.invoiceitems.isCreating &&
          this.props.invoiceitems.error && !this.props.invoiceitems.retry) {
            toast(getMessages(this.props.invoiceitems.message)[0]);
        }

        if (!isUpdating && prevProps.invoices.isUpdating && !error && !retry) {
            this.props.fetchInvoices({search, page});
        }

        if (page !== prevState.page || search !== prevState.search) {
            this.props.fetchInvoices({search, page});
        }

        if (prevQueryParams.q !== queryParams.q || prevQueryParams.page !== queryParams.page) {
            this.setState({
                search: queryParams.q || '',
                searchQuery: queryParams.q || '',
                page: parseInt(queryParams.page || "1"),
            });
        }
    }

    handleSearchClick = (e) => {
        e && e.preventDefault();
        let {searchQuery} = this.state;
        this.setState({page: 1, search: searchQuery});
        this.props.history.push(`/invoices/?q=${searchQuery}&page=${1}`);
    };

    handlePageClick = (page) => {
        let {search} = this.state;
        this.setState({page});
        this.props.history.push(`/invoices/?q=${search}&page=${page}`);
    };

    handleLabelPrint = (invoice) => {
        let w = window.open(invoice.invoice_url);
        w.print();
    };

    handleMarkPaid = (invoice) => {
        this.props.updateInvoice({action: 'mark_paid', id: invoice.id});
    }

    handleCancelInvoice = (invoice) => {
        this.props.updateInvoice({action: 'cancel', id: invoice.id});
    }

    handleCreateCreditNote = (invoice) => {
        this.props.updateInvoice({action: 'creditnote', id: invoice.id});
    }

    handleCloseReturnedInvoiceModal = () => {
        let {search, page} = this.state;
        this.setState({showReturnedModal: false, invoice: {}});
        this.props.fetchInvoices({search, page});
    }

    render() {
        let {invoices: {list: {results = [], count}, isFetching}} = this.props;
        let {searchQuery, page, showReturnedModal, showCreateInvoiceModal, invoice} = this.state;

        return (
            <SidebarContainer>
                <MainNavbar pageName={'Invoice'}/>

                <InvoiceCreateModal show={showCreateInvoiceModal}
                                    handleClose={() => this.setState({showCreateInvoiceModal: false})}/>

                <ReturnedOrderEditCreateModal show={showReturnedModal} invoice={invoice}
                                              handleClose={this.handleCloseReturnedInvoiceModal}/>

                <div className="container-fluid">
                    <div className="row pt-4">
                        <div className="col-8">
                            <Form onSubmit={this.handleSearchClick}>
                                <InputGroup>
                                    <Form.Control type="text"
                                                  value={searchQuery}
                                                  placeholder="Search Invoice"
                                                  onChange={(e) => this.setState({searchQuery: e.target.value})}/>
                                    <InputGroup.Append>
                                        <Button type="submit" variant="success" size="sm">
                                            {isFetching ?
                                                <Spinner size="sm" animation="border"/> :
                                                "Search"
                                            }
                                        </Button>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Form>
                        </div>
                        <div className="col-4 text-right">
                            <Button variant="success" size={'sm'}
                                    onClick={() => this.setState({showCreateInvoiceModal: true})}>
                                Create
                            </Button>
                        </div>
                        <div className="col-12 pt-2">
                            <Table responsive striped bordered hover cellPadding={3}>
                                <thead>
                                <tr>
                                    <th>S No.</th>
                                    <th>Invoice No</th>
                                    <th>Items</th>
                                    <th>Channel</th>
                                    <th>Price</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    results.map(
                                        (invoice, index) => {
                                            let isReturnable1 = !!invoice.order ? ['return_in_transit', 'return_initiated', 'return_received', 'canceled', 'shipped', 'packed'].includes(invoice.order.status): false;
                                            let isReturnable2 = !invoice.order;
                                            let isReturnable3 = invoice.status !== 'void';
                                            let isReturnable = (isReturnable1 || isReturnable2) && isReturnable3;
                                            return (
                                                <tr key={invoice.id}>
                                                    <td className="order-cid">{(index + 1) + (((page || 1) - 1) * 15)}</td>
                                                    <td className="order-cid">#{invoice.invoice_number}</td>
                                                    <td className='order-products'>
                                                        <ul>
                                                            {
                                                                invoice.items.map(
                                                                    (item, index) => (
                                                                        <li key={index}>
                                                                            {item.item_name} <br/>
                                                                            <span>₹​{getPrice(item.price)} &nbsp;
                                                                                Qty:{item.quantity}</span>
                                                                        </li>
                                                                    )
                                                                )
                                                            }
                                                        </ul>
                                                    </td>
                                                    <td>{invoice.channel}</td>
                                                    <td>₹{invoice.total}</td>
                                                    <td>{invoice.status}</td>
                                                    <td>
                                                        <ButtonGroup vertical className="action-group">
                                                            {/*<Button variant="success" size={'sm'}
                                                                onClick={() => this.setState({
                                                                    showCreateInvoiceModal: true,
                                                                    invoice
                                                                })}>
                                                            Update
                                                        </Button>*/}
                                                            {!!invoice.invoice_url &&
                                                            <Button variant="primary" size={'sm'}
                                                                    onClick={() => this.handleLabelPrint(invoice)}>
                                                                Print Invoice
                                                            </Button>}

                                                            {!invoice.creditnote && invoice.channel !== 'misters' &&
                                                            <React.Fragment>
                                                                {invoice.status === 'sent' &&
                                                                <React.Fragment>
                                                                    <Button variant="success" size={'sm'}
                                                                            onClick={() => this.handleMarkPaid(invoice)}>
                                                                        Mark Paid
                                                                    </Button>
                                                                    <Button variant="danger" size={'sm'}
                                                                            onClick={() => this.handleCancelInvoice(invoice)}>
                                                                        Cancel Invoice
                                                                    </Button>
                                                                </React.Fragment>
                                                                }
                                                            </React.Fragment>
                                                            }

                                                            {isReturnable &&
                                                            <Button size={'sm'}
                                                                    variant={invoice.acknowledgement ? 'success' : 'info'}
                                                                    onClick={() => this.setState({
                                                                        showReturnedModal: true,
                                                                        invoice
                                                                    })}>
                                                                {invoice.acknowledgement ? 'Update Acknowledgement' : 'Acknowledge Return'}
                                                            </Button>}
                                                        </ButtonGroup>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    )
                                }
                                </tbody>
                            </Table>
                        </div>
                        <div className="col-12 py-3">
                            {!!count &&
                            <PaginationComponent page={parseInt(page)} count={parseInt(count)}
                                                 onClick={this.handlePageClick}/>}
                        </div>
                    </div>
                </div>

            </SidebarContainer>
        )
    }
}

const mapStateToProps = (state) => ({
    invoices: state.pharmacy.invoices,
    invoiceitems: state.pharmacy.invoiceitems,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    fetchInvoices,
    updateInvoice,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(Invoices);
