import React from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Col from "react-bootstrap/Col";
import Button from 'react-bootstrap/Button';
import * as PropTypes from "prop-types";
import Select from "react-select";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import {fetchItems} from "../../../actions/pharmacy/items";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import {updateBatch, createBatch} from "../../../actions/pharmacy/batches";


class BatchEditCreateModal extends React.Component {

    static propTypes = {
        batch: PropTypes.object,
        show: PropTypes.bool,
        fetchItems: PropTypes.func,
        createBatch: PropTypes.func,
        updateBatch: PropTypes.func,
        handleClose: PropTypes.func,
    };

    static defaultProps = {
        batch: {},
    };

    constructor(props) {
        super(props);

        this.state = {
            batch: {active: false, ...props.batch},
            message: null,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.show && !prevProps.show) {
            this.props.fetchItems();
            this.setState({
                batch: {active: false, ...this.props.batch},
                message: null,
            });
        }
    }

    handleCreateClick = () => {
        let {batch} = this.state;
        if (!!batch.id) {
            this.props.updateBatch(batch);
        } else {
            this.props.createBatch(batch);
        }
    };

    render() {
        let {show, handleClose, items} = this.props;
        let {
            batch: {item, name, batch_number, quantity, mrp, import_date, manufactured_date, expiry_date, active},
            batch, message,
        } = this.state;

        return (
            <Modal size="lg" show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Batch</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Row>
                        <Form.Group as={Col} controlId="items_select">
                            <Form.Label>Select Item</Form.Label>
                            <Select options={items.list.map(i => ({
                                label: `${i.product_name}-${i.product_id}`,
                                value: i.id
                            }))}
                                    value={items.list.filter(i => i.id === item).map(i => ({
                                        label: `${i.product_name}-${i.product_id}`,
                                        value: i.value
                                    }))}
                                    onChange={(i) => this.setState({
                                        batch: {
                                            ...batch,
                                            item: i.value,
                                        }
                                    })}/>
                        </Form.Group>
                        <Form.Group as={Col} controlId="name">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" placeholder="Name"
                                          value={name || ''}
                                          isInvalid={!!message && !!message.name}
                                          onChange={(e) => this.setState({
                                              batch: {
                                                  ...batch,
                                                  name: e.target.value
                                              }
                                          })}/>
                            <Form.Control.Feedback type="invalid">
                                {!!message && message.name}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="batch_number">
                            <Form.Label>Batch Number</Form.Label>
                            <Form.Control type="text" placeholder="Batch Number"
                                          value={batch_number || ''}
                                          isInvalid={!!message && !!message.batch_number}
                                          onChange={(e) => this.setState({
                                              batch: {
                                                  ...batch,
                                                  batch_number: e.target.value
                                              }
                                          })}/>
                            <Form.Control.Feedback type="invalid">
                                {!!message && message.batch_number}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} controlId="quantity">
                            <Form.Label>Quantity</Form.Label>
                            <Form.Control type="text" placeholder="Quantity"
                                          value={quantity || ''}
                                          isInvalid={!!message && !!message.quantity}
                                          onChange={(e) => this.setState({
                                              batch: {
                                                  ...batch,
                                                  quantity: e.target.value
                                              }
                                          })}/>
                            <Form.Control.Feedback type="invalid">
                                {!!message && message.quantity}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="mrp">
                            <Form.Label>Mrp</Form.Label>
                            <Form.Control size="md" type="text" placeholder="Mrp"
                                          value={mrp || ''}
                                          isInvalid={!!message && !!message.mrp}
                                          onChange={(e) => this.setState({
                                              batch: {
                                                  ...batch,
                                                  mrp: e.target.value
                                              }
                                          })}/>
                            <Form.Control.Feedback type="invalid">
                                {!!message && message.mrp}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} controlId="import_date">
                            <Form.Label>Import Date</Form.Label>
                            <DatePicker showYearDropdown showMonthDropdown
                                        className='form-control'
                                        placeholderText='Import Date'
                                        dateFormat="dd MMM yyyy"
                                        selected={!!import_date ? new Date(import_date) : ''}
                                        onChange={(date) => this.setState({
                                            batch: {
                                                ...batch,
                                                import_date: moment(date).format(),
                                            }
                                        })}
                            />
                            <Form.Control.Feedback type="invalid">
                                {!!message && message.import_date}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="manufactured_date">
                            <Form.Label>Manufactured Date</Form.Label>
                            <DatePicker showYearDropdown showMonthDropdown
                                        className='form-control'
                                        placeholderText='Manufactured Date'
                                        dateFormat="dd MMM yyyy"
                                        selected={!!manufactured_date ? new Date(manufactured_date) : ''}
                                        onChange={(date) => this.setState({
                                            batch: {
                                                ...batch,
                                                manufactured_date: moment(date).format(),
                                            }
                                        })}
                            />
                            <Form.Control.Feedback type="invalid">
                                {!!message && message.manufactured_date}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} controlId="expiry_date">
                            <Form.Label>Expiry Date</Form.Label>
                            <DatePicker showYearDropdown showMonthDropdown
                                        className='form-control'
                                        placeholderText='Expiry Date'
                                        dateFormat="dd MMM yyyy"
                                        selected={!!expiry_date ? new Date(expiry_date) : ''}
                                        onChange={(date) => this.setState({
                                            batch: {
                                                ...batch,
                                                expiry_date: moment(date).format(),
                                            }
                                        })}
                            />
                            <Form.Control.Feedback type="invalid">
                                {!!message && message.expiry_date}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="active">
                            <Form.Check type="checkbox" label="Active"
                                        checked={active || ''}
                                        isInvalid={!!message && !!message.active}
                                        onChange={(e) => this.setState({
                                            batch: {
                                                ...batch,
                                                active: !active,
                                            }
                                        })}/>
                            <Form.Control.Feedback type="invalid">
                                {!!message && message.active}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-danger" onClick={handleClose}>Close</Button>
                    <Button variant="primary" onClick={this.handleCreateClick}>
                        {!!batch.id ? 'Update' : 'Create'}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}


const mapStateToProps = (state) => ({
    items: state.pharmacy.items,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    fetchItems,
    updateBatch,
    createBatch,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(BatchEditCreateModal);