export const CREATE_CONSULTATION = 'CREATE_CONSULTATION';
export const CREATE_CONSULTATION_RESULT = 'CREATE_CONSULTATION_RESULT';
export const CREATE_CONSULTATION_ERROR = 'CREATE_CONSULTATION_ERROR';
export const CREATE_CONSULTATION_RETRY = 'CREATE_CONSULTATION_RETRY';


export const FETCH_CONSULTATIONS = 'FETCH_CONSULTATIONS';
export const FETCH_CONSULTATIONS_RESULT = 'FETCH_CONSULTATIONS_RESULT';
export const FETCH_CONSULTATIONS_ERROR = 'FETCH_CONSULTATIONS_ERROR';
export const FETCH_CONSULTATIONS_RETRY = 'FETCH_CONSULTATIONS_RETRY';


export const FETCH_CONSULTATIONS_MASTER = 'FETCH_CONSULTATIONS_MASTER';
export const FETCH_CONSULTATIONS_MASTER_RESULT = 'FETCH_CONSULTATIONS_MASTER_RESULT';
export const FETCH_CONSULTATIONS_MASTER_ERROR = 'FETCH_CONSULTATIONS_MASTER_ERROR';
export const FETCH_CONSULTATIONS_MASTER_RETRY = 'FETCH_CONSULTATIONS_MASTER_RETRY';


export const createConsultation = (data) => ({
    type: CREATE_CONSULTATION,
    data,
});

export const fetchConsultations = (data) => ({
    type: FETCH_CONSULTATIONS,
    data,
});

export const fetchConsultationsMaster = (data) => ({
    type: FETCH_CONSULTATIONS_MASTER,
    data,
});
