export const FETCH_BATCHES = 'FETCH_BATCHES';
export const FETCH_BATCHES_RESULT = 'FETCH_BATCHES_RESULT';
export const FETCH_BATCHES_ERROR = 'FETCH_BATCHES_ERROR';
export const FETCH_BATCHES_RETRY = 'FETCH_BATCHES_RETRY';

export const CREATE_BATCH = 'CREATE_BATCH';
export const CREATE_BATCH_RESULT = 'CREATE_BATCH_RESULT';
export const CREATE_BATCH_ERROR = 'CREATE_BATCH_ERROR';
export const CREATE_BATCH_RETRY = 'CREATE_BATCH_RETRY';

export const UPDATE_BATCH = 'UPDATE_BATCH';
export const UPDATE_BATCH_RESULT = 'UPDATE_BATCH_RESULT';
export const UPDATE_BATCH_ERROR = 'UPDATE_BATCH_ERROR';
export const UPDATE_BATCH_RETRY = 'UPDATE_BATCH_RETRY';


export const fetchBatches = (data) => ({
    type: FETCH_BATCHES,
    data,
});

export const createBatch = (data) => ({
    type: CREATE_BATCH,
    data,
});

export const updateBatch = (data) => ({
    type: UPDATE_BATCH,
    data,
});