import {call, put} from 'redux-saga/effects';
import {
    FETCH_RETURNED_ORDERS,
    FETCH_RETURNED_ORDERS_RESULT,
    FETCH_RETURNED_ORDERS_ERROR,
    FETCH_RETURNED_ORDERS_RETRY,

    FETCH_RETURNED_ORDER,
    FETCH_RETURNED_ORDER_RESULT,
    FETCH_RETURNED_ORDER_ERROR,
    FETCH_RETURNED_ORDER_RETRY,

    CREATE_RETURNED_ORDER,
    CREATE_RETURNED_ORDER_RESULT,
    CREATE_RETURNED_ORDER_ERROR,
    CREATE_RETURNED_ORDER_RETRY,

    UPDATE_RETURNED_ORDER,
    UPDATE_RETURNED_ORDER_RESULT,
    UPDATE_RETURNED_ORDER_ERROR,
    UPDATE_RETURNED_ORDER_RETRY,

} from '../../actions/pharmacy/returned';
import api from "../api";


export function* fetchReturnedOrders(action) {
    try {
        const response = yield call(api.pharmacy.returned.list, action.data);
        const result = yield response.json();
        console.warn('SAGA:fetchReturnedOrders', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_RETURNED_ORDERS_RESULT, data: result});
        }
        else {
            yield put({type: FETCH_RETURNED_ORDERS_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_RETURNED_ORDERS_RETRY, retry: true, message: e.message});
    }
}

export function* fetchReturnedOrder(action) {
    try {
        const response = yield call(api.pharmacy.returned.get, action.data);
        const result = yield response.json();
        console.warn('SAGA:fetchReturnedOrder', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_RETURNED_ORDER_RESULT, data: result});
        }
        else {
            yield put({type: FETCH_RETURNED_ORDER_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_RETURNED_ORDER_RETRY, retry: true, message: e.message});
    }
}

export function* createReturnedOrder(action) {
    try {
        console.error('create', action);
        const response = yield call(api.pharmacy.returned.create, action.data);
        const result = yield response.json();
        console.warn('SAGA:createReturnedOrder', action, result);

        if (response.status === 200) {
            yield put({type: CREATE_RETURNED_ORDER_RESULT, data: result});
        }
        else {
            yield put({type: CREATE_RETURNED_ORDER_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: CREATE_RETURNED_ORDER_RETRY, retry: true, message: e.message});
    }
}

export function* updateReturnedOrder(action) {
    try {
        const response = yield call(api.pharmacy.returned.update, action.data);
        const result = yield response.json();
        console.warn('SAGA:updateReturnedOrder', action, result);

        if (response.status === 200) {
            yield put({type: UPDATE_RETURNED_ORDER_RESULT, data: result});
        }
        else {
            yield put({type: UPDATE_RETURNED_ORDER_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: UPDATE_RETURNED_ORDER_RETRY, retry: true, message: e.message});
    }
}