import {takeEvery} from 'redux-saga/effects';

import {USER_LOGIN, REQUEST_OTP, TOKEN_VERIFY,} from '../../actions/accounts/login';
import {UPDATE_USER_PROFILE, FETCH_USER_PROFILE} from '../../actions/accounts/profile';
import {updateUserProfile, fetchUserProfile} from './profile';
import {requestOtp, tokenVerify, userLogin} from "./login";


export default [
    takeEvery(USER_LOGIN, userLogin),
    takeEvery(REQUEST_OTP, requestOtp),
    takeEvery(TOKEN_VERIFY, tokenVerify),
    takeEvery(UPDATE_USER_PROFILE, updateUserProfile),
    takeEvery(FETCH_USER_PROFILE, fetchUserProfile),
];
