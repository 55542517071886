import {takeEvery, all} from 'redux-saga/effects';

import accounts from './accounts';
import ecommerce from './ecommerce';
import consultations from './consultations';
import pharmacy from './pharmacy';


export default function* sagas() {
    yield all([
        ...accounts,
        ...ecommerce,
        ...consultations,
        ...pharmacy,
    ]);
}
