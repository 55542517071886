import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as PropTypes from 'prop-types';
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import moment from "moment";

import {SidebarContainer} from "../../components/sidebars/MainSidebar";
import MainNavbar from "../../components/navbars/MainNavbar";
import {
    fetchDeliveryChallans,
    updateDeliveryChallan,
    deleteDeliveryChallan
} from "../../actions/pharmacy/deliverychallans";
import {getPhoneNumberWithoutCountryCode, getPrice, getSearchParams, scrollToTop} from "../../constants";
import './styles.scss';
import {toast} from "react-toastify";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Spinner from "react-bootstrap/Spinner";
import PaginationComponent from "../../components/others/PaginationComponent";
import DeliveryChallanCreateModal from "../../components/modals/DeliveryChallanCreateModal";


class DeliveryChallan extends React.Component {

    static propTypes = {
        deliverychallans: PropTypes.object,
        fetchDeliveryChallans: PropTypes.func,
        updateDeliveryChallan: PropTypes.func,
        deleteDeliveryChallan: PropTypes.func,
    };

    constructor(props) {
        super(props);
        let {search} = props.history.location;
        let {page = '1', q = ''} = getSearchParams(search);

        this.state = {
            showCreateDeliveryChallanModal: false,
            deliverychallans: {},
            search: q,
            searchQuery: q,
            page: parseInt(page),
        };
    }

    componentDidMount() {
        let {page, search} = this.state;
        this.props.fetchDeliveryChallans({page, search});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {detail, isFetching, isUpdating, isCreating, isDeleting, error, retry} = this.props.deliverychallans;

        let {search, page} = this.state;
        let prevQueryParams = getSearchParams(prevProps.location.search);
        let queryParams = getSearchParams(this.props.location.search);

        if (!isFetching && prevProps.deliverychallans.isFetching && !error && !retry) {
            scrollToTop();
        }

        if (!isCreating && prevProps.deliverychallans.isCreating && !error && !retry) {
            this.props.fetchDeliveryChallans({search, page});
            toast("Delivery Challan Created");
            this.setState({showCreateDeliveryChallanModal: false, deliveryChallan: {}})
        }

        if (!isUpdating && prevProps.deliverychallans.isUpdating && !error && !retry) {
            this.props.fetchDeliveryChallans({search, page});
            toast("Delivery Challan Updated");
        }

        if (!isDeleting && prevProps.deliverychallans.isDeleting && !error && !retry) {
            this.props.fetchDeliveryChallans({search, page});
            toast("Delivery Challan Deleted");
        }

        if (page !== prevState.page || search !== prevState.search) {
            this.props.fetchDeliveryChallans({search, page});
        }

        if (prevQueryParams.q !== queryParams.q || prevQueryParams.page !== queryParams.page) {
            this.setState({
                search: queryParams.q || '',
                searchQuery: queryParams.q || '',
                page: parseInt(queryParams.page || "1"),
            });
        }
    }

    handleSearchClick = (e) => {
        e && e.preventDefault();
        let {searchQuery} = this.state;
        this.setState({page: 1, search: searchQuery});
        this.props.history.push(`/delivery-challan/?q=${searchQuery}&page=${1}`);
    };

    handlePageClick = (page) => {
        let {search} = this.state;
        this.setState({page});
        this.props.history.push(`/delivery-challan/?q=${search}&page=${page}`);
    };

    handleUpdateDeliveryChallan = (status, id) => {
        this.props.updateDeliveryChallan({status, id});
    }

    handleDeleteDeliveryChallan = (id) => {
        this.props.deleteDeliveryChallan({id});
    }

    render() {
        let {deliverychallans: {list: {results = [], count}, isFetching}} = this.props;
        let {searchQuery, page, showCreateDeliveryChallanModal, deliveryChallan} = this.state;

        return (
            <SidebarContainer>
                <MainNavbar pageName={'Delivery Challan'}/>

                <DeliveryChallanCreateModal show={showCreateDeliveryChallanModal}
                                            handleClose={() => this.setState({showCreateDeliveryChallanModal: false})}/>

                <div className="container-fluid">
                    <div className="row pt-4">
                        <div className="col-8">
                            <Form onSubmit={this.handleSearchClick}>
                                <InputGroup>
                                    <Form.Control type="text"
                                                  value={searchQuery}
                                                  placeholder="Search Delivery Challan"
                                                  onChange={(e) => this.setState({searchQuery: e.target.value})}/>
                                    <InputGroup.Append>
                                        <Button type="submit" variant="success" size="sm">
                                            {isFetching ?
                                                <Spinner size="sm" animation="border"/> :
                                                "Search"
                                            }
                                        </Button>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Form>
                        </div>
                        <div className="col-4 text-right">
                            <Button variant="success" size={'sm'}
                                    onClick={() => this.setState({showCreateDeliveryChallanModal: true})}>
                                Create
                            </Button>
                        </div>
                        <div className="col-12 pt-2">
                            <Table responsive striped bordered hover cellPadding={3}>
                                <thead>
                                <tr>
                                    <th>S No.</th>
                                    <th>Delivery Challan No</th>
                                    <th>Items</th>
                                    <th>Status</th>
                                    <th>Is Reverse</th>
                                    <th>Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    results.map(
                                        (challan, index) => (
                                            <tr key={challan.id}>
                                                <td className="order-cid">{(index + 1) + (((page || 1) - 1) * 15)}</td>
                                                <td className="order-cid">{challan.delivery_challan_number}</td>
                                                <td className='order-products'>
                                                    <ul>
                                                        {
                                                            challan.items.map(
                                                                (item, index) => (
                                                                    <li key={index}>
                                                                        {item.item_name} &nbsp;
                                                                        <span>₹{getPrice(item.price)} &nbsp;
                                                                            Qty:{item.quantity}</span>
                                                                    </li>
                                                                )
                                                            )
                                                        }
                                                    </ul>
                                                </td>
                                                <td>{challan.status}</td>
                                                <td>{challan.is_reverse ? 'Yes': 'No'}</td>
                                                <td>
                                                    <ButtonGroup className="action-group">
                                                        {(challan.status === 'draft' || challan.status === 'delivered') &&
                                                        <Button className="text-wrap" variant="primary" size={'sm'}
                                                                style={{maxWidth: '100px'}}
                                                                onClick={() => this.handleUpdateDeliveryChallan('open', challan.id)}>
                                                            Convert to open
                                                        </Button>
                                                        }
                                                        {(challan.status === 'open' || challan.status === 'delivered') &&
                                                        <Button className="text-wrap" variant="info" size={'sm'}
                                                                style={{maxWidth: '100px'}}
                                                                onClick={() => this.handleUpdateDeliveryChallan('draft', challan.id)}>
                                                            Convert to draft
                                                        </Button>
                                                        }
                                                        {(challan.status === 'open' || challan.status === 'draft') &&
                                                        <Button className="text-wrap" variant="success" size={'sm'}
                                                                style={{maxWidth: '100px'}}
                                                                onClick={() => this.handleUpdateDeliveryChallan('delivered', challan.id)}>
                                                            Convert to delivered
                                                        </Button>
                                                        }

                                                        <Button className="text-wrap" variant="danger" size={'sm'}
                                                                style={{maxWidth: '100px'}}
                                                                onClick={() => this.handleDeleteDeliveryChallan(challan.id)}>
                                                            Delete
                                                        </Button>

                                                    </ButtonGroup>
                                                </td>
                                            </tr>
                                        )
                                    )
                                }
                                </tbody>
                            </Table>
                        </div>
                        <div className="col-12 py-3">
                            {!!count &&
                            <PaginationComponent page={parseInt(page)} count={parseInt(count)}
                                                 onClick={this.handlePageClick}/>}
                        </div>
                    </div>
                </div>

            </SidebarContainer>
        )
    }
}

const mapStateToProps = (state) => ({
    deliverychallans: state.pharmacy.deliverychallans,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    fetchDeliveryChallans,
    updateDeliveryChallan,
    deleteDeliveryChallan,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(DeliveryChallan);
