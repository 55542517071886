import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as PropTypes from 'prop-types';
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import moment from "moment";

import {SidebarContainer} from "../../components/sidebars/MainSidebar";
import MainNavbar from "../../components/navbars/MainNavbar";
import {fetchCreditNotes} from "../../actions/pharmacy/creditnotes";
import {getPhoneNumberWithoutCountryCode, getSearchParams, scrollToTop} from "../../constants";
import './styles.scss';
import {toast} from "react-toastify";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Spinner from "react-bootstrap/Spinner";
import PaginationComponent from "../../components/others/PaginationComponent";
import BatchEditCreateModal from "../../components/modals/BatchEditCreateModal";


class CreditNotes extends React.Component {

    static propTypes = {
        creditnotes: PropTypes.object,
        fetchCreditNotes: PropTypes.func,
    };

    constructor(props) {
        super(props);
        let {search} = props.history.location;
        let {page = 1, q = ''} = getSearchParams(search);

        this.state = {
            showCreditNoteModal: false,
            creditnote: {},
            search: q,
            searchQuery: q,
            page,
        };
    }

    componentDidMount() {
        let {page, search} = this.state;
        this.props.fetchCreditNotes({page, search});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {detail, isFetching, isUpdating, isCreating, error, retry} = this.props.creditnotes;

        let {search, page} = this.state;
        let prevQueryParams = getSearchParams(prevProps.location.search);
        let queryParams = getSearchParams(this.props.location.search);

        if (!isFetching && prevProps.creditnotes.isFetching && !error && !retry) {
            scrollToTop();
        }

        if ((!isUpdating && prevProps.creditnotes.isUpdating && !error && !retry) ||
            (!isCreating && prevProps.creditnotes.isCreating && !error && !retry)) {
            this.props.fetchCreditNotes({search, page});
            // this.setState({showCreditNoteModal: false, creditnote: {}})
        }

        if (page !== prevState.page || search !== prevState.search) {
            this.props.fetchCreditNotes({search, page});
        }

        if (prevQueryParams.q !== queryParams.q || prevQueryParams.page !== queryParams.page) {
            this.setState({
                search: queryParams.q || '',
                searchQuery: queryParams.q || '',
                page: parseInt(queryParams.page || "1"),
            });
        }
    }

    handleSearchClick = (e) => {
        e && e.preventDefault();
        let {searchQuery} = this.state;
        this.setState({page: 1, search: searchQuery});
        this.props.history.push(`/credit-notes/?q=${searchQuery}&page=${1}`);
    };

    handlePageClick = (page) => {
        let {search} = this.state;
        this.setState({page});
        this.props.history.push(`/credit-notes/?q=${search}&page=${page}`);
    };

    render() {
        let {creditnotes: {list: {results = [], count}, isFetching}} = this.props;
        let {searchQuery, page, showCreditNoteModal, creditnote} = this.state;

        return (
            <SidebarContainer>
                <MainNavbar pageName={'Credit Notes'}/>

                {/*<BatchEditCreateModal show={showBatchModal} batch={batch}
                                      handleClose={() => this.setState({showBatchModal: false, batch: {}})}/>*/}

                <div className="container-fluid">
                    <div className="row pt-5">
                        <div className="col-8">
                            <Form onSubmit={this.handleSearchClick}>
                                <InputGroup>
                                    <Form.Control type="text"
                                                  value={searchQuery}
                                                  placeholder="Search Invoice number, Order CID"
                                                  onChange={(e) => this.setState({searchQuery: e.target.value})}/>
                                    <InputGroup.Append>
                                        <Button type="submit" variant="success" size="sm">
                                            {isFetching ?
                                                <Spinner size="sm" animation="border"/> :
                                                "Search"
                                            }
                                        </Button>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Form>
                        </div>
                        <div className="col-4 text-right">
                            {/* <Button variant="success" size={'sm'}
                                    onClick={() => this.setState({showCreditNoteModal: true})}>
                                Create
                            </Button>*/}
                        </div>
                        <div className="col-12 pt-2">
                            <Table responsive striped bordered hover cellPadding={3}>
                                <thead>
                                <tr>
                                    <th>Invoice number</th>
                                    <th>Order CID</th>
                                    <th>Credit note ID</th>
                                    <th>Credit note refund ID</th>
                                    <th>Credit note number</th>
                                    <th>Reference number</th>
                                    <th>Refund mode</th>
                                    <th>Amount</th>
                                    <th>Status</th>
                                    <th>Created at</th>
                                    <th>Updated at</th>
                                    {/*<th>Actions</th>*/}
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    results.map(
                                        (credit) => (
                                            <tr key={credit.id}>
                                                <td className="order-cid">{credit.invoice.invoice_number}</td>
                                                <td className="order-cid">#{credit.invoice.order_cid}</td>
                                                <td className="order-cid">#{credit.creditnote_id}</td>
                                                <td className="order-cid">#{credit.creditnote_refund_id}</td>
                                                <td>{credit.creditnote_number}</td>
                                                <td>{credit.reference_number}</td>
                                                <td>{credit.refund_mode}</td>
                                                <td>{credit.amount}</td>
                                                <td>{credit.status}</td>
                                                <td className='order-date'>
                                                    {moment(credit.created_at).format("ddd, MMMM Do YYYY, h:mm:ss a")}
                                                </td>
                                                <td className='order-date'>
                                                    {moment(credit.updated_at).format("ddd, MMMM Do YYYY, h:mm:ss a")}
                                                </td>
                                                {/*<td>
                                                    <ButtonGroup vertical className="action-group">
                                                        <Button variant="primary" size={'sm'}
                                                                onClick={() => this.setState({
                                                                    showCreditNoteModal: true,
                                                                    creditnote: credit
                                                                })}>
                                                            Edit
                                                        </Button>
                                                    </ButtonGroup>
                                                </td>*/}
                                            </tr>
                                        )
                                    )
                                }
                                </tbody>
                            </Table>
                        </div>
                        <div className="col-12 py-3">
                            {!!count &&
                            <PaginationComponent page={parseInt(page)} count={parseInt(count)}
                                                 onClick={this.handlePageClick}/>}
                        </div>
                    </div>
                </div>

            </SidebarContainer>
        )
    }
}

const mapStateToProps = (state) => ({
    creditnotes: state.pharmacy.creditnotes,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    fetchCreditNotes,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(CreditNotes);
