import {call, put} from 'redux-saga/effects';
import {
    FETCH_INVOICES,
    FETCH_INVOICES_RESULT,
    FETCH_INVOICES_ERROR,
    FETCH_INVOICES_RETRY,

    CREATE_INVOICE,
    CREATE_INVOICE_RESULT,
    CREATE_INVOICE_ERROR,
    CREATE_INVOICE_RETRY,

    UPDATE_INVOICE,
    UPDATE_INVOICE_RESULT,
    UPDATE_INVOICE_ERROR,
    UPDATE_INVOICE_RETRY,
} from '../../actions/pharmacy/invoices';
import api from "../api";


export function* fetchInvoices(action) {
    try {
        const response = yield call(api.pharmacy.invoices.list, action.data);
        const result = yield response.json();
        console.warn('SAGA:fetchInvoices', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_INVOICES_RESULT, data: result});
        } else {
            yield put({type: FETCH_INVOICES_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_INVOICES_RETRY, retry: true, message: e.message});
    }
}

export function* createInvoice(action) {
    try {
        const response = yield call(api.pharmacy.invoices.create, action.data);
        const result = yield response.json();
        console.warn('SAGA:createInvoice', action, result);

        if (response.status === 201) {
            yield put({type: CREATE_INVOICE_RESULT, data: result});
        } else {
            yield put({type: CREATE_INVOICE_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: CREATE_INVOICE_RETRY, retry: true, message: e.message});
    }
}

export function* updateInvoice(action) {
    try {
        const response = yield call(api.pharmacy.invoices.update, action.data);
        const result = yield response.json();
        console.warn('SAGA:updateInvoice', action, result);

        if (response.status === 200) {
            yield put({type: UPDATE_INVOICE_RESULT, data: result});
        } else {
            yield put({type: UPDATE_INVOICE_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: UPDATE_INVOICE_RETRY, retry: true, message: e.message});
    }
}
