import {call, put} from 'redux-saga/effects';
import {
    FETCH_ORDERS,
    FETCH_ORDERS_RESULT,
    FETCH_ORDERS_ERROR,
    FETCH_ORDERS_RETRY,

    CONFIRM_ORDER_RESULT,
    CONFIRM_ORDER_ERROR,
    CONFIRM_ORDER_RETRY,

    CANCEL_ORDER_RESULT,
    CANCEL_ORDER_ERROR,
    CANCEL_ORDER_RETRY,

    REVERT_ORDER_RESULT,
    REVERT_ORDER_ERROR,
    REVERT_ORDER_RETRY,

    UPDATE_ORDER_CID_RESULT,
    UPDATE_ORDER_CID_ERROR,
    UPDATE_ORDER_CID_RETRY,

    CREATE_ORDER_IMAGE_RESULT,
    CREATE_ORDER_IMAGE_ERROR,
    CREATE_ORDER_IMAGE_RETRY,

    FETCH_ORDER_IMAGE_RESULT,
    FETCH_ORDER_IMAGE_ERROR,
    FETCH_ORDER_IMAGE_RETRY,

    UPDATE_ORDER_STATUS_RESULT,
    UPDATE_ORDER_STATUS_ERROR,
    UPDATE_ORDER_STATUS_RETRY,
} from '../../actions/ecommerce/orders';
import api from "../api";
import {toast} from "react-toastify";


export function* fetchOrders(action) {
    try {
        const response = yield call(api.ecommerce.orders.list, action.data);
        const result = yield response.json();
        console.warn('SAGA:fetchOrders', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_ORDERS_RESULT, data: result});
        }
        else {
            yield put({type: FETCH_ORDERS_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_ORDERS_RETRY, retry: true, message: e.message});
    }
}


export function* confirmOrder(action) {
    try {
        const response = yield call(api.ecommerce.orders.confirm, action.data);
        const result = yield response.json();
        console.warn('SAGA:confirmOrder', action, result);

        if (response.status === 200) {
            yield put({type: CONFIRM_ORDER_RESULT, data: result});
        }
        else {
            toast(result.message,);
            yield put({type: CONFIRM_ORDER_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: CONFIRM_ORDER_RETRY, retry: true, message: e.message});
    }
}


export function* cancelOrder(action) {
    try {
        const response = yield call(api.ecommerce.orders.cancel, action.data);
        const result = yield response.json();
        console.warn('SAGA:cancelOrder', action, result);

        if (response.status === 200) {
            yield put({type: CANCEL_ORDER_RESULT, data: result});
        }
        else {
            yield put({type: CANCEL_ORDER_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: CANCEL_ORDER_RETRY, retry: true, message: e.message});
    }
}

export function* revertOrder(action) {
    try {
        const response = yield call(api.ecommerce.orders.revert, action.data);
        const result = yield response.json();
        console.warn('SAGA:revertOrder', action, result);

        if (response.status === 200) {
            yield put({type: REVERT_ORDER_RESULT, data: result});
        }
        else {
            yield put({type: REVERT_ORDER_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: REVERT_ORDER_RETRY, retry: true, message: e.message});
    }
}

export function* updateOrderCid(action) {
    try {
        const response = yield call(api.ecommerce.orders.cid, action.data);
        const result = yield response.json();
        console.warn('SAGA:updateOrderCid', action, result);

        if (response.status === 200) {
            yield put({type: UPDATE_ORDER_CID_RESULT, data: result});
        }
        else {
            yield put({type: UPDATE_ORDER_CID_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: UPDATE_ORDER_CID_RETRY, retry: true, message: e.message});
    }
}

export function* createOrderImage(action) {
    try {
        const response = yield call(api.ecommerce.orders.images.create, action.data);
        const result = yield response.json();
        console.warn('SAGA:createOrderImage', action, result);

        if (response.status === 201) {
            yield put({type: CREATE_ORDER_IMAGE_RESULT, data: result});
        }
        else {
            yield put({type: CREATE_ORDER_IMAGE_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: CREATE_ORDER_IMAGE_RETRY, retry: true, message: e.message});
    }
}

export function* fetchOrderImage(action) {
    try {
        const response = yield call(api.ecommerce.orders.images.get, action.data);
        const result = yield response.json();
        console.warn('SAGA:fetchOrderImage', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_ORDER_IMAGE_RESULT, data: result});
        }
        else {
            yield put({type: FETCH_ORDER_IMAGE_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_ORDER_IMAGE_RETRY, retry: true, message: e.message});
    }
}

export function* updateOrderStatus(action) {
    try {
        const response = yield call(api.ecommerce.orders.updateStatus, action.data);
        const result = yield response.json();
        console.warn('SAGA:updateOrderStatus', action, result);

        if (response.status === 200) {
            yield put({type: UPDATE_ORDER_STATUS_RESULT, data: result});
        }
        else {
            yield put({type: UPDATE_ORDER_STATUS_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: UPDATE_ORDER_STATUS_RETRY, retry: true, message: e.message});
    }
}
