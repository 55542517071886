import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
// My Imports
import './styles.scss';
import {userLogin} from "../../actions/accounts/login";
import Alert from "react-bootstrap/Alert";
import {getMessages} from "../../constants";
import {toast} from "react-toastify";


class Login extends React.Component {

    static propTypes = {
        login: PropTypes.object,
        userLogin: PropTypes.func,
        requestOtp: PropTypes.func,
    };

    state = {
        phoneNumber: '',
        otp: '',
        message: null,
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {isFetchingLogin, data, message, error, otpSent, loggedIn, retry} = this.props.login;

        if (error && !prevProps.login.error) {
            this.setState({message});
        }
        if (!isFetchingLogin && prevProps.login.isFetchingLogin && !error && !retry && !otpSent) {
            toast("Logged In Successfully", );
            this.props.history && this.props.history.push('/');
        }
        if (!isFetchingLogin && prevProps.login.isFetchingLogin && !error && !retry && otpSent) {
            toast("OTP sent Successfully", );
        }
    }

    handlePhoneNumberChange = (e) => {
        this.setState({phoneNumber: e.target.value})
    };

    handleOtpChange = (e) => {
        this.setState({otp: e.target.value})
    };

    handleSendOtp = () => {
        let phoneNumber = `+91${this.state.phoneNumber}`;
        this.props.userLogin({
            phone_number: phoneNumber,
        })
    };
    handleLogin = () => {
        let phoneNumber = `+91${this.state.phoneNumber}`;
        this.props.userLogin({
            phone_number: phoneNumber,
            otp: this.state.otp,
        })
    };
    handleSubmit = (e) => {
        e.preventDefault();
        if (this.state.otp.length === 0) {
            this.handleSendOtp();
        } else {
            this.handleLogin()
        }
    };

    render() {
        let {phoneNumber, otp, message} = this.state;
        let messages = getMessages(message);

        return (
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3">
                        <div className="min-vh-100 d-flex justify-content-center align-items-center flex-column">
                            <h3 className='text-center mb-4 w-100'>Login</h3>
                            {
                                messages.map(
                                    (message, index) => (
                                        <Alert key={index} variant={'danger'} className='w-100 text-center'>
                                            {message}
                                        </Alert>
                                    )
                                )
                            }
                            <Form className='w-100' onSubmit={this.handleSubmit}>
                                <Form.Group controlId="formPhoneNumber">
                                    {/*<Form.Label>Phone Number</Form.Label>*/}
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text id="basic-addon1">+91</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control type="number" placeholder="Enter Phone Number"
                                                      value={phoneNumber}
                                                      onChange={this.handlePhoneNumberChange}/>
                                    </InputGroup>
                                    <Form.Text className="text-muted">
                                        We'll never share your Number with anyone else.
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group controlId="formOtp">
                                    {/*<Form.Label>Password</Form.Label>*/}
                                    <Form.Control type="password"
                                                  placeholder="OTP"
                                                  value={otp}
                                                  onChange={this.handleOtpChange}/>
                                </Form.Group>
                                <div className="pb-3"/>

                                <Button type='submit' variant="dark" block onClick={this.handleSubmit}>
                                    {this.state.otp.length === 0 ? 'Send Otp' : 'Login'}
                                </Button>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    login: state.accounts.login,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    userLogin,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Login);
