export const FETCH_DELIVERY_CHALLANS = 'FETCH_DELIVERY_CHALLANS';
export const FETCH_DELIVERY_CHALLANS_RESULT = 'FETCH_DELIVERY_CHALLANS_RESULT';
export const FETCH_DELIVERY_CHALLANS_ERROR = 'FETCH_DELIVERY_CHALLANS_ERROR';
export const FETCH_DELIVERY_CHALLANS_RETRY = 'FETCH_DELIVERY_CHALLANS_RETRY';

export const CREATE_DELIVERY_CHALLAN = 'CREATE_DELIVERY_CHALLAN';
export const CREATE_DELIVERY_CHALLAN_RESULT = 'CREATE_DELIVERY_CHALLAN_RESULT';
export const CREATE_DELIVERY_CHALLAN_ERROR = 'CREATE_DELIVERY_CHALLAN_ERROR';
export const CREATE_DELIVERY_CHALLAN_RETRY = 'CREATE_DELIVERY_CHALLAN_RETRY';

export const UPDATE_DELIVERY_CHALLAN = 'UPDATE_DELIVERY_CHALLAN';
export const UPDATE_DELIVERY_CHALLAN_RESULT = 'UPDATE_DELIVERY_CHALLAN_RESULT';
export const UPDATE_DELIVERY_CHALLAN_ERROR = 'UPDATE_DELIVERY_CHALLAN_ERROR';
export const UPDATE_DELIVERY_CHALLAN_RETRY = 'UPDATE_DELIVERY_CHALLAN_RETRY';

export const DELETE_DELIVERY_CHALLAN = 'DELETE_DELIVERY_CHALLAN';
export const DELETE_DELIVERY_CHALLAN_RESULT = 'DELETE_DELIVERY_CHALLAN_RESULT';
export const DELETE_DELIVERY_CHALLAN_ERROR = 'DELETE_DELIVERY_CHALLAN_ERROR';
export const DELETE_DELIVERY_CHALLAN_RETRY = 'DELETE_DELIVERY_CHALLAN_RETRY';


export const fetchDeliveryChallans = (data) => ({
    type: FETCH_DELIVERY_CHALLANS,
    data,
});

export const createDeliveryChallan = (data) => ({
    type: CREATE_DELIVERY_CHALLAN,
    data,
});

export const updateDeliveryChallan = (data) => ({
    type: UPDATE_DELIVERY_CHALLAN,
    data,
});

export const deleteDeliveryChallan = (data) => ({
    type: DELETE_DELIVERY_CHALLAN,
    data,
});