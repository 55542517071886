export const FETCH_DELIVERY_CHALLAN_ITEMS = 'FETCH_DELIVERY_CHALLAN_ITEMS';
export const FETCH_DELIVERY_CHALLAN_ITEMS_RESULT = 'FETCH_DELIVERY_CHALLAN_ITEMS_RESULT';
export const FETCH_DELIVERY_CHALLAN_ITEMS_ERROR = 'FETCH_DELIVERY_CHALLAN_ITEMS_ERROR';
export const FETCH_DELIVERY_CHALLAN_ITEMS_RETRY = 'FETCH_DELIVERY_CHALLAN_ITEMS_RETRY';

export const CREATE_DELIVERY_CHALLAN_ITEM = 'CREATE_DELIVERY_CHALLAN_ITEM';
export const CREATE_DELIVERY_CHALLAN_ITEM_RESULT = 'CREATE_DELIVERY_CHALLAN_ITEM_RESULT';
export const CREATE_DELIVERY_CHALLAN_ITEM_ERROR = 'CREATE_DELIVERY_CHALLAN_ITEM_ERROR';
export const CREATE_DELIVERY_CHALLAN_ITEM_RETRY = 'CREATE_DELIVERY_CHALLAN_ITEM_RETRY';

export const UPDATE_DELIVERY_CHALLAN_ITEM = 'UPDATE_DELIVERY_CHALLAN_ITEM';
export const UPDATE_DELIVERY_CHALLAN_ITEM_RESULT = 'UPDATE_DELIVERY_CHALLAN_ITEM_RESULT';
export const UPDATE_DELIVERY_CHALLAN_ITEM_ERROR = 'UPDATE_DELIVERY_CHALLAN_ITEM_ERROR';
export const UPDATE_DELIVERY_CHALLAN_ITEM_RETRY = 'UPDATE_DELIVERY_CHALLAN_ITEM_RETRY';

export const DELETE_DELIVERY_CHALLAN_ITEM = 'DELETE_DELIVERY_CHALLAN_ITEM';
export const DELETE_DELIVERY_CHALLAN_ITEM_RESULT = 'DELETE_DELIVERY_CHALLAN_ITEM_RESULT';
export const DELETE_DELIVERY_CHALLAN_ITEM_ERROR = 'DELETE_DELIVERY_CHALLAN_ITEM_ERROR';
export const DELETE_DELIVERY_CHALLAN_ITEM_RETRY = 'DELETE_DELIVERY_CHALLAN_ITEM_RETRY';


export const fetchDeliveryChallanItems = (data) => ({
    type: FETCH_DELIVERY_CHALLAN_ITEMS,
    data,
});

export const createDeliveryChallanItem = (data) => ({
    type: CREATE_DELIVERY_CHALLAN_ITEM,
    data,
});

export const updateDeliveryChallanItem = (data) => ({
    type: UPDATE_DELIVERY_CHALLAN_ITEM,
    data,
});

export const deleteDeliveryChallanItem = (data) => ({
    type: DELETE_DELIVERY_CHALLAN_ITEM,
    data,
});