import {
    FETCH_RETURNED_ORDERS,
    FETCH_RETURNED_ORDERS_RESULT,
    FETCH_RETURNED_ORDERS_ERROR,
    FETCH_RETURNED_ORDERS_RETRY,

    FETCH_RETURNED_ORDER,
    FETCH_RETURNED_ORDER_RESULT,
    FETCH_RETURNED_ORDER_ERROR,
    FETCH_RETURNED_ORDER_RETRY,

    CREATE_RETURNED_ORDER,
    CREATE_RETURNED_ORDER_RESULT,
    CREATE_RETURNED_ORDER_ERROR,
    CREATE_RETURNED_ORDER_RETRY,

    UPDATE_RETURNED_ORDER,
    UPDATE_RETURNED_ORDER_RESULT,
    UPDATE_RETURNED_ORDER_ERROR,
    UPDATE_RETURNED_ORDER_RETRY,

} from '../../actions/pharmacy/returned';


const initialState = {
    list: {},
    detail: {},
    isFetchingList: false,
    isFetching: false,
    isUpdating: false,
    isCreating: false,
    error: false,
    message: null,
    retry: false,
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_RETURNED_ORDERS:
            return {...state, isFetchingList: true, error: false, message: null, retry: false};
        case FETCH_RETURNED_ORDERS_RESULT:
            return {...state, isFetchingList: false, list: action.data};
        case FETCH_RETURNED_ORDERS_ERROR:
            return {...state, isFetchingList: false, error: true, message: action.message};
        case FETCH_RETURNED_ORDERS_RETRY:
            return {...state, isFetchingList: false, retry: true, message: action.message};

        case FETCH_RETURNED_ORDER:
            return {...state, isFetching: true, error: false, message: null, retry: false};
        case FETCH_RETURNED_ORDER_RESULT:
            return {...state, isFetching: false, detail: action.data};
        case FETCH_RETURNED_ORDER_ERROR:
            return {...state, isFetching: false, error: true, message: action.message};
        case FETCH_RETURNED_ORDER_RETRY:
            return {...state, isFetching: false, retry: true, message: action.message};

        case CREATE_RETURNED_ORDER:
            return {...state, isCreating: true, error: false, message: null, retry: false};
        case CREATE_RETURNED_ORDER_RESULT:
            return {...state, isCreating: false, detail: action.data};
        case CREATE_RETURNED_ORDER_ERROR:
            return {...state, isCreating: false, error: true, message: action.message};
        case CREATE_RETURNED_ORDER_RETRY:
            return {...state, isCreating: false, retry: true, message: action.message};

        case UPDATE_RETURNED_ORDER:
            return {...state, isUpdating: true, error: false, message: null, retry: false};
        case UPDATE_RETURNED_ORDER_RESULT:
            return {...state, isUpdating: false, detail: action.data};
        case UPDATE_RETURNED_ORDER_ERROR:
            return {...state, isUpdating: false, error: true, message: action.message};
        case UPDATE_RETURNED_ORDER_RETRY:
            return {...state, isUpdating: false, retry: true, message: action.message};

        default:
            return state;
    }
};

export default reducer;
